/* tslint:disable */

/* eslint-disable */

/**
 * Chaos Mesh Dashboard API
 * Swagger for Chaos Mesh Dashboard. If you encounter any problems with API, please click on the issues link below to report.
 *
 * The version of the OpenAPI document: 2.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'

// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError } from './base'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common'
import { Configuration } from './configuration'

/**
 *
 * @export
 * @interface ConfigChaosDashboardConfig
 */
export interface ConfigChaosDashboardConfig {
  /**
   * The Burst config for kubernetes client
   * @type {number}
   * @memberof ConfigChaosDashboardConfig
   */
  burst?: number
  /**
   * ClusterScoped means control Chaos Object in cluster level(all namespace).
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  cluster_mode?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  dns_server_create?: boolean
  /**
   * EnableFilterNamespace will filter namespace with annotation. Only the pods/containers in namespace annotated with `chaos-mesh.org/inject=enabled` will be injected.
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  enableFilterNamespace?: boolean
  /**
   * enableProfiling is a flag to enable pprof in controller-manager and chaos-daemon
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  enableProfiling?: boolean
  /**
   * GcpSecurityMode will use the gcloud authentication to login to GKE user
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  gcp_security_mode?: boolean
  /**
   *
   * @type {string}
   * @memberof ConfigChaosDashboardConfig
   */
  listen_host?: string
  /**
   *
   * @type {number}
   * @memberof ConfigChaosDashboardConfig
   */
  listen_port?: number
  /**
   * The QPS config for kubernetes client
   * @type {number}
   * @memberof ConfigChaosDashboardConfig
   */
  qps?: number
  /**
   *
   * @type {string}
   * @memberof ConfigChaosDashboardConfig
   */
  root_path?: string
  /**
   * SecurityMode will use the token login by the user if set to true
   * @type {boolean}
   * @memberof ConfigChaosDashboardConfig
   */
  security_mode?: boolean
  /**
   * TargetNamespace is the target namespace to injecting chaos. It only works with ClusterScoped is false.
   * @type {string}
   * @memberof ConfigChaosDashboardConfig
   */
  target_namespace?: string
  /**
   *
   * @type {string}
   * @memberof ConfigChaosDashboardConfig
   */
  version?: string
}
/**
 *
 * @export
 * @interface CoreConditionalBranch
 */
export interface CoreConditionalBranch {
  /**
   *
   * @type {string}
   * @memberof CoreConditionalBranch
   */
  expression?: string
  /**
   *
   * @type {string}
   * @memberof CoreConditionalBranch
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreConditionalBranch
   */
  template?: string
}
/**
 *
 * @export
 * @interface CoreEvent
 */
export interface CoreEvent {
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  created_at?: string
  /**
   *
   * @type {number}
   * @memberof CoreEvent
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  kind?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  object_id?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  reason?: string
  /**
   *
   * @type {string}
   * @memberof CoreEvent
   */
  type?: string
}
/**
 *
 * @export
 * @interface CoreKubeObjectDesc
 */
export interface CoreKubeObjectDesc {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources +optional
   * @type {string}
   * @memberof CoreKubeObjectDesc
   */
  apiVersion?: string
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds +optional
   * @type {string}
   * @memberof CoreKubeObjectDesc
   */
  kind?: string
  /**
   *
   * @type {CoreKubeObjectMeta}
   * @memberof CoreKubeObjectDesc
   */
  metadata?: CoreKubeObjectMeta
  /**
   *
   * @type {object}
   * @memberof CoreKubeObjectDesc
   */
  spec?: object
}
/**
 *
 * @export
 * @interface CoreKubeObjectMeta
 */
export interface CoreKubeObjectMeta {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreKubeObjectMeta
   */
  annotations?: { [key: string]: string }
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CoreKubeObjectMeta
   */
  labels?: { [key: string]: string }
  /**
   *
   * @type {string}
   * @memberof CoreKubeObjectMeta
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreKubeObjectMeta
   */
  namespace?: string
}
/**
 *
 * @export
 * @interface CoreNode
 */
export interface CoreNode {
  /**
   *
   * @type {Array<CoreConditionalBranch>}
   * @memberof CoreNode
   */
  conditional_branches?: Array<CoreConditionalBranch>
  /**
   *
   * @type {string}
   * @memberof CoreNode
   */
  name?: string
  /**
   *
   * @type {Array<CoreNodeNameWithTemplate>}
   * @memberof CoreNode
   */
  parallel?: Array<CoreNodeNameWithTemplate>
  /**
   *
   * @type {Array<CoreNodeNameWithTemplate>}
   * @memberof CoreNode
   */
  serial?: Array<CoreNodeNameWithTemplate>
  /**
   *
   * @type {string}
   * @memberof CoreNode
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof CoreNode
   */
  template?: string
  /**
   *
   * @type {string}
   * @memberof CoreNode
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof CoreNode
   */
  uid?: string
}
/**
 *
 * @export
 * @interface CoreNodeNameWithTemplate
 */
export interface CoreNodeNameWithTemplate {
  /**
   *
   * @type {string}
   * @memberof CoreNodeNameWithTemplate
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreNodeNameWithTemplate
   */
  template?: string
}
/**
 *
 * @export
 * @interface CoreTopology
 */
export interface CoreTopology {
  /**
   *
   * @type {Array<CoreNode>}
   * @memberof CoreTopology
   */
  nodes?: Array<CoreNode>
}
/**
 *
 * @export
 * @interface CoreWorkflowDetail
 */
export interface CoreWorkflowDetail {
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  created_at?: string
  /**
   * EndTime represents the time when the workflow completed all steps.
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  end_time?: string
  /**
   * the entry node name
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  entry?: string
  /**
   * FinishTime represents the time when the workflow was deleted from Kubernetes.
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  finish_time?: string
  /**
   *
   * @type {number}
   * @memberof CoreWorkflowDetail
   */
  id?: number
  /**
   *
   * @type {CoreKubeObjectDesc}
   * @memberof CoreWorkflowDetail
   */
  kube_object?: CoreKubeObjectDesc
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  status?: string
  /**
   *
   * @type {CoreTopology}
   * @memberof CoreWorkflowDetail
   */
  topology?: CoreTopology
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowDetail
   */
  uid?: string
}
/**
 *
 * @export
 * @interface CoreWorkflowMeta
 */
export interface CoreWorkflowMeta {
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  created_at?: string
  /**
   * EndTime represents the time when the workflow completed all steps.
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  end_time?: string
  /**
   * the entry node name
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  entry?: string
  /**
   * FinishTime represents the time when the workflow was deleted from Kubernetes.
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  finish_time?: string
  /**
   *
   * @type {number}
   * @memberof CoreWorkflowMeta
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof CoreWorkflowMeta
   */
  uid?: string
}
/**
 *
 * @export
 * @interface CurlRequestForm
 */
export interface CurlRequestForm {
  /**
   *
   * @type {string}
   * @memberof CurlRequestForm
   */
  body?: string
  /**
   *
   * @type {boolean}
   * @memberof CurlRequestForm
   */
  followLocation?: boolean
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof CurlRequestForm
   */
  header?: { [key: string]: Array<string> }
  /**
   *
   * @type {boolean}
   * @memberof CurlRequestForm
   */
  jsonContent?: boolean
  /**
   *
   * @type {string}
   * @memberof CurlRequestForm
   */
  method?: string
  /**
   *
   * @type {string}
   * @memberof CurlRequestForm
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CurlRequestForm
   */
  url?: string
}
/**
 *
 * @export
 * @interface IntstrIntOrString
 */
export interface IntstrIntOrString {
  /**
   *
   * @type {number}
   * @memberof IntstrIntOrString
   */
  intVal?: number
  /**
   *
   * @type {string}
   * @memberof IntstrIntOrString
   */
  strVal?: string
  /**
   *
   * @type {number}
   * @memberof IntstrIntOrString
   */
  type?: number
}
/**
 *
 * @export
 * @interface ResourceQuantity
 */
export interface ResourceQuantity {
  /**
   *
   * @type {string}
   * @memberof ResourceQuantity
   */
  Format?: string
}
/**
 *
 * @export
 * @interface StatusAllChaosStatus
 */
export interface StatusAllChaosStatus {
  /**
   *
   * @type {number}
   * @memberof StatusAllChaosStatus
   */
  deleting?: number
  /**
   *
   * @type {number}
   * @memberof StatusAllChaosStatus
   */
  finished?: number
  /**
   *
   * @type {number}
   * @memberof StatusAllChaosStatus
   */
  injecting?: number
  /**
   *
   * @type {number}
   * @memberof StatusAllChaosStatus
   */
  paused?: number
  /**
   *
   * @type {number}
   * @memberof StatusAllChaosStatus
   */
  running?: number
}
/**
 *
 * @export
 * @interface TypesArchive
 */
export interface TypesArchive {
  /**
   *
   * @type {string}
   * @memberof TypesArchive
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchive
   */
  kind?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchive
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchive
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchive
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesArchiveDetail
 */
export interface TypesArchiveDetail {
  /**
   *
   * @type {string}
   * @memberof TypesArchiveDetail
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchiveDetail
   */
  kind?: string
  /**
   *
   * @type {CoreKubeObjectDesc}
   * @memberof TypesArchiveDetail
   */
  kube_object?: CoreKubeObjectDesc
  /**
   *
   * @type {string}
   * @memberof TypesArchiveDetail
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchiveDetail
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesArchiveDetail
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesExperiment
 */
export interface TypesExperiment {
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  failed_message?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  kind?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperiment
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesExperimentDetail
 */
export interface TypesExperimentDetail {
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  failed_message?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  kind?: string
  /**
   *
   * @type {CoreKubeObjectDesc}
   * @memberof TypesExperimentDetail
   */
  kube_object?: CoreKubeObjectDesc
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof TypesExperimentDetail
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesPhysicalMachine
 */
export interface TypesPhysicalMachine {
  /**
   *
   * @type {string}
   * @memberof TypesPhysicalMachine
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof TypesPhysicalMachine
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesPhysicalMachine
   */
  namespace?: string
}
/**
 *
 * @export
 * @interface TypesPod
 */
export interface TypesPod {
  /**
   *
   * @type {string}
   * @memberof TypesPod
   */
  ip?: string
  /**
   *
   * @type {string}
   * @memberof TypesPod
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesPod
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesPod
   */
  state?: string
}
/**
 *
 * @export
 * @interface TypesSchedule
 */
export interface TypesSchedule {
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  kind?: string
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof TypesSchedule
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesScheduleDetail
 */
export interface TypesScheduleDetail {
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  created_at?: string
  /**
   *
   * @type {Array<string>}
   * @memberof TypesScheduleDetail
   */
  experiment_uids?: Array<string>
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  kind?: string
  /**
   *
   * @type {CoreKubeObjectDesc}
   * @memberof TypesScheduleDetail
   */
  kube_object?: CoreKubeObjectDesc
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof TypesScheduleDetail
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesStatusCheckTemplate
 */
export interface TypesStatusCheckTemplate {
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplate
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplate
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplate
   */
  namespace?: string
  /**
   *
   * @type {V1alpha1StatusCheckTemplate}
   * @memberof TypesStatusCheckTemplate
   */
  spec?: V1alpha1StatusCheckTemplate
}
/**
 *
 * @export
 * @interface TypesStatusCheckTemplateBase
 */
export interface TypesStatusCheckTemplateBase {
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateBase
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateBase
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateBase
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateBase
   */
  namespace?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateBase
   */
  uid?: string
}
/**
 *
 * @export
 * @interface TypesStatusCheckTemplateDetail
 */
export interface TypesStatusCheckTemplateDetail {
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateDetail
   */
  created_at?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateDetail
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateDetail
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateDetail
   */
  namespace?: string
  /**
   *
   * @type {V1alpha1StatusCheckTemplate}
   * @memberof TypesStatusCheckTemplateDetail
   */
  spec?: V1alpha1StatusCheckTemplate
  /**
   *
   * @type {string}
   * @memberof TypesStatusCheckTemplateDetail
   */
  uid?: string
}
/**
 *
 * @export
 * @interface UtilsAPIError
 */
export interface UtilsAPIError {
  /**
   *
   * @type {number}
   * @memberof UtilsAPIError
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof UtilsAPIError
   */
  full_text?: string
  /**
   *
   * @type {string}
   * @memberof UtilsAPIError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof UtilsAPIError
   */
  type?: string
}
/**
 *
 * @export
 * @interface UtilsResponse
 */
export interface UtilsResponse {
  /**
   *
   * @type {string}
   * @memberof UtilsResponse
   */
  status?: string
}
/**
 *
 * @export
 * @interface V1AWSElasticBlockStoreVolumeSource
 */
export interface V1AWSElasticBlockStoreVolumeSource {
  /**
   * Filesystem type of the volume that you want to mount. Tip: Ensure that the filesystem type is supported by the host operating system. Examples: \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore TODO: how do we prevent errors in the filesystem from compromising the machine +optional
   * @type {string}
   * @memberof V1AWSElasticBlockStoreVolumeSource
   */
  fsType?: string
  /**
   * The partition in the volume that you want to mount. If omitted, the default is to mount by volume name. Examples: For volume /dev/sda1, you specify the partition as \"1\". Similarly, the volume partition for /dev/sda is \"0\" (or you can leave the property empty). +optional
   * @type {number}
   * @memberof V1AWSElasticBlockStoreVolumeSource
   */
  partition?: number
  /**
   * Specify \"true\" to force and set the ReadOnly property in VolumeMounts to \"true\". If omitted, the default is \"false\". More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore +optional
   * @type {boolean}
   * @memberof V1AWSElasticBlockStoreVolumeSource
   */
  readOnly?: boolean
  /**
   * Unique ID of the persistent disk resource in AWS (Amazon EBS volume). More info: https://kubernetes.io/docs/concepts/storage/volumes#awselasticblockstore
   * @type {string}
   * @memberof V1AWSElasticBlockStoreVolumeSource
   */
  volumeID?: string
}
/**
 *
 * @export
 * @interface V1AzureDiskVolumeSource
 */
export interface V1AzureDiskVolumeSource {
  /**
   * Host Caching mode: None, Read Only, Read Write. +optional
   * @type {string}
   * @memberof V1AzureDiskVolumeSource
   */
  cachingMode?: string
  /**
   * The Name of the data disk in the blob storage
   * @type {string}
   * @memberof V1AzureDiskVolumeSource
   */
  diskName?: string
  /**
   * The URI the data disk in the blob storage
   * @type {string}
   * @memberof V1AzureDiskVolumeSource
   */
  diskURI?: string
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. +optional
   * @type {string}
   * @memberof V1AzureDiskVolumeSource
   */
  fsType?: string
  /**
   * Expected values Shared: multiple blob disks per storage account  Dedicated: single blob disk per storage account  Managed: azure managed data disk (only in managed availability set). defaults to shared
   * @type {string}
   * @memberof V1AzureDiskVolumeSource
   */
  kind?: string
  /**
   * Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1AzureDiskVolumeSource
   */
  readOnly?: boolean
}
/**
 *
 * @export
 * @interface V1AzureFileVolumeSource
 */
export interface V1AzureFileVolumeSource {
  /**
   * Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1AzureFileVolumeSource
   */
  readOnly?: boolean
  /**
   * the name of secret that contains Azure Storage Account Name and Key
   * @type {string}
   * @memberof V1AzureFileVolumeSource
   */
  secretName?: string
  /**
   * Share Name
   * @type {string}
   * @memberof V1AzureFileVolumeSource
   */
  shareName?: string
}
/**
 *
 * @export
 * @interface V1CSIVolumeSource
 */
export interface V1CSIVolumeSource {
  /**
   * Driver is the name of the CSI driver that handles this volume. Consult with your admin for the correct name as registered in the cluster.
   * @type {string}
   * @memberof V1CSIVolumeSource
   */
  driver?: string
  /**
   * Filesystem type to mount. Ex. \"ext4\", \"xfs\", \"ntfs\". If not provided, the empty value is passed to the associated CSI driver which will determine the default filesystem to apply. +optional
   * @type {string}
   * @memberof V1CSIVolumeSource
   */
  fsType?: string
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1CSIVolumeSource
   */
  nodePublishSecretRef?: V1LocalObjectReference
  /**
   * Specifies a read-only configuration for the volume. Defaults to false (read/write). +optional
   * @type {boolean}
   * @memberof V1CSIVolumeSource
   */
  readOnly?: boolean
  /**
   * VolumeAttributes stores driver-specific properties that are passed to the CSI driver. Consult your driver\'s documentation for supported values. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1CSIVolumeSource
   */
  volumeAttributes?: { [key: string]: string }
}
/**
 *
 * @export
 * @interface V1Capabilities
 */
export interface V1Capabilities {
  /**
   * Added capabilities +optional
   * @type {Array<string>}
   * @memberof V1Capabilities
   */
  add?: Array<string>
  /**
   * Removed capabilities +optional
   * @type {Array<string>}
   * @memberof V1Capabilities
   */
  drop?: Array<string>
}
/**
 *
 * @export
 * @interface V1CephFSVolumeSource
 */
export interface V1CephFSVolumeSource {
  /**
   * Required: Monitors is a collection of Ceph monitors More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
   * @type {Array<string>}
   * @memberof V1CephFSVolumeSource
   */
  monitors?: Array<string>
  /**
   * Optional: Used as the mounted root, rather than the full Ceph tree, default is / +optional
   * @type {string}
   * @memberof V1CephFSVolumeSource
   */
  path?: string
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it +optional
   * @type {boolean}
   * @memberof V1CephFSVolumeSource
   */
  readOnly?: boolean
  /**
   * Optional: SecretFile is the path to key ring for User, default is /etc/ceph/user.secret More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it +optional
   * @type {string}
   * @memberof V1CephFSVolumeSource
   */
  secretFile?: string
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1CephFSVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * Optional: User is the rados user name, default is admin More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it +optional
   * @type {string}
   * @memberof V1CephFSVolumeSource
   */
  user?: string
}
/**
 *
 * @export
 * @interface V1CinderVolumeSource
 */
export interface V1CinderVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Examples: \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. More info: https://examples.k8s.io/mysql-cinder-pd/README.md +optional
   * @type {string}
   * @memberof V1CinderVolumeSource
   */
  fsType?: string
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. More info: https://examples.k8s.io/mysql-cinder-pd/README.md +optional
   * @type {boolean}
   * @memberof V1CinderVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1CinderVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * volume id used to identify the volume in cinder. More info: https://examples.k8s.io/mysql-cinder-pd/README.md
   * @type {string}
   * @memberof V1CinderVolumeSource
   */
  volumeID?: string
}
/**
 *
 * @export
 * @interface V1ConfigMapEnvSource
 */
export interface V1ConfigMapEnvSource {
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1ConfigMapEnvSource
   */
  name?: string
  /**
   * Specify whether the ConfigMap must be defined +optional
   * @type {boolean}
   * @memberof V1ConfigMapEnvSource
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1ConfigMapKeySelector
 */
export interface V1ConfigMapKeySelector {
  /**
   * The key to select.
   * @type {string}
   * @memberof V1ConfigMapKeySelector
   */
  key?: string
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1ConfigMapKeySelector
   */
  name?: string
  /**
   * Specify whether the ConfigMap or its key must be defined +optional
   * @type {boolean}
   * @memberof V1ConfigMapKeySelector
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1ConfigMapProjection
 */
export interface V1ConfigMapProjection {
  /**
   * If unspecified, each key-value pair in the Data field of the referenced ConfigMap will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the ConfigMap, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the \'..\' path or start with \'..\'. +optional
   * @type {Array<V1KeyToPath>}
   * @memberof V1ConfigMapProjection
   */
  items?: Array<V1KeyToPath>
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1ConfigMapProjection
   */
  name?: string
  /**
   * Specify whether the ConfigMap or its keys must be defined +optional
   * @type {boolean}
   * @memberof V1ConfigMapProjection
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1ConfigMapVolumeSource
 */
export interface V1ConfigMapVolumeSource {
  /**
   * Optional: mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Defaults to 0644. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1ConfigMapVolumeSource
   */
  defaultMode?: number
  /**
   * If unspecified, each key-value pair in the Data field of the referenced ConfigMap will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the ConfigMap, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the \'..\' path or start with \'..\'. +optional
   * @type {Array<V1KeyToPath>}
   * @memberof V1ConfigMapVolumeSource
   */
  items?: Array<V1KeyToPath>
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1ConfigMapVolumeSource
   */
  name?: string
  /**
   * Specify whether the ConfigMap or its keys must be defined +optional
   * @type {boolean}
   * @memberof V1ConfigMapVolumeSource
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1Container
 */
export interface V1Container {
  /**
   * Arguments to the entrypoint. The docker image\'s CMD is used if this is not provided. Variable references $(VAR_NAME) are expanded using the container\'s environment. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. \"$$(VAR_NAME)\" will produce the string literal \"$(VAR_NAME)\". Escaped references will never be expanded, regardless of whether the variable exists or not. Cannot be updated. More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell +optional
   * @type {Array<string>}
   * @memberof V1Container
   */
  args?: Array<string>
  /**
   * Entrypoint array. Not executed within a shell. The docker image\'s ENTRYPOINT is used if this is not provided. Variable references $(VAR_NAME) are expanded using the container\'s environment. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. \"$$(VAR_NAME)\" will produce the string literal \"$(VAR_NAME)\". Escaped references will never be expanded, regardless of whether the variable exists or not. Cannot be updated. More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell +optional
   * @type {Array<string>}
   * @memberof V1Container
   */
  command?: Array<string>
  /**
   * List of environment variables to set in the container. Cannot be updated. +optional +patchMergeKey=name +patchStrategy=merge
   * @type {Array<V1EnvVar>}
   * @memberof V1Container
   */
  env?: Array<V1EnvVar>
  /**
   * List of sources to populate environment variables in the container. The keys defined within a source must be a C_IDENTIFIER. All invalid keys will be reported as an event when the container is starting. When a key exists in multiple sources, the value associated with the last source will take precedence. Values defined by an Env with a duplicate key will take precedence. Cannot be updated. +optional
   * @type {Array<V1EnvFromSource>}
   * @memberof V1Container
   */
  envFrom?: Array<V1EnvFromSource>
  /**
   * Docker image name. More info: https://kubernetes.io/docs/concepts/containers/images This field is optional to allow higher level config management to default or override container images in workload controllers like Deployments and StatefulSets. +optional
   * @type {string}
   * @memberof V1Container
   */
  image?: string
  /**
   * Image pull policy. One of Always, Never, IfNotPresent. Defaults to Always if :latest tag is specified, or IfNotPresent otherwise. Cannot be updated. More info: https://kubernetes.io/docs/concepts/containers/images#updating-images +optional
   * @type {string}
   * @memberof V1Container
   */
  imagePullPolicy?: string
  /**
   *
   * @type {V1Lifecycle}
   * @memberof V1Container
   */
  lifecycle?: V1Lifecycle
  /**
   *
   * @type {V1Probe}
   * @memberof V1Container
   */
  livenessProbe?: V1Probe
  /**
   * Name of the container specified as a DNS_LABEL. Each container in a pod must have a unique name (DNS_LABEL). Cannot be updated.
   * @type {string}
   * @memberof V1Container
   */
  name?: string
  /**
   * List of ports to expose from the container. Exposing a port here gives the system additional information about the network connections a container uses, but is primarily informational. Not specifying a port here DOES NOT prevent that port from being exposed. Any port which is listening on the default \"0.0.0.0\" address inside a container will be accessible from the network. Cannot be updated. +optional +patchMergeKey=containerPort +patchStrategy=merge +listType=map +listMapKey=containerPort +listMapKey=protocol
   * @type {Array<V1ContainerPort>}
   * @memberof V1Container
   */
  ports?: Array<V1ContainerPort>
  /**
   *
   * @type {V1Probe}
   * @memberof V1Container
   */
  readinessProbe?: V1Probe
  /**
   *
   * @type {V1ResourceRequirements}
   * @memberof V1Container
   */
  resources?: V1ResourceRequirements
  /**
   *
   * @type {V1SecurityContext}
   * @memberof V1Container
   */
  securityContext?: V1SecurityContext
  /**
   *
   * @type {V1Probe}
   * @memberof V1Container
   */
  startupProbe?: V1Probe
  /**
   * Whether this container should allocate a buffer for stdin in the container runtime. If this is not set, reads from stdin in the container will always result in EOF. Default is false. +optional
   * @type {boolean}
   * @memberof V1Container
   */
  stdin?: boolean
  /**
   * Whether the container runtime should close the stdin channel after it has been opened by a single attach. When stdin is true the stdin stream will remain open across multiple attach sessions. If stdinOnce is set to true, stdin is opened on container start, is empty until the first client attaches to stdin, and then remains open and accepts data until the client disconnects, at which time stdin is closed and remains closed until the container is restarted. If this flag is false, a container processes that reads from stdin will never receive an EOF. Default is false +optional
   * @type {boolean}
   * @memberof V1Container
   */
  stdinOnce?: boolean
  /**
   * Optional: Path at which the file to which the container\'s termination message will be written is mounted into the container\'s filesystem. Message written is intended to be brief final status, such as an assertion failure message. Will be truncated by the node if greater than 4096 bytes. The total message length across all containers will be limited to 12kb. Defaults to /dev/termination-log. Cannot be updated. +optional
   * @type {string}
   * @memberof V1Container
   */
  terminationMessagePath?: string
  /**
   * Indicate how the termination message should be populated. File will use the contents of terminationMessagePath to populate the container status message on both success and failure. FallbackToLogsOnError will use the last chunk of container log output if the termination message file is empty and the container exited with an error. The log output is limited to 2048 bytes or 80 lines, whichever is smaller. Defaults to File. Cannot be updated. +optional
   * @type {string}
   * @memberof V1Container
   */
  terminationMessagePolicy?: string
  /**
   * Whether this container should allocate a TTY for itself, also requires \'stdin\' to be true. Default is false. +optional
   * @type {boolean}
   * @memberof V1Container
   */
  tty?: boolean
  /**
   * volumeDevices is the list of block devices to be used by the container. +patchMergeKey=devicePath +patchStrategy=merge +optional
   * @type {Array<V1VolumeDevice>}
   * @memberof V1Container
   */
  volumeDevices?: Array<V1VolumeDevice>
  /**
   * Pod volumes to mount into the container\'s filesystem. Cannot be updated. +optional +patchMergeKey=mountPath +patchStrategy=merge
   * @type {Array<V1VolumeMount>}
   * @memberof V1Container
   */
  volumeMounts?: Array<V1VolumeMount>
  /**
   * Container\'s working directory. If not specified, the container runtime\'s default will be used, which might be configured in the container image. Cannot be updated. +optional
   * @type {string}
   * @memberof V1Container
   */
  workingDir?: string
}
/**
 *
 * @export
 * @interface V1ContainerPort
 */
export interface V1ContainerPort {
  /**
   * Number of port to expose on the pod\'s IP address. This must be a valid port number, 0 < x < 65536.
   * @type {number}
   * @memberof V1ContainerPort
   */
  containerPort?: number
  /**
   * What host IP to bind the external port to. +optional
   * @type {string}
   * @memberof V1ContainerPort
   */
  hostIP?: string
  /**
   * Number of port to expose on the host. If specified, this must be a valid port number, 0 < x < 65536. If HostNetwork is specified, this must match ContainerPort. Most containers do not need this. +optional
   * @type {number}
   * @memberof V1ContainerPort
   */
  hostPort?: number
  /**
   * If specified, this must be an IANA_SVC_NAME and unique within the pod. Each named port in a pod must have a unique name. Name for the port that can be referred to by services. +optional
   * @type {string}
   * @memberof V1ContainerPort
   */
  name?: string
  /**
   * Protocol for port. Must be UDP, TCP, or SCTP. Defaults to \"TCP\". +optional +default=\"TCP\"
   * @type {string}
   * @memberof V1ContainerPort
   */
  protocol?: string
}
/**
 *
 * @export
 * @interface V1DownwardAPIProjection
 */
export interface V1DownwardAPIProjection {
  /**
   * Items is a list of DownwardAPIVolume file +optional
   * @type {Array<V1DownwardAPIVolumeFile>}
   * @memberof V1DownwardAPIProjection
   */
  items?: Array<V1DownwardAPIVolumeFile>
}
/**
 *
 * @export
 * @interface V1DownwardAPIVolumeFile
 */
export interface V1DownwardAPIVolumeFile {
  /**
   *
   * @type {V1ObjectFieldSelector}
   * @memberof V1DownwardAPIVolumeFile
   */
  fieldRef?: V1ObjectFieldSelector
  /**
   * Optional: mode bits used to set permissions on this file, must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. If not specified, the volume defaultMode will be used. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1DownwardAPIVolumeFile
   */
  mode?: number
  /**
   * Required: Path is  the relative path name of the file to be created. Must not be absolute or contain the \'..\' path. Must be utf-8 encoded. The first item of the relative path must not start with \'..\'
   * @type {string}
   * @memberof V1DownwardAPIVolumeFile
   */
  path?: string
  /**
   *
   * @type {V1ResourceFieldSelector}
   * @memberof V1DownwardAPIVolumeFile
   */
  resourceFieldRef?: V1ResourceFieldSelector
}
/**
 *
 * @export
 * @interface V1DownwardAPIVolumeSource
 */
export interface V1DownwardAPIVolumeSource {
  /**
   * Optional: mode bits to use on created files by default. Must be a Optional: mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Defaults to 0644. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1DownwardAPIVolumeSource
   */
  defaultMode?: number
  /**
   * Items is a list of downward API volume file +optional
   * @type {Array<V1DownwardAPIVolumeFile>}
   * @memberof V1DownwardAPIVolumeSource
   */
  items?: Array<V1DownwardAPIVolumeFile>
}
/**
 *
 * @export
 * @interface V1EmptyDirVolumeSource
 */
export interface V1EmptyDirVolumeSource {
  /**
   * What type of storage medium should back this directory. The default is \"\" which means to use the node\'s default medium. Must be an empty string (default) or Memory. More info: https://kubernetes.io/docs/concepts/storage/volumes#emptydir +optional
   * @type {string}
   * @memberof V1EmptyDirVolumeSource
   */
  medium?: string
  /**
   *
   * @type {ResourceQuantity}
   * @memberof V1EmptyDirVolumeSource
   */
  sizeLimit?: ResourceQuantity
}
/**
 *
 * @export
 * @interface V1EnvFromSource
 */
export interface V1EnvFromSource {
  /**
   *
   * @type {V1ConfigMapEnvSource}
   * @memberof V1EnvFromSource
   */
  configMapRef?: V1ConfigMapEnvSource
  /**
   * An optional identifier to prepend to each key in the ConfigMap. Must be a C_IDENTIFIER. +optional
   * @type {string}
   * @memberof V1EnvFromSource
   */
  prefix?: string
  /**
   *
   * @type {V1SecretEnvSource}
   * @memberof V1EnvFromSource
   */
  secretRef?: V1SecretEnvSource
}
/**
 *
 * @export
 * @interface V1EnvVar
 */
export interface V1EnvVar {
  /**
   * Name of the environment variable. Must be a C_IDENTIFIER.
   * @type {string}
   * @memberof V1EnvVar
   */
  name?: string
  /**
   * Variable references $(VAR_NAME) are expanded using the previously defined environment variables in the container and any service environment variables. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. \"$$(VAR_NAME)\" will produce the string literal \"$(VAR_NAME)\". Escaped references will never be expanded, regardless of whether the variable exists or not. Defaults to \"\". +optional
   * @type {string}
   * @memberof V1EnvVar
   */
  value?: string
  /**
   *
   * @type {V1EnvVarSource}
   * @memberof V1EnvVar
   */
  valueFrom?: V1EnvVarSource
}
/**
 *
 * @export
 * @interface V1EnvVarSource
 */
export interface V1EnvVarSource {
  /**
   *
   * @type {V1ConfigMapKeySelector}
   * @memberof V1EnvVarSource
   */
  configMapKeyRef?: V1ConfigMapKeySelector
  /**
   *
   * @type {V1ObjectFieldSelector}
   * @memberof V1EnvVarSource
   */
  fieldRef?: V1ObjectFieldSelector
  /**
   *
   * @type {V1ResourceFieldSelector}
   * @memberof V1EnvVarSource
   */
  resourceFieldRef?: V1ResourceFieldSelector
  /**
   *
   * @type {V1SecretKeySelector}
   * @memberof V1EnvVarSource
   */
  secretKeyRef?: V1SecretKeySelector
}
/**
 *
 * @export
 * @interface V1EphemeralVolumeSource
 */
export interface V1EphemeralVolumeSource {
  /**
   *
   * @type {V1PersistentVolumeClaimTemplate}
   * @memberof V1EphemeralVolumeSource
   */
  volumeClaimTemplate?: V1PersistentVolumeClaimTemplate
}
/**
 *
 * @export
 * @interface V1ExecAction
 */
export interface V1ExecAction {
  /**
   * Command is the command line to execute inside the container, the working directory for the command  is root (\'/\') in the container\'s filesystem. The command is simply exec\'d, it is not run inside a shell, so traditional shell instructions (\'|\', etc) won\'t work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy. +optional
   * @type {Array<string>}
   * @memberof V1ExecAction
   */
  command?: Array<string>
}
/**
 *
 * @export
 * @interface V1FCVolumeSource
 */
export interface V1FCVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. TODO: how do we prevent errors in the filesystem from compromising the machine +optional
   * @type {string}
   * @memberof V1FCVolumeSource
   */
  fsType?: string
  /**
   * Optional: FC target lun number +optional
   * @type {number}
   * @memberof V1FCVolumeSource
   */
  lun?: number
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1FCVolumeSource
   */
  readOnly?: boolean
  /**
   * Optional: FC target worldwide names (WWNs) +optional
   * @type {Array<string>}
   * @memberof V1FCVolumeSource
   */
  targetWWNs?: Array<string>
  /**
   * Optional: FC volume world wide identifiers (wwids) Either wwids or combination of targetWWNs and lun must be set, but not both simultaneously. +optional
   * @type {Array<string>}
   * @memberof V1FCVolumeSource
   */
  wwids?: Array<string>
}
/**
 *
 * @export
 * @interface V1FlexVolumeSource
 */
export interface V1FlexVolumeSource {
  /**
   * Driver is the name of the driver to use for this volume.
   * @type {string}
   * @memberof V1FlexVolumeSource
   */
  driver?: string
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". The default filesystem depends on FlexVolume script. +optional
   * @type {string}
   * @memberof V1FlexVolumeSource
   */
  fsType?: string
  /**
   * Optional: Extra command options if any. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1FlexVolumeSource
   */
  options?: { [key: string]: string }
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1FlexVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1FlexVolumeSource
   */
  secretRef?: V1LocalObjectReference
}
/**
 *
 * @export
 * @interface V1FlockerVolumeSource
 */
export interface V1FlockerVolumeSource {
  /**
   * Name of the dataset stored as metadata -> name on the dataset for Flocker should be considered as deprecated +optional
   * @type {string}
   * @memberof V1FlockerVolumeSource
   */
  datasetName?: string
  /**
   * UUID of the dataset. This is unique identifier of a Flocker dataset +optional
   * @type {string}
   * @memberof V1FlockerVolumeSource
   */
  datasetUUID?: string
}
/**
 *
 * @export
 * @interface V1GCEPersistentDiskVolumeSource
 */
export interface V1GCEPersistentDiskVolumeSource {
  /**
   * Filesystem type of the volume that you want to mount. Tip: Ensure that the filesystem type is supported by the host operating system. Examples: \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk TODO: how do we prevent errors in the filesystem from compromising the machine +optional
   * @type {string}
   * @memberof V1GCEPersistentDiskVolumeSource
   */
  fsType?: string
  /**
   * The partition in the volume that you want to mount. If omitted, the default is to mount by volume name. Examples: For volume /dev/sda1, you specify the partition as \"1\". Similarly, the volume partition for /dev/sda is \"0\" (or you can leave the property empty). More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk +optional
   * @type {number}
   * @memberof V1GCEPersistentDiskVolumeSource
   */
  partition?: number
  /**
   * Unique name of the PD resource in GCE. Used to identify the disk in GCE. More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk
   * @type {string}
   * @memberof V1GCEPersistentDiskVolumeSource
   */
  pdName?: string
  /**
   * ReadOnly here will force the ReadOnly setting in VolumeMounts. Defaults to false. More info: https://kubernetes.io/docs/concepts/storage/volumes#gcepersistentdisk +optional
   * @type {boolean}
   * @memberof V1GCEPersistentDiskVolumeSource
   */
  readOnly?: boolean
}
/**
 *
 * @export
 * @interface V1GRPCAction
 */
export interface V1GRPCAction {
  /**
   * Port number of the gRPC service. Number must be in the range 1 to 65535.
   * @type {number}
   * @memberof V1GRPCAction
   */
  port?: number
  /**
   * Service is the name of the service to place in the gRPC HealthCheckRequest (see https://github.com/grpc/grpc/blob/master/doc/health-checking.md).  If this is not specified, the default behavior is defined by gRPC. +optional +default=\"\"
   * @type {string}
   * @memberof V1GRPCAction
   */
  service?: string
}
/**
 *
 * @export
 * @interface V1GitRepoVolumeSource
 */
export interface V1GitRepoVolumeSource {
  /**
   * Target directory name. Must not contain or start with \'..\'.  If \'.\' is supplied, the volume directory will be the git repository.  Otherwise, if specified, the volume will contain the git repository in the subdirectory with the given name. +optional
   * @type {string}
   * @memberof V1GitRepoVolumeSource
   */
  directory?: string
  /**
   * Repository URL
   * @type {string}
   * @memberof V1GitRepoVolumeSource
   */
  repository?: string
  /**
   * Commit hash for the specified revision. +optional
   * @type {string}
   * @memberof V1GitRepoVolumeSource
   */
  revision?: string
}
/**
 *
 * @export
 * @interface V1GlusterfsVolumeSource
 */
export interface V1GlusterfsVolumeSource {
  /**
   * EndpointsName is the endpoint name that details Glusterfs topology. More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod
   * @type {string}
   * @memberof V1GlusterfsVolumeSource
   */
  endpoints?: string
  /**
   * Path is the Glusterfs volume path. More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod
   * @type {string}
   * @memberof V1GlusterfsVolumeSource
   */
  path?: string
  /**
   * ReadOnly here will force the Glusterfs volume to be mounted with read-only permissions. Defaults to false. More info: https://examples.k8s.io/volumes/glusterfs/README.md#create-a-pod +optional
   * @type {boolean}
   * @memberof V1GlusterfsVolumeSource
   */
  readOnly?: boolean
}
/**
 *
 * @export
 * @interface V1HTTPGetAction
 */
export interface V1HTTPGetAction {
  /**
   * Host name to connect to, defaults to the pod IP. You probably want to set \"Host\" in httpHeaders instead. +optional
   * @type {string}
   * @memberof V1HTTPGetAction
   */
  host?: string
  /**
   * Custom headers to set in the request. HTTP allows repeated headers. +optional
   * @type {Array<V1HTTPHeader>}
   * @memberof V1HTTPGetAction
   */
  httpHeaders?: Array<V1HTTPHeader>
  /**
   * Path to access on the HTTP server. +optional
   * @type {string}
   * @memberof V1HTTPGetAction
   */
  path?: string
  /**
   *
   * @type {IntstrIntOrString}
   * @memberof V1HTTPGetAction
   */
  port?: IntstrIntOrString
  /**
   * Scheme to use for connecting to the host. Defaults to HTTP. +optional
   * @type {string}
   * @memberof V1HTTPGetAction
   */
  scheme?: string
}
/**
 *
 * @export
 * @interface V1HTTPHeader
 */
export interface V1HTTPHeader {
  /**
   * The header field name
   * @type {string}
   * @memberof V1HTTPHeader
   */
  name?: string
  /**
   * The header field value
   * @type {string}
   * @memberof V1HTTPHeader
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1HostPathVolumeSource
 */
export interface V1HostPathVolumeSource {
  /**
   * Path of the directory on the host. If the path is a symlink, it will follow the link to the real path. More info: https://kubernetes.io/docs/concepts/storage/volumes#hostpath
   * @type {string}
   * @memberof V1HostPathVolumeSource
   */
  path?: string
  /**
   * Type for HostPath Volume Defaults to \"\" More info: https://kubernetes.io/docs/concepts/storage/volumes#hostpath +optional
   * @type {string}
   * @memberof V1HostPathVolumeSource
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1ISCSIVolumeSource
 */
export interface V1ISCSIVolumeSource {
  /**
   * whether support iSCSI Discovery CHAP authentication +optional
   * @type {boolean}
   * @memberof V1ISCSIVolumeSource
   */
  chapAuthDiscovery?: boolean
  /**
   * whether support iSCSI Session CHAP authentication +optional
   * @type {boolean}
   * @memberof V1ISCSIVolumeSource
   */
  chapAuthSession?: boolean
  /**
   * Filesystem type of the volume that you want to mount. Tip: Ensure that the filesystem type is supported by the host operating system. Examples: \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. More info: https://kubernetes.io/docs/concepts/storage/volumes#iscsi TODO: how do we prevent errors in the filesystem from compromising the machine +optional
   * @type {string}
   * @memberof V1ISCSIVolumeSource
   */
  fsType?: string
  /**
   * Custom iSCSI Initiator Name. If initiatorName is specified with iscsiInterface simultaneously, new iSCSI interface <target portal>:<volume name> will be created for the connection. +optional
   * @type {string}
   * @memberof V1ISCSIVolumeSource
   */
  initiatorName?: string
  /**
   * Target iSCSI Qualified Name.
   * @type {string}
   * @memberof V1ISCSIVolumeSource
   */
  iqn?: string
  /**
   * iSCSI Interface Name that uses an iSCSI transport. Defaults to \'default\' (tcp). +optional
   * @type {string}
   * @memberof V1ISCSIVolumeSource
   */
  iscsiInterface?: string
  /**
   * iSCSI Target Lun number.
   * @type {number}
   * @memberof V1ISCSIVolumeSource
   */
  lun?: number
  /**
   * iSCSI Target Portal List. The portal is either an IP or ip_addr:port if the port is other than default (typically TCP ports 860 and 3260). +optional
   * @type {Array<string>}
   * @memberof V1ISCSIVolumeSource
   */
  portals?: Array<string>
  /**
   * ReadOnly here will force the ReadOnly setting in VolumeMounts. Defaults to false. +optional
   * @type {boolean}
   * @memberof V1ISCSIVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1ISCSIVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * iSCSI Target Portal. The Portal is either an IP or ip_addr:port if the port is other than default (typically TCP ports 860 and 3260).
   * @type {string}
   * @memberof V1ISCSIVolumeSource
   */
  targetPortal?: string
}
/**
 *
 * @export
 * @interface V1KeyToPath
 */
export interface V1KeyToPath {
  /**
   * The key to project.
   * @type {string}
   * @memberof V1KeyToPath
   */
  key?: string
  /**
   * Optional: mode bits used to set permissions on this file. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. If not specified, the volume defaultMode will be used. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1KeyToPath
   */
  mode?: number
  /**
   * The relative path of the file to map the key to. May not be an absolute path. May not contain the path element \'..\'. May not start with the string \'..\'.
   * @type {string}
   * @memberof V1KeyToPath
   */
  path?: string
}
/**
 *
 * @export
 * @interface V1LabelSelector
 */
export interface V1LabelSelector {
  /**
   * matchExpressions is a list of label selector requirements. The requirements are ANDed. +optional
   * @type {Array<V1LabelSelectorRequirement>}
   * @memberof V1LabelSelector
   */
  matchExpressions?: Array<V1LabelSelectorRequirement>
  /**
   * matchLabels is a map of {key,value} pairs. A single {key,value} in the matchLabels map is equivalent to an element of matchExpressions, whose key field is \"key\", the operator is \"In\", and the values array contains only \"value\". The requirements are ANDed. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1LabelSelector
   */
  matchLabels?: { [key: string]: string }
}
/**
 *
 * @export
 * @interface V1LabelSelectorRequirement
 */
export interface V1LabelSelectorRequirement {
  /**
   * key is the label key that the selector applies to. +patchMergeKey=key +patchStrategy=merge
   * @type {string}
   * @memberof V1LabelSelectorRequirement
   */
  key?: string
  /**
   * operator represents a key\'s relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
   * @type {string}
   * @memberof V1LabelSelectorRequirement
   */
  operator?: string
  /**
   * values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty. This array is replaced during a strategic merge patch. +optional
   * @type {Array<string>}
   * @memberof V1LabelSelectorRequirement
   */
  values?: Array<string>
}
/**
 *
 * @export
 * @interface V1Lifecycle
 */
export interface V1Lifecycle {
  /**
   *
   * @type {V1LifecycleHandler}
   * @memberof V1Lifecycle
   */
  postStart?: V1LifecycleHandler
  /**
   *
   * @type {V1LifecycleHandler}
   * @memberof V1Lifecycle
   */
  preStop?: V1LifecycleHandler
}
/**
 *
 * @export
 * @interface V1LifecycleHandler
 */
export interface V1LifecycleHandler {
  /**
   *
   * @type {V1ExecAction}
   * @memberof V1LifecycleHandler
   */
  exec?: V1ExecAction
  /**
   *
   * @type {V1HTTPGetAction}
   * @memberof V1LifecycleHandler
   */
  httpGet?: V1HTTPGetAction
  /**
   *
   * @type {V1TCPSocketAction}
   * @memberof V1LifecycleHandler
   */
  tcpSocket?: V1TCPSocketAction
}
/**
 *
 * @export
 * @interface V1LocalObjectReference
 */
export interface V1LocalObjectReference {
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1LocalObjectReference
   */
  name?: string
}
/**
 *
 * @export
 * @interface V1ManagedFieldsEntry
 */
export interface V1ManagedFieldsEntry {
  /**
   * APIVersion defines the version of this resource that this field set applies to. The format is \"group/version\" just like the top-level APIVersion field. It is necessary to track the version of a field set because it cannot be automatically converted.
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  apiVersion?: string
  /**
   * FieldsType is the discriminator for the different fields format and version. There is currently only one possible value: \"FieldsV1\"
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  fieldsType?: string
  /**
   *
   * @type {object}
   * @memberof V1ManagedFieldsEntry
   */
  fieldsV1?: object
  /**
   * Manager is an identifier of the workflow managing these fields.
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  manager?: string
  /**
   * Operation is the type of operation which lead to this ManagedFieldsEntry being created. The only valid values for this field are \'Apply\' and \'Update\'.
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  operation?: string
  /**
   * Subresource is the name of the subresource used to update that object, or empty string if the object was updated through the main resource. The value of this field is used to distinguish between managers, even if they share the same name. For example, a status update will be distinct from a regular update using the same manager name. Note that the APIVersion field is not related to the Subresource field and it always corresponds to the version of the main resource.
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  subresource?: string
  /**
   * Time is timestamp of when these fields were set. It should always be empty if Operation is \'Apply\' +optional
   * @type {string}
   * @memberof V1ManagedFieldsEntry
   */
  time?: string
}
/**
 *
 * @export
 * @interface V1NFSVolumeSource
 */
export interface V1NFSVolumeSource {
  /**
   * Path that is exported by the NFS server. More info: https://kubernetes.io/docs/concepts/storage/volumes#nfs
   * @type {string}
   * @memberof V1NFSVolumeSource
   */
  path?: string
  /**
   * ReadOnly here will force the NFS export to be mounted with read-only permissions. Defaults to false. More info: https://kubernetes.io/docs/concepts/storage/volumes#nfs +optional
   * @type {boolean}
   * @memberof V1NFSVolumeSource
   */
  readOnly?: boolean
  /**
   * Server is the hostname or IP address of the NFS server. More info: https://kubernetes.io/docs/concepts/storage/volumes#nfs
   * @type {string}
   * @memberof V1NFSVolumeSource
   */
  server?: string
}
/**
 *
 * @export
 * @interface V1ObjectFieldSelector
 */
export interface V1ObjectFieldSelector {
  /**
   * Version of the schema the FieldPath is written in terms of, defaults to \"v1\". +optional
   * @type {string}
   * @memberof V1ObjectFieldSelector
   */
  apiVersion?: string
  /**
   * Path of the field to select in the specified API version.
   * @type {string}
   * @memberof V1ObjectFieldSelector
   */
  fieldPath?: string
}
/**
 *
 * @export
 * @interface V1ObjectReference
 */
export interface V1ObjectReference {
  /**
   * API version of the referent. +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  apiVersion?: string
  /**
   * If referring to a piece of an object instead of an entire object, this string should contain a valid JSON/Go field access statement, such as desiredState.manifest.containers[2]. For example, if the object reference is to a container within a pod, this would take on a value like: \"spec.containers{name}\" (where \"name\" refers to the name of the container that triggered the event) or if no container name is specified \"spec.containers[2]\" (container with index 2 in this pod). This syntax is chosen only to have some well-defined way of referencing a part of an object. TODO: this design is not final and this field is subject to change in the future. +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  fieldPath?: string
  /**
   * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  kind?: string
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  name?: string
  /**
   * Namespace of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/ +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  namespace?: string
  /**
   * Specific resourceVersion to which this reference is made, if any. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  resourceVersion?: string
  /**
   * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#uids +optional
   * @type {string}
   * @memberof V1ObjectReference
   */
  uid?: string
}
/**
 *
 * @export
 * @interface V1OwnerReference
 */
export interface V1OwnerReference {
  /**
   * API version of the referent.
   * @type {string}
   * @memberof V1OwnerReference
   */
  apiVersion?: string
  /**
   * If true, AND if the owner has the \"foregroundDeletion\" finalizer, then the owner cannot be deleted from the key-value store until this reference is removed. Defaults to false. To set this field, a user needs \"delete\" permission of the owner, otherwise 422 (Unprocessable Entity) will be returned. +optional
   * @type {boolean}
   * @memberof V1OwnerReference
   */
  blockOwnerDeletion?: boolean
  /**
   * If true, this reference points to the managing controller. +optional
   * @type {boolean}
   * @memberof V1OwnerReference
   */
  controller?: boolean
  /**
   * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   * @type {string}
   * @memberof V1OwnerReference
   */
  kind?: string
  /**
   * Name of the referent. More info: http://kubernetes.io/docs/user-guide/identifiers#names
   * @type {string}
   * @memberof V1OwnerReference
   */
  name?: string
  /**
   * UID of the referent. More info: http://kubernetes.io/docs/user-guide/identifiers#uids
   * @type {string}
   * @memberof V1OwnerReference
   */
  uid?: string
}
/**
 *
 * @export
 * @interface V1PersistentVolumeClaimSpec
 */
export interface V1PersistentVolumeClaimSpec {
  /**
   * AccessModes contains the desired access modes the volume should have. More info: https://kubernetes.io/docs/concepts/storage/persistent-volumes#access-modes-1 +optional
   * @type {Array<string>}
   * @memberof V1PersistentVolumeClaimSpec
   */
  accessModes?: Array<string>
  /**
   *
   * @type {V1TypedLocalObjectReference}
   * @memberof V1PersistentVolumeClaimSpec
   */
  dataSource?: V1TypedLocalObjectReference
  /**
   *
   * @type {V1TypedLocalObjectReference}
   * @memberof V1PersistentVolumeClaimSpec
   */
  dataSourceRef?: V1TypedLocalObjectReference
  /**
   *
   * @type {V1ResourceRequirements}
   * @memberof V1PersistentVolumeClaimSpec
   */
  resources?: V1ResourceRequirements
  /**
   *
   * @type {V1LabelSelector}
   * @memberof V1PersistentVolumeClaimSpec
   */
  selector?: V1LabelSelector
  /**
   * Name of the StorageClass required by the claim. More info: https://kubernetes.io/docs/concepts/storage/persistent-volumes#class-1 +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimSpec
   */
  storageClassName?: string
  /**
   * volumeMode defines what type of volume is required by the claim. Value of Filesystem is implied when not included in claim spec. +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimSpec
   */
  volumeMode?: string
  /**
   * VolumeName is the binding reference to the PersistentVolume backing this claim. +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimSpec
   */
  volumeName?: string
}
/**
 *
 * @export
 * @interface V1PersistentVolumeClaimTemplate
 */
export interface V1PersistentVolumeClaimTemplate {
  /**
   * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: http://kubernetes.io/docs/user-guide/annotations +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  annotations?: { [key: string]: string }
  /**
   * The name of the cluster which the object belongs to. This is used to distinguish resources with same name and namespace in different clusters. This field is not set anywhere right now and apiserver is going to ignore it if set in create or update request. +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  clusterName?: string
  /**
   * CreationTimestamp is a timestamp representing the server time when this object was created. It is not guaranteed to be set in happens-before order across separate operations. Clients may not set this value. It is represented in RFC3339 form and is in UTC.  Populated by the system. Read-only. Null for lists. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  creationTimestamp?: string
  /**
   * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only. +optional
   * @type {number}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  deletionGracePeriodSeconds?: number
  /**
   * DeletionTimestamp is RFC 3339 date and time at which this resource will be deleted. This field is set by the server when a graceful deletion is requested by the user, and is not directly settable by a client. The resource is expected to be deleted (no longer visible from resource lists, and not reachable by name) after the time in this field, once the finalizers list is empty. As long as the finalizers list contains items, deletion is blocked. Once the deletionTimestamp is set, this value may not be unset or be set further into the future, although it may be shortened or the resource may be deleted prior to this time. For example, a user may request that a pod is deleted in 30 seconds. The Kubelet will react by sending a graceful termination signal to the containers in the pod. After that 30 seconds, the Kubelet will send a hard termination signal (SIGKILL) to the container and after cleanup, remove the pod from the API. In the presence of network partitions, this object may still exist after this timestamp, until an administrator or automated process can determine the resource is fully terminated. If not set, graceful deletion of the object has not been requested.  Populated by the system when a graceful deletion is requested. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  deletionTimestamp?: string
  /**
   * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list. +optional +patchStrategy=merge
   * @type {Array<string>}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  finalizers?: Array<string>
  /**
   * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will NOT return a 409 - instead, it will either return 201 Created or 500 with Reason ServerTimeout indicating a unique name could not be found in the time allotted, and the client should retry (optionally after the time indicated in the Retry-After header).  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  generateName?: string
  /**
   * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only. +optional
   * @type {number}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  generation?: number
  /**
   * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: http://kubernetes.io/docs/user-guide/labels +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  labels?: { [key: string]: string }
  /**
   * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.  +optional
   * @type {Array<V1ManagedFieldsEntry>}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  managedFields?: Array<V1ManagedFieldsEntry>
  /**
   * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/identifiers#names +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  name?: string
  /**
   * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/namespaces +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  namespace?: string
  /**
   * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller. +optional +patchMergeKey=uid +patchStrategy=merge
   * @type {Array<V1OwnerReference>}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  ownerReferences?: Array<V1OwnerReference>
  /**
   * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  resourceVersion?: string
  /**
   * SelfLink is a URL representing this object. Populated by the system. Read-only.  DEPRECATED Kubernetes will stop propagating this field in 1.20 release and the field is planned to be removed in 1.21 release. +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  selfLink?: string
  /**
   *
   * @type {V1PersistentVolumeClaimSpec}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  spec?: V1PersistentVolumeClaimSpec
  /**
   * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: http://kubernetes.io/docs/user-guide/identifiers#uids +optional
   * @type {string}
   * @memberof V1PersistentVolumeClaimTemplate
   */
  uid?: string
}
/**
 *
 * @export
 * @interface V1PersistentVolumeClaimVolumeSource
 */
export interface V1PersistentVolumeClaimVolumeSource {
  /**
   * ClaimName is the name of a PersistentVolumeClaim in the same namespace as the pod using this volume. More info: https://kubernetes.io/docs/concepts/storage/persistent-volumes#persistentvolumeclaims
   * @type {string}
   * @memberof V1PersistentVolumeClaimVolumeSource
   */
  claimName?: string
  /**
   * Will force the ReadOnly setting in VolumeMounts. Default false. +optional
   * @type {boolean}
   * @memberof V1PersistentVolumeClaimVolumeSource
   */
  readOnly?: boolean
}
/**
 *
 * @export
 * @interface V1PhotonPersistentDiskVolumeSource
 */
export interface V1PhotonPersistentDiskVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified.
   * @type {string}
   * @memberof V1PhotonPersistentDiskVolumeSource
   */
  fsType?: string
  /**
   * ID that identifies Photon Controller persistent disk
   * @type {string}
   * @memberof V1PhotonPersistentDiskVolumeSource
   */
  pdID?: string
}
/**
 *
 * @export
 * @interface V1PortworxVolumeSource
 */
export interface V1PortworxVolumeSource {
  /**
   * FSType represents the filesystem type to mount Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\". Implicitly inferred to be \"ext4\" if unspecified.
   * @type {string}
   * @memberof V1PortworxVolumeSource
   */
  fsType?: string
  /**
   * Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1PortworxVolumeSource
   */
  readOnly?: boolean
  /**
   * VolumeID uniquely identifies a Portworx volume
   * @type {string}
   * @memberof V1PortworxVolumeSource
   */
  volumeID?: string
}
/**
 *
 * @export
 * @interface V1Probe
 */
export interface V1Probe {
  /**
   *
   * @type {V1ExecAction}
   * @memberof V1Probe
   */
  exec?: V1ExecAction
  /**
   * Minimum consecutive failures for the probe to be considered failed after having succeeded. Defaults to 3. Minimum value is 1. +optional
   * @type {number}
   * @memberof V1Probe
   */
  failureThreshold?: number
  /**
   *
   * @type {V1GRPCAction}
   * @memberof V1Probe
   */
  grpc?: V1GRPCAction
  /**
   *
   * @type {V1HTTPGetAction}
   * @memberof V1Probe
   */
  httpGet?: V1HTTPGetAction
  /**
   * Number of seconds after the container has started before liveness probes are initiated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes +optional
   * @type {number}
   * @memberof V1Probe
   */
  initialDelaySeconds?: number
  /**
   * How often (in seconds) to perform the probe. Default to 10 seconds. Minimum value is 1. +optional
   * @type {number}
   * @memberof V1Probe
   */
  periodSeconds?: number
  /**
   * Minimum consecutive successes for the probe to be considered successful after having failed. Defaults to 1. Must be 1 for liveness and startup. Minimum value is 1. +optional
   * @type {number}
   * @memberof V1Probe
   */
  successThreshold?: number
  /**
   *
   * @type {V1TCPSocketAction}
   * @memberof V1Probe
   */
  tcpSocket?: V1TCPSocketAction
  /**
   * Optional duration in seconds the pod needs to terminate gracefully upon probe failure. The grace period is the duration in seconds after the processes running in the pod are sent a termination signal and the time when the processes are forcibly halted with a kill signal. Set this value longer than the expected cleanup time for your process. If this value is nil, the pod\'s terminationGracePeriodSeconds will be used. Otherwise, this value overrides the value provided by the pod spec. Value must be non-negative integer. The value zero indicates stop immediately via the kill signal (no opportunity to shut down). This is a beta field and requires enabling ProbeTerminationGracePeriod feature gate. Minimum value is 1. spec.terminationGracePeriodSeconds is used if unset. +optional
   * @type {number}
   * @memberof V1Probe
   */
  terminationGracePeriodSeconds?: number
  /**
   * Number of seconds after which the probe times out. Defaults to 1 second. Minimum value is 1. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes +optional
   * @type {number}
   * @memberof V1Probe
   */
  timeoutSeconds?: number
}
/**
 *
 * @export
 * @interface V1ProjectedVolumeSource
 */
export interface V1ProjectedVolumeSource {
  /**
   * Mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1ProjectedVolumeSource
   */
  defaultMode?: number
  /**
   * list of volume projections +optional
   * @type {Array<V1VolumeProjection>}
   * @memberof V1ProjectedVolumeSource
   */
  sources?: Array<V1VolumeProjection>
}
/**
 *
 * @export
 * @interface V1QuobyteVolumeSource
 */
export interface V1QuobyteVolumeSource {
  /**
   * Group to map volume access to Default is no group +optional
   * @type {string}
   * @memberof V1QuobyteVolumeSource
   */
  group?: string
  /**
   * ReadOnly here will force the Quobyte volume to be mounted with read-only permissions. Defaults to false. +optional
   * @type {boolean}
   * @memberof V1QuobyteVolumeSource
   */
  readOnly?: boolean
  /**
   * Registry represents a single or multiple Quobyte Registry services specified as a string as host:port pair (multiple entries are separated with commas) which acts as the central registry for volumes
   * @type {string}
   * @memberof V1QuobyteVolumeSource
   */
  registry?: string
  /**
   * Tenant owning the given Quobyte volume in the Backend Used with dynamically provisioned Quobyte volumes, value is set by the plugin +optional
   * @type {string}
   * @memberof V1QuobyteVolumeSource
   */
  tenant?: string
  /**
   * User to map volume access to Defaults to serivceaccount user +optional
   * @type {string}
   * @memberof V1QuobyteVolumeSource
   */
  user?: string
  /**
   * Volume is a string that references an already created Quobyte volume by name.
   * @type {string}
   * @memberof V1QuobyteVolumeSource
   */
  volume?: string
}
/**
 *
 * @export
 * @interface V1RBDVolumeSource
 */
export interface V1RBDVolumeSource {
  /**
   * Filesystem type of the volume that you want to mount. Tip: Ensure that the filesystem type is supported by the host operating system. Examples: \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. More info: https://kubernetes.io/docs/concepts/storage/volumes#rbd TODO: how do we prevent errors in the filesystem from compromising the machine +optional
   * @type {string}
   * @memberof V1RBDVolumeSource
   */
  fsType?: string
  /**
   * The rados image name. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
   * @type {string}
   * @memberof V1RBDVolumeSource
   */
  image?: string
  /**
   * Keyring is the path to key ring for RBDUser. Default is /etc/ceph/keyring. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it +optional
   * @type {string}
   * @memberof V1RBDVolumeSource
   */
  keyring?: string
  /**
   * A collection of Ceph monitors. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it
   * @type {Array<string>}
   * @memberof V1RBDVolumeSource
   */
  monitors?: Array<string>
  /**
   * The rados pool name. Default is rbd. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it +optional
   * @type {string}
   * @memberof V1RBDVolumeSource
   */
  pool?: string
  /**
   * ReadOnly here will force the ReadOnly setting in VolumeMounts. Defaults to false. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it +optional
   * @type {boolean}
   * @memberof V1RBDVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1RBDVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * The rados user name. Default is admin. More info: https://examples.k8s.io/volumes/rbd/README.md#how-to-use-it +optional
   * @type {string}
   * @memberof V1RBDVolumeSource
   */
  user?: string
}
/**
 *
 * @export
 * @interface V1ResourceFieldSelector
 */
export interface V1ResourceFieldSelector {
  /**
   * Container name: required for volumes, optional for env vars +optional
   * @type {string}
   * @memberof V1ResourceFieldSelector
   */
  containerName?: string
  /**
   *
   * @type {ResourceQuantity}
   * @memberof V1ResourceFieldSelector
   */
  divisor?: ResourceQuantity
  /**
   * Required: resource to select
   * @type {string}
   * @memberof V1ResourceFieldSelector
   */
  resource?: string
}
/**
 *
 * @export
 * @interface V1ResourceRequirements
 */
export interface V1ResourceRequirements {
  /**
   *
   * @type {{ [key: string]: ResourceQuantity; }}
   * @memberof V1ResourceRequirements
   */
  limits?: { [key: string]: ResourceQuantity }
  /**
   *
   * @type {{ [key: string]: ResourceQuantity; }}
   * @memberof V1ResourceRequirements
   */
  requests?: { [key: string]: ResourceQuantity }
}
/**
 *
 * @export
 * @interface V1SELinuxOptions
 */
export interface V1SELinuxOptions {
  /**
   * Level is SELinux level label that applies to the container. +optional
   * @type {string}
   * @memberof V1SELinuxOptions
   */
  level?: string
  /**
   * Role is a SELinux role label that applies to the container. +optional
   * @type {string}
   * @memberof V1SELinuxOptions
   */
  role?: string
  /**
   * Type is a SELinux type label that applies to the container. +optional
   * @type {string}
   * @memberof V1SELinuxOptions
   */
  type?: string
  /**
   * User is a SELinux user label that applies to the container. +optional
   * @type {string}
   * @memberof V1SELinuxOptions
   */
  user?: string
}
/**
 *
 * @export
 * @interface V1ScaleIOVolumeSource
 */
export interface V1ScaleIOVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Default is \"xfs\". +optional
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  fsType?: string
  /**
   * The host address of the ScaleIO API Gateway.
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  gateway?: string
  /**
   * The name of the ScaleIO Protection Domain for the configured storage. +optional
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  protectionDomain?: string
  /**
   * Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1ScaleIOVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1ScaleIOVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * Flag to enable/disable SSL communication with Gateway, default false +optional
   * @type {boolean}
   * @memberof V1ScaleIOVolumeSource
   */
  sslEnabled?: boolean
  /**
   * Indicates whether the storage for a volume should be ThickProvisioned or ThinProvisioned. Default is ThinProvisioned. +optional
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  storageMode?: string
  /**
   * The ScaleIO Storage Pool associated with the protection domain. +optional
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  storagePool?: string
  /**
   * The name of the storage system as configured in ScaleIO.
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  system?: string
  /**
   * The name of a volume already created in the ScaleIO system that is associated with this volume source.
   * @type {string}
   * @memberof V1ScaleIOVolumeSource
   */
  volumeName?: string
}
/**
 *
 * @export
 * @interface V1SeccompProfile
 */
export interface V1SeccompProfile {
  /**
   * localhostProfile indicates a profile defined in a file on the node should be used. The profile must be preconfigured on the node to work. Must be a descending path, relative to the kubelet\'s configured seccomp profile location. Must only be set if type is \"Localhost\". +optional
   * @type {string}
   * @memberof V1SeccompProfile
   */
  localhostProfile?: string
  /**
   * type indicates which kind of seccomp profile will be applied. Valid options are:  Localhost - a profile defined in a file on the node should be used. RuntimeDefault - the container runtime default profile should be used. Unconfined - no profile should be applied. +unionDiscriminator
   * @type {string}
   * @memberof V1SeccompProfile
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1SecretEnvSource
 */
export interface V1SecretEnvSource {
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1SecretEnvSource
   */
  name?: string
  /**
   * Specify whether the Secret must be defined +optional
   * @type {boolean}
   * @memberof V1SecretEnvSource
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1SecretKeySelector
 */
export interface V1SecretKeySelector {
  /**
   * The key of the secret to select from.  Must be a valid secret key.
   * @type {string}
   * @memberof V1SecretKeySelector
   */
  key?: string
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1SecretKeySelector
   */
  name?: string
  /**
   * Specify whether the Secret or its key must be defined +optional
   * @type {boolean}
   * @memberof V1SecretKeySelector
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1SecretProjection
 */
export interface V1SecretProjection {
  /**
   * If unspecified, each key-value pair in the Data field of the referenced Secret will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the Secret, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the \'..\' path or start with \'..\'. +optional
   * @type {Array<V1KeyToPath>}
   * @memberof V1SecretProjection
   */
  items?: Array<V1KeyToPath>
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid? +optional
   * @type {string}
   * @memberof V1SecretProjection
   */
  name?: string
  /**
   * Specify whether the Secret or its key must be defined +optional
   * @type {boolean}
   * @memberof V1SecretProjection
   */
  optional?: boolean
}
/**
 *
 * @export
 * @interface V1SecretVolumeSource
 */
export interface V1SecretVolumeSource {
  /**
   * Optional: mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Defaults to 0644. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set. +optional
   * @type {number}
   * @memberof V1SecretVolumeSource
   */
  defaultMode?: number
  /**
   * If unspecified, each key-value pair in the Data field of the referenced Secret will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the Secret, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the \'..\' path or start with \'..\'. +optional
   * @type {Array<V1KeyToPath>}
   * @memberof V1SecretVolumeSource
   */
  items?: Array<V1KeyToPath>
  /**
   * Specify whether the Secret or its keys must be defined +optional
   * @type {boolean}
   * @memberof V1SecretVolumeSource
   */
  optional?: boolean
  /**
   * Name of the secret in the pod\'s namespace to use. More info: https://kubernetes.io/docs/concepts/storage/volumes#secret +optional
   * @type {string}
   * @memberof V1SecretVolumeSource
   */
  secretName?: string
}
/**
 *
 * @export
 * @interface V1SecurityContext
 */
export interface V1SecurityContext {
  /**
   * AllowPrivilegeEscalation controls whether a process can gain more privileges than its parent process. This bool directly controls if the no_new_privs flag will be set on the container process. AllowPrivilegeEscalation is true always when the container is: 1) run as Privileged 2) has CAP_SYS_ADMIN Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {boolean}
   * @memberof V1SecurityContext
   */
  allowPrivilegeEscalation?: boolean
  /**
   *
   * @type {V1Capabilities}
   * @memberof V1SecurityContext
   */
  capabilities?: V1Capabilities
  /**
   * Run container in privileged mode. Processes in privileged containers are essentially equivalent to root on the host. Defaults to false. Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {boolean}
   * @memberof V1SecurityContext
   */
  privileged?: boolean
  /**
   * procMount denotes the type of proc mount to use for the containers. The default is DefaultProcMount which uses the container runtime defaults for readonly paths and masked paths. This requires the ProcMountType feature flag to be enabled. Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {string}
   * @memberof V1SecurityContext
   */
  procMount?: string
  /**
   * Whether this container has a read-only root filesystem. Default is false. Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {boolean}
   * @memberof V1SecurityContext
   */
  readOnlyRootFilesystem?: boolean
  /**
   * The GID to run the entrypoint of the container process. Uses runtime default if unset. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {number}
   * @memberof V1SecurityContext
   */
  runAsGroup?: number
  /**
   * Indicates that the container must run as a non-root user. If true, the Kubelet will validate the image at runtime to ensure that it does not run as UID 0 (root) and fail to start the container if it does. If unset or false, no such validation will be performed. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. +optional
   * @type {boolean}
   * @memberof V1SecurityContext
   */
  runAsNonRoot?: boolean
  /**
   * The UID to run the entrypoint of the container process. Defaults to user specified in image metadata if unspecified. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is windows. +optional
   * @type {number}
   * @memberof V1SecurityContext
   */
  runAsUser?: number
  /**
   *
   * @type {V1SELinuxOptions}
   * @memberof V1SecurityContext
   */
  seLinuxOptions?: V1SELinuxOptions
  /**
   *
   * @type {V1SeccompProfile}
   * @memberof V1SecurityContext
   */
  seccompProfile?: V1SeccompProfile
  /**
   *
   * @type {V1WindowsSecurityContextOptions}
   * @memberof V1SecurityContext
   */
  windowsOptions?: V1WindowsSecurityContextOptions
}
/**
 *
 * @export
 * @interface V1ServiceAccountTokenProjection
 */
export interface V1ServiceAccountTokenProjection {
  /**
   * Audience is the intended audience of the token. A recipient of a token must identify itself with an identifier specified in the audience of the token, and otherwise should reject the token. The audience defaults to the identifier of the apiserver. +optional
   * @type {string}
   * @memberof V1ServiceAccountTokenProjection
   */
  audience?: string
  /**
   * ExpirationSeconds is the requested duration of validity of the service account token. As the token approaches expiration, the kubelet volume plugin will proactively rotate the service account token. The kubelet will start trying to rotate the token if the token is older than 80 percent of its time to live or if the token is older than 24 hours.Defaults to 1 hour and must be at least 10 minutes. +optional
   * @type {number}
   * @memberof V1ServiceAccountTokenProjection
   */
  expirationSeconds?: number
  /**
   * Path is the path relative to the mount point of the file to project the token into.
   * @type {string}
   * @memberof V1ServiceAccountTokenProjection
   */
  path?: string
}
/**
 *
 * @export
 * @interface V1StorageOSVolumeSource
 */
export interface V1StorageOSVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. +optional
   * @type {string}
   * @memberof V1StorageOSVolumeSource
   */
  fsType?: string
  /**
   * Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. +optional
   * @type {boolean}
   * @memberof V1StorageOSVolumeSource
   */
  readOnly?: boolean
  /**
   *
   * @type {V1LocalObjectReference}
   * @memberof V1StorageOSVolumeSource
   */
  secretRef?: V1LocalObjectReference
  /**
   * VolumeName is the human-readable name of the StorageOS volume.  Volume names are only unique within a namespace.
   * @type {string}
   * @memberof V1StorageOSVolumeSource
   */
  volumeName?: string
  /**
   * VolumeNamespace specifies the scope of the volume within StorageOS.  If no namespace is specified then the Pod\'s namespace will be used.  This allows the Kubernetes name scoping to be mirrored within StorageOS for tighter integration. Set VolumeName to any name to override the default behaviour. Set to \"default\" if you are not using namespaces within StorageOS. Namespaces that do not pre-exist within StorageOS will be created. +optional
   * @type {string}
   * @memberof V1StorageOSVolumeSource
   */
  volumeNamespace?: string
}
/**
 *
 * @export
 * @interface V1TCPSocketAction
 */
export interface V1TCPSocketAction {
  /**
   * Optional: Host name to connect to, defaults to the pod IP. +optional
   * @type {string}
   * @memberof V1TCPSocketAction
   */
  host?: string
  /**
   *
   * @type {IntstrIntOrString}
   * @memberof V1TCPSocketAction
   */
  port?: IntstrIntOrString
}
/**
 *
 * @export
 * @interface V1TypedLocalObjectReference
 */
export interface V1TypedLocalObjectReference {
  /**
   * APIGroup is the group for the resource being referenced. If APIGroup is not specified, the specified Kind must be in the core API group. For any other third-party types, APIGroup is required. +optional
   * @type {string}
   * @memberof V1TypedLocalObjectReference
   */
  apiGroup?: string
  /**
   * Kind is the type of resource being referenced
   * @type {string}
   * @memberof V1TypedLocalObjectReference
   */
  kind?: string
  /**
   * Name is the name of resource being referenced
   * @type {string}
   * @memberof V1TypedLocalObjectReference
   */
  name?: string
}
/**
 *
 * @export
 * @interface V1Volume
 */
export interface V1Volume {
  /**
   *
   * @type {V1AWSElasticBlockStoreVolumeSource}
   * @memberof V1Volume
   */
  awsElasticBlockStore?: V1AWSElasticBlockStoreVolumeSource
  /**
   *
   * @type {V1AzureDiskVolumeSource}
   * @memberof V1Volume
   */
  azureDisk?: V1AzureDiskVolumeSource
  /**
   *
   * @type {V1AzureFileVolumeSource}
   * @memberof V1Volume
   */
  azureFile?: V1AzureFileVolumeSource
  /**
   *
   * @type {V1CephFSVolumeSource}
   * @memberof V1Volume
   */
  cephfs?: V1CephFSVolumeSource
  /**
   *
   * @type {V1CinderVolumeSource}
   * @memberof V1Volume
   */
  cinder?: V1CinderVolumeSource
  /**
   *
   * @type {V1ConfigMapVolumeSource}
   * @memberof V1Volume
   */
  configMap?: V1ConfigMapVolumeSource
  /**
   *
   * @type {V1CSIVolumeSource}
   * @memberof V1Volume
   */
  csi?: V1CSIVolumeSource
  /**
   *
   * @type {V1DownwardAPIVolumeSource}
   * @memberof V1Volume
   */
  downwardAPI?: V1DownwardAPIVolumeSource
  /**
   *
   * @type {V1EmptyDirVolumeSource}
   * @memberof V1Volume
   */
  emptyDir?: V1EmptyDirVolumeSource
  /**
   *
   * @type {V1EphemeralVolumeSource}
   * @memberof V1Volume
   */
  ephemeral?: V1EphemeralVolumeSource
  /**
   *
   * @type {V1FCVolumeSource}
   * @memberof V1Volume
   */
  fc?: V1FCVolumeSource
  /**
   *
   * @type {V1FlexVolumeSource}
   * @memberof V1Volume
   */
  flexVolume?: V1FlexVolumeSource
  /**
   *
   * @type {V1FlockerVolumeSource}
   * @memberof V1Volume
   */
  flocker?: V1FlockerVolumeSource
  /**
   *
   * @type {V1GCEPersistentDiskVolumeSource}
   * @memberof V1Volume
   */
  gcePersistentDisk?: V1GCEPersistentDiskVolumeSource
  /**
   *
   * @type {V1GitRepoVolumeSource}
   * @memberof V1Volume
   */
  gitRepo?: V1GitRepoVolumeSource
  /**
   *
   * @type {V1GlusterfsVolumeSource}
   * @memberof V1Volume
   */
  glusterfs?: V1GlusterfsVolumeSource
  /**
   *
   * @type {V1HostPathVolumeSource}
   * @memberof V1Volume
   */
  hostPath?: V1HostPathVolumeSource
  /**
   *
   * @type {V1ISCSIVolumeSource}
   * @memberof V1Volume
   */
  iscsi?: V1ISCSIVolumeSource
  /**
   * Volume\'s name. Must be a DNS_LABEL and unique within the pod. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
   * @type {string}
   * @memberof V1Volume
   */
  name?: string
  /**
   *
   * @type {V1NFSVolumeSource}
   * @memberof V1Volume
   */
  nfs?: V1NFSVolumeSource
  /**
   *
   * @type {V1PersistentVolumeClaimVolumeSource}
   * @memberof V1Volume
   */
  persistentVolumeClaim?: V1PersistentVolumeClaimVolumeSource
  /**
   *
   * @type {V1PhotonPersistentDiskVolumeSource}
   * @memberof V1Volume
   */
  photonPersistentDisk?: V1PhotonPersistentDiskVolumeSource
  /**
   *
   * @type {V1PortworxVolumeSource}
   * @memberof V1Volume
   */
  portworxVolume?: V1PortworxVolumeSource
  /**
   *
   * @type {V1ProjectedVolumeSource}
   * @memberof V1Volume
   */
  projected?: V1ProjectedVolumeSource
  /**
   *
   * @type {V1QuobyteVolumeSource}
   * @memberof V1Volume
   */
  quobyte?: V1QuobyteVolumeSource
  /**
   *
   * @type {V1RBDVolumeSource}
   * @memberof V1Volume
   */
  rbd?: V1RBDVolumeSource
  /**
   *
   * @type {V1ScaleIOVolumeSource}
   * @memberof V1Volume
   */
  scaleIO?: V1ScaleIOVolumeSource
  /**
   *
   * @type {V1SecretVolumeSource}
   * @memberof V1Volume
   */
  secret?: V1SecretVolumeSource
  /**
   *
   * @type {V1StorageOSVolumeSource}
   * @memberof V1Volume
   */
  storageos?: V1StorageOSVolumeSource
  /**
   *
   * @type {V1VsphereVirtualDiskVolumeSource}
   * @memberof V1Volume
   */
  vsphereVolume?: V1VsphereVirtualDiskVolumeSource
}
/**
 *
 * @export
 * @interface V1VolumeDevice
 */
export interface V1VolumeDevice {
  /**
   * devicePath is the path inside of the container that the device will be mapped to.
   * @type {string}
   * @memberof V1VolumeDevice
   */
  devicePath?: string
  /**
   * name must match the name of a persistentVolumeClaim in the pod
   * @type {string}
   * @memberof V1VolumeDevice
   */
  name?: string
}
/**
 *
 * @export
 * @interface V1VolumeMount
 */
export interface V1VolumeMount {
  /**
   * Path within the container at which the volume should be mounted.  Must not contain \':\'.
   * @type {string}
   * @memberof V1VolumeMount
   */
  mountPath?: string
  /**
   * mountPropagation determines how mounts are propagated from the host to container and the other way around. When not set, MountPropagationNone is used. This field is beta in 1.10. +optional
   * @type {string}
   * @memberof V1VolumeMount
   */
  mountPropagation?: string
  /**
   * This must match the Name of a Volume.
   * @type {string}
   * @memberof V1VolumeMount
   */
  name?: string
  /**
   * Mounted read-only if true, read-write otherwise (false or unspecified). Defaults to false. +optional
   * @type {boolean}
   * @memberof V1VolumeMount
   */
  readOnly?: boolean
  /**
   * Path within the volume from which the container\'s volume should be mounted. Defaults to \"\" (volume\'s root). +optional
   * @type {string}
   * @memberof V1VolumeMount
   */
  subPath?: string
  /**
   * Expanded path within the volume from which the container\'s volume should be mounted. Behaves similarly to SubPath but environment variable references $(VAR_NAME) are expanded using the container\'s environment. Defaults to \"\" (volume\'s root). SubPathExpr and SubPath are mutually exclusive. +optional
   * @type {string}
   * @memberof V1VolumeMount
   */
  subPathExpr?: string
}
/**
 *
 * @export
 * @interface V1VolumeProjection
 */
export interface V1VolumeProjection {
  /**
   *
   * @type {V1ConfigMapProjection}
   * @memberof V1VolumeProjection
   */
  configMap?: V1ConfigMapProjection
  /**
   *
   * @type {V1DownwardAPIProjection}
   * @memberof V1VolumeProjection
   */
  downwardAPI?: V1DownwardAPIProjection
  /**
   *
   * @type {V1SecretProjection}
   * @memberof V1VolumeProjection
   */
  secret?: V1SecretProjection
  /**
   *
   * @type {V1ServiceAccountTokenProjection}
   * @memberof V1VolumeProjection
   */
  serviceAccountToken?: V1ServiceAccountTokenProjection
}
/**
 *
 * @export
 * @interface V1VsphereVirtualDiskVolumeSource
 */
export interface V1VsphereVirtualDiskVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified. +optional
   * @type {string}
   * @memberof V1VsphereVirtualDiskVolumeSource
   */
  fsType?: string
  /**
   * Storage Policy Based Management (SPBM) profile ID associated with the StoragePolicyName. +optional
   * @type {string}
   * @memberof V1VsphereVirtualDiskVolumeSource
   */
  storagePolicyID?: string
  /**
   * Storage Policy Based Management (SPBM) profile name. +optional
   * @type {string}
   * @memberof V1VsphereVirtualDiskVolumeSource
   */
  storagePolicyName?: string
  /**
   * Path that identifies vSphere volume vmdk
   * @type {string}
   * @memberof V1VsphereVirtualDiskVolumeSource
   */
  volumePath?: string
}
/**
 *
 * @export
 * @interface V1WindowsSecurityContextOptions
 */
export interface V1WindowsSecurityContextOptions {
  /**
   * GMSACredentialSpec is where the GMSA admission webhook (https://github.com/kubernetes-sigs/windows-gmsa) inlines the contents of the GMSA credential spec named by the GMSACredentialSpecName field. +optional
   * @type {string}
   * @memberof V1WindowsSecurityContextOptions
   */
  gmsaCredentialSpec?: string
  /**
   * GMSACredentialSpecName is the name of the GMSA credential spec to use. +optional
   * @type {string}
   * @memberof V1WindowsSecurityContextOptions
   */
  gmsaCredentialSpecName?: string
  /**
   * HostProcess determines if a container should be run as a \'Host Process\' container. This field is alpha-level and will only be honored by components that enable the WindowsHostProcessContainers feature flag. Setting this field without the feature flag will result in errors when validating the Pod. All of a Pod\'s containers must have the same effective HostProcess value (it is not allowed to have a mix of HostProcess containers and non-HostProcess containers).  In addition, if HostProcess is true then HostNetwork must also be set to true. +optional
   * @type {boolean}
   * @memberof V1WindowsSecurityContextOptions
   */
  hostProcess?: boolean
  /**
   * The UserName in Windows to run the entrypoint of the container process. Defaults to the user specified in image metadata if unspecified. May also be set in PodSecurityContext. If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. +optional
   * @type {string}
   * @memberof V1WindowsSecurityContextOptions
   */
  runAsUserName?: string
}
/**
 *
 * @export
 * @interface V1alpha1AWSChaosSpec
 */
export interface V1alpha1AWSChaosSpec {
  /**
   * Action defines the specific aws chaos action. Supported action: ec2-stop / ec2-restart / detach-volume Default action: ec2-stop +kubebuilder:validation:Enum=ec2-stop;ec2-restart;detach-volume
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  action?: string
  /**
   * AWSRegion defines the region of aws.
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  awsRegion?: string
  /**
   * DeviceName indicates the name of the device. Needed in detach-volume. +ui:form:when=action==\'detach-volume\' +optional
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  deviceName?: string
  /**
   * Duration represents the duration of the chaos action. +optional
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  duration?: string
  /**
   * Ec2Instance indicates the ID of the ec2 instance.
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  ec2Instance?: string
  /**
   * Endpoint indicates the endpoint of the aws server. Just used it in test now. +ui:form:ignore +optional
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  endpoint?: string
  /**
   * SecretName defines the name of kubernetes secret. +optional
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  secretName?: string
  /**
   * EbsVolume indicates the ID of the EBS volume. Needed in detach-volume. +ui:form:when=action==\'detach-volume\' +optional
   * @type {string}
   * @memberof V1alpha1AWSChaosSpec
   */
  volumeID?: string
}
/**
 *
 * @export
 * @interface V1alpha1AttrOverrideSpec
 */
export interface V1alpha1AttrOverrideSpec {
  /**
   *
   * @type {V1alpha1Timespec}
   * @memberof V1alpha1AttrOverrideSpec
   */
  atime?: V1alpha1Timespec
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  blocks?: number
  /**
   *
   * @type {V1alpha1Timespec}
   * @memberof V1alpha1AttrOverrideSpec
   */
  ctime?: V1alpha1Timespec
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  gid?: number
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  ino?: number
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1AttrOverrideSpec
   */
  kind?: string
  /**
   *
   * @type {V1alpha1Timespec}
   * @memberof V1alpha1AttrOverrideSpec
   */
  mtime?: V1alpha1Timespec
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  nlink?: number
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  perm?: number
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  rdev?: number
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  size?: number
  /**
   * +optional
   * @type {number}
   * @memberof V1alpha1AttrOverrideSpec
   */
  uid?: number
}
/**
 *
 * @export
 * @interface V1alpha1AzureChaosSpec
 */
export interface V1alpha1AzureChaosSpec {
  /**
   * Action defines the specific azure chaos action. Supported action: vm-stop / vm-restart / disk-detach Default action: vm-stop +kubebuilder:validation:Enum=vm-stop;vm-restart;disk-detach
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  action?: string
  /**
   * DiskName indicates the name of the disk. Needed in disk-detach. +optional
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  diskName?: string
  /**
   * Duration represents the duration of the chaos action. +optional
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  duration?: string
  /**
   * LUN indicates the Logical Unit Number of the data disk. Needed in disk-detach. +optional
   * @type {number}
   * @memberof V1alpha1AzureChaosSpec
   */
  lun?: number
  /**
   * ResourceGroupName defines the name of ResourceGroup
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  resourceGroupName?: string
  /**
   * SecretName defines the name of kubernetes secret. It is used for Azure credentials. +optional
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  secretName?: string
  /**
   * SubscriptionID defines the id of Azure subscription.
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  subscriptionID?: string
  /**
   * VMName defines the name of Virtual Machine
   * @type {string}
   * @memberof V1alpha1AzureChaosSpec
   */
  vmName?: string
}
/**
 *
 * @export
 * @interface V1alpha1BandwidthSpec
 */
export interface V1alpha1BandwidthSpec {
  /**
   * Buffer is the maximum amount of bytes that tokens can be available for instantaneously. +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1BandwidthSpec
   */
  buffer?: number
  /**
   * Limit is the number of bytes that can be queued waiting for tokens to become available. +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1BandwidthSpec
   */
  limit?: number
  /**
   * Minburst specifies the size of the peakrate bucket. For perfect accuracy, should be set to the MTU of the interface.  If a peakrate is needed, but some burstiness is acceptable, this size can be raised. A 3000 byte minburst allows around 3mbit/s of peakrate, given 1000 byte packets. +optional +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1BandwidthSpec
   */
  minburst?: number
  /**
   * Peakrate is the maximum depletion rate of the bucket. The peakrate does not need to be set, it is only necessary if perfect millisecond timescale shaping is required. +optional +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1BandwidthSpec
   */
  peakrate?: number
  /**
   * Rate is the speed knob. Allows bps, kbps, mbps, gbps, tbps unit. bps means bytes per second.
   * @type {string}
   * @memberof V1alpha1BandwidthSpec
   */
  rate?: string
}
/**
 *
 * @export
 * @interface V1alpha1BlockChaosSpec
 */
export interface V1alpha1BlockChaosSpec {
  /**
   * Action defines the specific block chaos action. Supported action: delay +kubebuilder:validation:Enum=delay
   * @type {string}
   * @memberof V1alpha1BlockChaosSpec
   */
  action?: string
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1BlockChaosSpec
   */
  containerNames?: Array<string>
  /**
   *
   * @type {V1alpha1BlockDelaySpec}
   * @memberof V1alpha1BlockChaosSpec
   */
  delay?: V1alpha1BlockDelaySpec
  /**
   * Duration represents the duration of the chaos action. +optional
   * @type {string}
   * @memberof V1alpha1BlockChaosSpec
   */
  duration?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1BlockChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1BlockChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1BlockChaosSpec
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1BlockChaosSpec
   */
  volumeName?: string
}
/**
 *
 * @export
 * @interface V1alpha1BlockDelaySpec
 */
export interface V1alpha1BlockDelaySpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1BlockDelaySpec
   */
  correlation?: string
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1BlockDelaySpec
   */
  jitter?: string
  /**
   * Latency defines the latency of every io request.
   * @type {string}
   * @memberof V1alpha1BlockDelaySpec
   */
  latency?: string
}
/**
 *
 * @export
 * @interface V1alpha1CPUStressor
 */
export interface V1alpha1CPUStressor {
  /**
   * Load specifies P percent loading per CPU worker. 0 is effectively a sleep (no load) and 100 is full loading. +kubebuilder:validation:Minimum=0 +kubebuilder:validation:Maximum=100 +optional
   * @type {number}
   * @memberof V1alpha1CPUStressor
   */
  load?: number
  /**
   * extend stress-ng options +optional
   * @type {Array<string>}
   * @memberof V1alpha1CPUStressor
   */
  options?: Array<string>
  /**
   * Workers specifies N workers to apply the stressor. Maximum 8192 workers can run by stress-ng +kubebuilder:validation:Maximum=8192
   * @type {number}
   * @memberof V1alpha1CPUStressor
   */
  workers?: number
}
/**
 *
 * @export
 * @interface V1alpha1ChaosOnlyScheduleSpec
 */
export interface V1alpha1ChaosOnlyScheduleSpec {
  /**
   *
   * @type {V1alpha1AWSChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  awsChaos?: V1alpha1AWSChaosSpec
  /**
   *
   * @type {V1alpha1AzureChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  azureChaos?: V1alpha1AzureChaosSpec
  /**
   *
   * @type {V1alpha1BlockChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  blockChaos?: V1alpha1BlockChaosSpec
  /**
   * +optional +kubebuilder:validation:Enum=Forbid;Allow
   * @type {string}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  concurrencyPolicy?: string
  /**
   *
   * @type {V1alpha1DNSChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  dnsChaos?: V1alpha1DNSChaosSpec
  /**
   *
   * @type {V1alpha1GCPChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  gcpChaos?: V1alpha1GCPChaosSpec
  /**
   * +optional +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  historyLimit?: number
  /**
   *
   * @type {V1alpha1HTTPChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  httpChaos?: V1alpha1HTTPChaosSpec
  /**
   *
   * @type {V1alpha1IOChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  ioChaos?: V1alpha1IOChaosSpec
  /**
   *
   * @type {V1alpha1JVMChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  jvmChaos?: V1alpha1JVMChaosSpec
  /**
   *
   * @type {V1alpha1KernelChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  kernelChaos?: V1alpha1KernelChaosSpec
  /**
   *
   * @type {V1alpha1NetworkChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  networkChaos?: V1alpha1NetworkChaosSpec
  /**
   *
   * @type {V1alpha1PhysicalMachineChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  physicalmachineChaos?: V1alpha1PhysicalMachineChaosSpec
  /**
   *
   * @type {V1alpha1PodChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  podChaos?: V1alpha1PodChaosSpec
  /**
   *
   * @type {string}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  schedule?: string
  /**
   * +optional +nullable +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  startingDeadlineSeconds?: number
  /**
   *
   * @type {V1alpha1StressChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  stressChaos?: V1alpha1StressChaosSpec
  /**
   *
   * @type {V1alpha1TimeChaosSpec}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  timeChaos?: V1alpha1TimeChaosSpec
  /**
   * TODO: use a custom type, as `TemplateType` contains other possible values
   * @type {string}
   * @memberof V1alpha1ChaosOnlyScheduleSpec
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1alpha1ClockSpec
 */
export interface V1alpha1ClockSpec {
  /**
   * the identifier of the particular clock on which to act. More clock description in linux kernel can be found in man page of clock_getres, clock_gettime, clock_settime. Muti clock ids should be split with \",\"
   * @type {string}
   * @memberof V1alpha1ClockSpec
   */
  'clock-ids-slice'?: string
  /**
   * the pid of target program.
   * @type {number}
   * @memberof V1alpha1ClockSpec
   */
  pid?: number
  /**
   * specifies the length of time offset.
   * @type {string}
   * @memberof V1alpha1ClockSpec
   */
  'time-offset'?: string
}
/**
 *
 * @export
 * @interface V1alpha1ConditionalBranch
 */
export interface V1alpha1ConditionalBranch {
  /**
   * Expression is the expression for this conditional branch, expected type of result is boolean. If expression is empty, this branch will always be selected/the template will be spawned. +optional
   * @type {string}
   * @memberof V1alpha1ConditionalBranch
   */
  expression?: string
  /**
   * Target is the name of other template, if expression is evaluated as true, this template will be spawned.
   * @type {string}
   * @memberof V1alpha1ConditionalBranch
   */
  target?: string
}
/**
 *
 * @export
 * @interface V1alpha1CorruptSpec
 */
export interface V1alpha1CorruptSpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1CorruptSpec
   */
  correlation?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1CorruptSpec
   */
  corrupt?: string
}
/**
 *
 * @export
 * @interface V1alpha1DNSChaosSpec
 */
export interface V1alpha1DNSChaosSpec {
  /**
   * Action defines the specific DNS chaos action. Supported action: error, random Default action: error +kubebuilder:validation:Enum=error;random
   * @type {string}
   * @memberof V1alpha1DNSChaosSpec
   */
  action?: string
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1DNSChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action
   * @type {string}
   * @memberof V1alpha1DNSChaosSpec
   */
  duration?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1DNSChaosSpec
   */
  mode?: string
  /**
   * Choose which domain names to take effect, support the placeholder ? and wildcard *, or the Specified domain name. Note:      1. The wildcard * must be at the end of the string. For example, chaos-*.org is invalid.      2. if the patterns is empty, will take effect on all the domain names. For example:   The value is [\"google.com\", \"github.*\", \"chaos-mes?.org\"],   will take effect on \"google.com\", \"github.com\" and \"chaos-mesh.org\" +optional
   * @type {Array<string>}
   * @memberof V1alpha1DNSChaosSpec
   */
  patterns?: Array<string>
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1DNSChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1DNSChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1DelaySpec
 */
export interface V1alpha1DelaySpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1DelaySpec
   */
  correlation?: string
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1DelaySpec
   */
  jitter?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1DelaySpec
   */
  latency?: string
  /**
   *
   * @type {V1alpha1ReorderSpec}
   * @memberof V1alpha1DelaySpec
   */
  reorder?: V1alpha1ReorderSpec
}
/**
 *
 * @export
 * @interface V1alpha1DiskFillSpec
 */
export interface V1alpha1DiskFillSpec {
  /**
   * fill disk by fallocate
   * @type {boolean}
   * @memberof V1alpha1DiskFillSpec
   */
  'fill-by-fallocate'?: boolean
  /**
   * specifies the location to fill data in. if path not provided, payload will read/write from/into a temp file, temp file will be deleted after writing
   * @type {string}
   * @memberof V1alpha1DiskFillSpec
   */
  path?: string
  /**
   * specifies how many units of data will write into the file path. support unit: c=1, w=2, b=512, kB=1000, K=1024, MB=1000*1000, M=1024*1024, GB=1000*1000*1000, G=1024*1024*1024 BYTES. example : 1M | 512kB
   * @type {string}
   * @memberof V1alpha1DiskFillSpec
   */
  size?: string
}
/**
 *
 * @export
 * @interface V1alpha1DiskPayloadSpec
 */
export interface V1alpha1DiskPayloadSpec {
  /**
   * specifies the location to fill data in. if path not provided, payload will read/write from/into a temp file, temp file will be deleted after writing
   * @type {string}
   * @memberof V1alpha1DiskPayloadSpec
   */
  path?: string
  /**
   * specifies the number of process work on writing, default 1, only 1-255 is valid value
   * @type {number}
   * @memberof V1alpha1DiskPayloadSpec
   */
  'payload-process-num'?: number
  /**
   * specifies how many units of data will write into the file path. support unit: c=1, w=2, b=512, kB=1000, K=1024, MB=1000*1000, M=1024*1024, GB=1000*1000*1000, G=1024*1024*1024 BYTES. example : 1M | 512kB
   * @type {string}
   * @memberof V1alpha1DiskPayloadSpec
   */
  size?: string
}
/**
 *
 * @export
 * @interface V1alpha1DuplicateSpec
 */
export interface V1alpha1DuplicateSpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1DuplicateSpec
   */
  correlation?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1DuplicateSpec
   */
  duplicate?: string
}
/**
 *
 * @export
 * @interface V1alpha1FailKernRequest
 */
export interface V1alpha1FailKernRequest {
  /**
   * Callchain indicate a special call chain, such as:     ext4_mount       -> mount_subtree          -> ...             -> should_failslab With an optional set of predicates and an optional set of parameters, which used with predicates. You can read call chan and predicate examples from https://github.com/chaos-mesh/bpfki/tree/develop/examples to learn more. If no special call chain, just keep Callchain empty, which means it will fail at any call chain with slab alloc (eg: kmalloc).
   * @type {Array<V1alpha1Frame>}
   * @memberof V1alpha1FailKernRequest
   */
  callchain?: Array<V1alpha1Frame>
  /**
   * FailType indicates what to fail, can be set to \'0\' / \'1\' / \'2\' If `0`, indicates slab to fail (should_failslab) If `1`, indicates alloc_page to fail (should_fail_alloc_page) If `2`, indicates bio to fail (should_fail_bio) You can read:   1. https://www.kernel.org/doc/html/latest/fault-injection/fault-injection.html   2. http://github.com/iovisor/bcc/blob/master/tools/inject_example.txt to learn more +kubebuilder:validation:Maximum=2 +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1FailKernRequest
   */
  failtype?: number
  /**
   * Headers indicates the appropriate kernel headers you need. Eg: \"linux/mmzone.h\", \"linux/blkdev.h\" and so on
   * @type {Array<string>}
   * @memberof V1alpha1FailKernRequest
   */
  headers?: Array<string>
  /**
   * Probability indicates the fails with probability. If you want 1%, please set this field with 1. +kubebuilder:validation:Minimum=0 +kubebuilder:validation:Maximum=100
   * @type {number}
   * @memberof V1alpha1FailKernRequest
   */
  probability?: number
  /**
   * Times indicates the max times of fails. +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1FailKernRequest
   */
  times?: number
}
/**
 *
 * @export
 * @interface V1alpha1FileAppendSpec
 */
export interface V1alpha1FileAppendSpec {
  /**
   * Count is the number of times to append the data.
   * @type {number}
   * @memberof V1alpha1FileAppendSpec
   */
  count?: number
  /**
   * Data is the data for append.
   * @type {string}
   * @memberof V1alpha1FileAppendSpec
   */
  data?: string
  /**
   * FileName is the name of the file to be created, modified, deleted, renamed, or appended.
   * @type {string}
   * @memberof V1alpha1FileAppendSpec
   */
  'file-name'?: string
}
/**
 *
 * @export
 * @interface V1alpha1FileCreateSpec
 */
export interface V1alpha1FileCreateSpec {
  /**
   * DirName is the directory name to create or delete.
   * @type {string}
   * @memberof V1alpha1FileCreateSpec
   */
  'dir-name'?: string
  /**
   * FileName is the name of the file to be created, modified, deleted, renamed, or appended.
   * @type {string}
   * @memberof V1alpha1FileCreateSpec
   */
  'file-name'?: string
}
/**
 *
 * @export
 * @interface V1alpha1FileDeleteSpec
 */
export interface V1alpha1FileDeleteSpec {
  /**
   * DirName is the directory name to create or delete.
   * @type {string}
   * @memberof V1alpha1FileDeleteSpec
   */
  'dir-name'?: string
  /**
   * FileName is the name of the file to be created, modified, deleted, renamed, or appended.
   * @type {string}
   * @memberof V1alpha1FileDeleteSpec
   */
  'file-name'?: string
}
/**
 *
 * @export
 * @interface V1alpha1FileModifyPrivilegeSpec
 */
export interface V1alpha1FileModifyPrivilegeSpec {
  /**
   * FileName is the name of the file to be created, modified, deleted, renamed, or appended.
   * @type {string}
   * @memberof V1alpha1FileModifyPrivilegeSpec
   */
  'file-name'?: string
  /**
   * Privilege is the file privilege to be set.
   * @type {number}
   * @memberof V1alpha1FileModifyPrivilegeSpec
   */
  privilege?: number
}
/**
 *
 * @export
 * @interface V1alpha1FileRenameSpec
 */
export interface V1alpha1FileRenameSpec {
  /**
   * DestFile is the name to be renamed.
   * @type {string}
   * @memberof V1alpha1FileRenameSpec
   */
  'dest-file'?: string
  /**
   * SourceFile is the name need to be renamed.
   * @type {string}
   * @memberof V1alpha1FileRenameSpec
   */
  'source-file'?: string
}
/**
 *
 * @export
 * @interface V1alpha1FileReplaceSpec
 */
export interface V1alpha1FileReplaceSpec {
  /**
   * DestStr is the destination string of the file.
   * @type {string}
   * @memberof V1alpha1FileReplaceSpec
   */
  'dest-string'?: string
  /**
   * FileName is the name of the file to be created, modified, deleted, renamed, or appended.
   * @type {string}
   * @memberof V1alpha1FileReplaceSpec
   */
  'file-name'?: string
  /**
   * Line is the line number of the file to be replaced.
   * @type {number}
   * @memberof V1alpha1FileReplaceSpec
   */
  line?: number
  /**
   * OriginStr is the origin string of the file.
   * @type {string}
   * @memberof V1alpha1FileReplaceSpec
   */
  'origin-string'?: string
}
/**
 *
 * @export
 * @interface V1alpha1Frame
 */
export interface V1alpha1Frame {
  /**
   * Funcname can be find from kernel source or `/proc/kallsyms`, such as `ext4_mount`
   * @type {string}
   * @memberof V1alpha1Frame
   */
  funcname?: string
  /**
   * Parameters is used with predicate, for example, if you want to inject slab error in `d_alloc_parallel(struct dentry *parent, const struct qstr *name)` with a special name `bananas`, you need to set it to `struct dentry *parent, const struct qstr *name` otherwise omit it.
   * @type {string}
   * @memberof V1alpha1Frame
   */
  parameters?: string
  /**
   * Predicate will access the arguments of this Frame, example with Parameters\'s, you can set it to `STRNCMP(name->name, \"bananas\", 8)` to make inject only with it, or omit it to inject for all d_alloc_parallel call chain.
   * @type {string}
   * @memberof V1alpha1Frame
   */
  predicate?: string
}
/**
 *
 * @export
 * @interface V1alpha1GCPChaosSpec
 */
export interface V1alpha1GCPChaosSpec {
  /**
   * Action defines the specific gcp chaos action. Supported action: node-stop / node-reset / disk-loss Default action: node-stop +kubebuilder:validation:Enum=node-stop;node-reset;disk-loss
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  action?: string
  /**
   * The device name of disks to detach. Needed in disk-loss. +ui:form:when=action==\'disk-loss\' +optional
   * @type {Array<string>}
   * @memberof V1alpha1GCPChaosSpec
   */
  deviceNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action. +optional
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  duration?: string
  /**
   * Instance defines the name of the instance
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  instance?: string
  /**
   * Project defines the ID of gcp project.
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  project?: string
  /**
   * SecretName defines the name of kubernetes secret. It is used for GCP credentials. +optional
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  secretName?: string
  /**
   * Zone defines the zone of gcp project.
   * @type {string}
   * @memberof V1alpha1GCPChaosSpec
   */
  zone?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPAbortSpec
 */
export interface V1alpha1HTTPAbortSpec {
  /**
   * Code is a rule to select target by http status code in response
   * @type {string}
   * @memberof V1alpha1HTTPAbortSpec
   */
  code?: string
  /**
   * HTTP method
   * @type {string}
   * @memberof V1alpha1HTTPAbortSpec
   */
  method?: string
  /**
   * Match path of Uri with wildcard matches
   * @type {string}
   * @memberof V1alpha1HTTPAbortSpec
   */
  path?: string
  /**
   * The TCP port that the target service listens on
   * @type {number}
   * @memberof V1alpha1HTTPAbortSpec
   */
  port?: number
  /**
   * Composed with one of the port of HTTP connection, we will only attack HTTP connection with port inside proxy_ports
   * @type {Array<number>}
   * @memberof V1alpha1HTTPAbortSpec
   */
  proxy_ports?: Array<number>
  /**
   * HTTP target: Request or Response
   * @type {string}
   * @memberof V1alpha1HTTPAbortSpec
   */
  target?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPChaosSpec
 */
export interface V1alpha1HTTPChaosSpec {
  /**
   * Abort is a rule to abort a http session. +optional
   * @type {boolean}
   * @memberof V1alpha1HTTPChaosSpec
   */
  abort?: boolean
  /**
   * Code is a rule to select target by http status code in response. +optional
   * @type {number}
   * @memberof V1alpha1HTTPChaosSpec
   */
  code?: number
  /**
   * Delay represents the delay of the target request/response. A duration string is a possibly unsigned sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\", \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +optional
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  delay?: string
  /**
   * Duration represents the duration of the chaos action. +optional
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  duration?: string
  /**
   * Method is a rule to select target by http method in request. +optional
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  method?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  mode?: string
  /**
   * Patch is a rule to patch some contents in target. +optional
   * @type {V1alpha1PodHttpChaosPatchActions}
   * @memberof V1alpha1HTTPChaosSpec
   */
  patch?: V1alpha1PodHttpChaosPatchActions
  /**
   * Path is a rule to select target by uri path in http request. +optional
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  path?: string
  /**
   * Port represents the target port to be proxy of.
   * @type {number}
   * @memberof V1alpha1HTTPChaosSpec
   */
  port?: number
  /**
   * Replace is a rule to replace some contents in target. +optional
   * @type {V1alpha1PodHttpChaosReplaceActions}
   * @memberof V1alpha1HTTPChaosSpec
   */
  replace?: V1alpha1PodHttpChaosReplaceActions
  /**
   * RequestHeaders is a rule to select target by http headers in request. The key-value pairs represent header name and header value pairs. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1HTTPChaosSpec
   */
  request_headers?: { [key: string]: string }
  /**
   * ResponseHeaders is a rule to select target by http headers in response. The key-value pairs represent header name and header value pairs. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1HTTPChaosSpec
   */
  response_headers?: { [key: string]: string }
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1HTTPChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Target is the object to be selected and injected. +kubebuilder:validation:Enum=Request;Response
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  target?: string
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1HTTPChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPConfigSpec
 */
export interface V1alpha1HTTPConfigSpec {
  /**
   * The config file path
   * @type {string}
   * @memberof V1alpha1HTTPConfigSpec
   */
  file_path?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPCriteria
 */
export interface V1alpha1HTTPCriteria {
  /**
   * StatusCode defines the expected http status code for the request. A statusCode string could be a single code (e.g. 200), or an inclusive range (e.g. 200-400, both `200` and `400` are included).
   * @type {string}
   * @memberof V1alpha1HTTPCriteria
   */
  statusCode?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPDelaySpec
 */
export interface V1alpha1HTTPDelaySpec {
  /**
   * Code is a rule to select target by http status code in response
   * @type {string}
   * @memberof V1alpha1HTTPDelaySpec
   */
  code?: string
  /**
   * Delay represents the delay of the target request/response
   * @type {string}
   * @memberof V1alpha1HTTPDelaySpec
   */
  delay?: string
  /**
   * HTTP method
   * @type {string}
   * @memberof V1alpha1HTTPDelaySpec
   */
  method?: string
  /**
   * Match path of Uri with wildcard matches
   * @type {string}
   * @memberof V1alpha1HTTPDelaySpec
   */
  path?: string
  /**
   * The TCP port that the target service listens on
   * @type {number}
   * @memberof V1alpha1HTTPDelaySpec
   */
  port?: number
  /**
   * Composed with one of the port of HTTP connection, we will only attack HTTP connection with port inside proxy_ports
   * @type {Array<number>}
   * @memberof V1alpha1HTTPDelaySpec
   */
  proxy_ports?: Array<number>
  /**
   * HTTP target: Request or Response
   * @type {string}
   * @memberof V1alpha1HTTPDelaySpec
   */
  target?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPRequestSpec
 */
export interface V1alpha1HTTPRequestSpec {
  /**
   * The number of requests to send
   * @type {number}
   * @memberof V1alpha1HTTPRequestSpec
   */
  count?: number
  /**
   * Enable connection pool
   * @type {boolean}
   * @memberof V1alpha1HTTPRequestSpec
   */
  'enable-conn-pool'?: boolean
  /**
   * Request to send\"
   * @type {string}
   * @memberof V1alpha1HTTPRequestSpec
   */
  url?: string
}
/**
 *
 * @export
 * @interface V1alpha1HTTPStatusCheck
 */
export interface V1alpha1HTTPStatusCheck {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1HTTPStatusCheck
   */
  body?: string
  /**
   *
   * @type {V1alpha1HTTPCriteria}
   * @memberof V1alpha1HTTPStatusCheck
   */
  criteria?: V1alpha1HTTPCriteria
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof V1alpha1HTTPStatusCheck
   */
  headers?: { [key: string]: Array<string> }
  /**
   * +optional +kubebuilder:validation:Enum=GET;POST +kubebuilder:default=GET
   * @type {string}
   * @memberof V1alpha1HTTPStatusCheck
   */
  method?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1HTTPStatusCheck
   */
  url?: string
}
/**
 *
 * @export
 * @interface V1alpha1IOChaosSpec
 */
export interface V1alpha1IOChaosSpec {
  /**
   * Action defines the specific pod chaos action. Supported action: latency / fault / attrOverride / mistake +kubebuilder:validation:Enum=latency;fault;attrOverride;mistake
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  action?: string
  /**
   * Attr defines the overrided attribution +ui:form:when=action==\'attrOverride\' +optional
   * @type {V1alpha1AttrOverrideSpec}
   * @memberof V1alpha1IOChaosSpec
   */
  attr?: V1alpha1AttrOverrideSpec
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1IOChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Delay defines the value of I/O chaos action delay. A delay string is a possibly signed sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +ui:form:when=action==\'latency\' +optional
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  delay?: string
  /**
   * Duration represents the duration of the chaos action. It is required when the action is `PodFailureAction`. A duration string is a possibly signed sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\", \"-1.5h\" or \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +optional
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  duration?: string
  /**
   * Errno defines the error code that returned by I/O action. refer to: https://www-numi.fnal.gov/offline_software/srt_public_context/WebDocs/Errors/unix_system_errors.html +ui:form:when=action==\'fault\' +optional
   * @type {number}
   * @memberof V1alpha1IOChaosSpec
   */
  errno?: number
  /**
   * Methods defines the I/O methods for injecting I/O chaos action. default: all I/O methods. +optional
   * @type {Array<string>}
   * @memberof V1alpha1IOChaosSpec
   */
  methods?: Array<string>
  /**
   * Mistake defines what types of incorrectness are injected to IO operations +ui:form:when=action==\'mistake\' +optional
   * @type {V1alpha1MistakeSpec}
   * @memberof V1alpha1IOChaosSpec
   */
  mistake?: V1alpha1MistakeSpec
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  mode?: string
  /**
   * Path defines the path of files for injecting I/O chaos action. +optional
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  path?: string
  /**
   * Percent defines the percentage of injection errors and provides a number from 0-100. default: 100. +optional +kubebuilder:default=100
   * @type {number}
   * @memberof V1alpha1IOChaosSpec
   */
  percent?: number
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1IOChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  value?: string
  /**
   * VolumePath represents the mount path of injected volume
   * @type {string}
   * @memberof V1alpha1IOChaosSpec
   */
  volumePath?: string
}
/**
 *
 * @export
 * @interface V1alpha1JVMChaosSpec
 */
export interface V1alpha1JVMChaosSpec {
  /**
   * Action defines the specific jvm chaos action. Supported action: latency;return;exception;stress;gc;ruleData +kubebuilder:validation:Enum=latency;return;exception;stress;gc;ruleData;mysql
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  action?: string
  /**
   * +optional Java class
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  class?: string
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1JVMChaosSpec
   */
  containerNames?: Array<string>
  /**
   * +optional the CPU core number needs to use, only set it when action is stress
   * @type {number}
   * @memberof V1alpha1JVMChaosSpec
   */
  cpuCount?: number
  /**
   * the match database default value is \"\", means match all database
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  database?: string
  /**
   * Duration represents the duration of the chaos action +optional
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  duration?: string
  /**
   * +optional the exception which needs to throw for action `exception` or the exception message needs to throw in action `mysql`
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  exception?: string
  /**
   * +optional the latency duration for action \'latency\', unit ms or the latency duration in action `mysql`
   * @type {number}
   * @memberof V1alpha1JVMChaosSpec
   */
  latency?: number
  /**
   * +optional the memory type needs to locate, only set it when action is stress, the value can be \'stack\' or \'heap\'
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  memType?: string
  /**
   * +optional the method in Java class
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  method?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  mode?: string
  /**
   * the version of mysql-connector-java, only support 5.X.X(set to \"5\") and 8.X.X(set to \"8\") now
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  mysqlConnectorVersion?: string
  /**
   * +optional byteman rule name, should be unique, and will generate one if not set
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  name?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMChaosSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMChaosSpec
   */
  port?: number
  /**
   * +optional the byteman rule\'s data for action \'ruleData\'
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  ruleData?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1JVMChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * the match sql type default value is \"\", means match all SQL type. The value can be \'select\', \'insert\', \'update\', \'delete\', \'replace\'.
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  sqlType?: string
  /**
   * the match table default value is \"\", means match all table
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  table?: string
  /**
   * +optional the return value for action \'return\'
   * @type {string}
   * @memberof V1alpha1JVMChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1JVMExceptionSpec
 */
export interface V1alpha1JVMExceptionSpec {
  /**
   * +optional Java class
   * @type {string}
   * @memberof V1alpha1JVMExceptionSpec
   */
  class?: string
  /**
   * the exception which needs to throw for action `exception`
   * @type {string}
   * @memberof V1alpha1JVMExceptionSpec
   */
  exception?: string
  /**
   * +optional the method in Java class
   * @type {string}
   * @memberof V1alpha1JVMExceptionSpec
   */
  method?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMExceptionSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMExceptionSpec
   */
  port?: number
}
/**
 *
 * @export
 * @interface V1alpha1JVMGCSpec
 */
export interface V1alpha1JVMGCSpec {
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMGCSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMGCSpec
   */
  port?: number
}
/**
 *
 * @export
 * @interface V1alpha1JVMLatencySpec
 */
export interface V1alpha1JVMLatencySpec {
  /**
   * +optional Java class
   * @type {string}
   * @memberof V1alpha1JVMLatencySpec
   */
  class?: string
  /**
   * the latency duration for action \'latency\', unit ms
   * @type {number}
   * @memberof V1alpha1JVMLatencySpec
   */
  latency?: number
  /**
   * +optional the method in Java class
   * @type {string}
   * @memberof V1alpha1JVMLatencySpec
   */
  method?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMLatencySpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMLatencySpec
   */
  port?: number
}
/**
 *
 * @export
 * @interface V1alpha1JVMReturnSpec
 */
export interface V1alpha1JVMReturnSpec {
  /**
   * +optional Java class
   * @type {string}
   * @memberof V1alpha1JVMReturnSpec
   */
  class?: string
  /**
   * +optional the method in Java class
   * @type {string}
   * @memberof V1alpha1JVMReturnSpec
   */
  method?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMReturnSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMReturnSpec
   */
  port?: number
  /**
   * the return value for action \'return\'
   * @type {string}
   * @memberof V1alpha1JVMReturnSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1JVMRuleDataSpec
 */
export interface V1alpha1JVMRuleDataSpec {
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMRuleDataSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMRuleDataSpec
   */
  port?: number
  /**
   * RuleData used to save the rule file\'s data, will use it when recover
   * @type {string}
   * @memberof V1alpha1JVMRuleDataSpec
   */
  'rule-data'?: string
}
/**
 *
 * @export
 * @interface V1alpha1JVMStressSpec
 */
export interface V1alpha1JVMStressSpec {
  /**
   * the CPU core number need to use, only set it when action is stress
   * @type {number}
   * @memberof V1alpha1JVMStressSpec
   */
  'cpu-count'?: number
  /**
   * the memory type need to locate, only set it when action is stress, the value can be \'stack\' or \'heap\'
   * @type {string}
   * @memberof V1alpha1JVMStressSpec
   */
  'mem-type'?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1JVMStressSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1JVMStressSpec
   */
  port?: number
}
/**
 *
 * @export
 * @interface V1alpha1KafkaFillSpec
 */
export interface V1alpha1KafkaFillSpec {
  /**
   * The host of kafka server
   * @type {string}
   * @memberof V1alpha1KafkaFillSpec
   */
  host?: string
  /**
   * The max bytes to fill
   * @type {number}
   * @memberof V1alpha1KafkaFillSpec
   */
  maxBytes?: number
  /**
   * The size of each message
   * @type {number}
   * @memberof V1alpha1KafkaFillSpec
   */
  messageSize?: number
  /**
   * The password of kafka client
   * @type {string}
   * @memberof V1alpha1KafkaFillSpec
   */
  password?: string
  /**
   * The port of kafka server
   * @type {number}
   * @memberof V1alpha1KafkaFillSpec
   */
  port?: number
  /**
   * The command to reload kafka config
   * @type {string}
   * @memberof V1alpha1KafkaFillSpec
   */
  reloadCommand?: string
  /**
   * The topic to attack
   * @type {string}
   * @memberof V1alpha1KafkaFillSpec
   */
  topic?: string
  /**
   * The username of kafka client
   * @type {string}
   * @memberof V1alpha1KafkaFillSpec
   */
  username?: string
}
/**
 *
 * @export
 * @interface V1alpha1KafkaFloodSpec
 */
export interface V1alpha1KafkaFloodSpec {
  /**
   * The host of kafka server
   * @type {string}
   * @memberof V1alpha1KafkaFloodSpec
   */
  host?: string
  /**
   * The size of each message
   * @type {number}
   * @memberof V1alpha1KafkaFloodSpec
   */
  messageSize?: number
  /**
   * The password of kafka client
   * @type {string}
   * @memberof V1alpha1KafkaFloodSpec
   */
  password?: string
  /**
   * The port of kafka server
   * @type {number}
   * @memberof V1alpha1KafkaFloodSpec
   */
  port?: number
  /**
   * The number of worker threads
   * @type {number}
   * @memberof V1alpha1KafkaFloodSpec
   */
  threads?: number
  /**
   * The topic to attack
   * @type {string}
   * @memberof V1alpha1KafkaFloodSpec
   */
  topic?: string
  /**
   * The username of kafka client
   * @type {string}
   * @memberof V1alpha1KafkaFloodSpec
   */
  username?: string
}
/**
 *
 * @export
 * @interface V1alpha1KafkaIOSpec
 */
export interface V1alpha1KafkaIOSpec {
  /**
   * The path of server config
   * @type {string}
   * @memberof V1alpha1KafkaIOSpec
   */
  configFile?: string
  /**
   * Make kafka cluster non-readable
   * @type {boolean}
   * @memberof V1alpha1KafkaIOSpec
   */
  nonReadable?: boolean
  /**
   * Make kafka cluster non-writable
   * @type {boolean}
   * @memberof V1alpha1KafkaIOSpec
   */
  nonWritable?: boolean
  /**
   * The topic to attack
   * @type {string}
   * @memberof V1alpha1KafkaIOSpec
   */
  topic?: string
}
/**
 *
 * @export
 * @interface V1alpha1KernelChaosSpec
 */
export interface V1alpha1KernelChaosSpec {
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1KernelChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action
   * @type {string}
   * @memberof V1alpha1KernelChaosSpec
   */
  duration?: string
  /**
   *
   * @type {V1alpha1FailKernRequest}
   * @memberof V1alpha1KernelChaosSpec
   */
  failKernRequest?: V1alpha1FailKernRequest
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1KernelChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1KernelChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1KernelChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1LossSpec
 */
export interface V1alpha1LossSpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1LossSpec
   */
  correlation?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1LossSpec
   */
  loss?: string
}
/**
 *
 * @export
 * @interface V1alpha1MemoryStressor
 */
export interface V1alpha1MemoryStressor {
  /**
   * OOMScoreAdj sets the oom_score_adj of the stress process. See `man 5 proc` to know more about this option. +kubebuilder:validation:Minimum=-1000 +kubebuilder:validation:Maximum=1000 +kubebuilder:default=0 +optional
   * @type {number}
   * @memberof V1alpha1MemoryStressor
   */
  oomScoreAdj?: number
  /**
   * extend stress-ng options +optional
   * @type {Array<string>}
   * @memberof V1alpha1MemoryStressor
   */
  options?: Array<string>
  /**
   * Size specifies N bytes consumed per vm worker, default is the total available memory. One can specify the size as % of total available memory or in units of B, KB/KiB, MB/MiB, GB/GiB, TB/TiB. +optional
   * @type {string}
   * @memberof V1alpha1MemoryStressor
   */
  size?: string
  /**
   * Workers specifies N workers to apply the stressor. Maximum 8192 workers can run by stress-ng +kubebuilder:validation:Maximum=8192
   * @type {number}
   * @memberof V1alpha1MemoryStressor
   */
  workers?: number
}
/**
 *
 * @export
 * @interface V1alpha1MistakeSpec
 */
export interface V1alpha1MistakeSpec {
  /**
   * Filling determines what is filled in the mistake data. +optional +kubebuilder:validation:Enum=zero;random
   * @type {string}
   * @memberof V1alpha1MistakeSpec
   */
  filling?: string
  /**
   * Max length of each wrong data segment in bytes +optional +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1MistakeSpec
   */
  maxLength?: number
  /**
   * There will be [1, MaxOccurrences] segments of wrong data. +optional +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1MistakeSpec
   */
  maxOccurrences?: number
}
/**
 *
 * @export
 * @interface V1alpha1NetworkBandwidthSpec
 */
export interface V1alpha1NetworkBandwidthSpec {
  /**
   * +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  buffer?: number
  /**
   *
   * @type {string}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  device?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  hostname?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  'ip-address'?: string
  /**
   * +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  minburst?: number
  /**
   *
   * @type {number}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  peakrate?: number
  /**
   *
   * @type {string}
   * @memberof V1alpha1NetworkBandwidthSpec
   */
  rate?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkChaosSpec
 */
export interface V1alpha1NetworkChaosSpec {
  /**
   * Action defines the specific network chaos action. Supported action: partition, netem, delay, loss, duplicate, corrupt Default action: delay +kubebuilder:validation:Enum=netem;delay;loss;duplicate;corrupt;partition;bandwidth
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  action?: string
  /**
   * Bandwidth represents the detail about bandwidth control action +ui:form:when=action==\'bandwidth\' +optional
   * @type {V1alpha1BandwidthSpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  bandwidth?: V1alpha1BandwidthSpec
  /**
   * Corrupt represents the detail about corrupt action +ui:form:when=action==\'corrupt\' +optional
   * @type {V1alpha1CorruptSpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  corrupt?: V1alpha1CorruptSpec
  /**
   * Delay represents the detail about delay action +ui:form:when=action==\'delay\' +optional
   * @type {V1alpha1DelaySpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  delay?: V1alpha1DelaySpec
  /**
   * Device represents the network device to be affected. +optional
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  device?: string
  /**
   * Direction represents the direction, this applies on netem and network partition action +optional +kubebuilder:validation:Enum=to;from;both +kubebuilder:default=to
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  direction?: string
  /**
   * DuplicateSpec represents the detail about loss action +ui:form:when=action==\'duplicate\' +optional
   * @type {V1alpha1DuplicateSpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  duplicate?: V1alpha1DuplicateSpec
  /**
   * Duration represents the duration of the chaos action
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  duration?: string
  /**
   * ExternalTargets represents network targets outside k8s +optional
   * @type {Array<string>}
   * @memberof V1alpha1NetworkChaosSpec
   */
  externalTargets?: Array<string>
  /**
   * Loss represents the detail about loss action +ui:form:when=action==\'loss\' +optional
   * @type {V1alpha1LossSpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  loss?: V1alpha1LossSpec
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1NetworkChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   *
   * @type {V1alpha1PodSelector}
   * @memberof V1alpha1NetworkChaosSpec
   */
  target?: V1alpha1PodSelector
  /**
   * TargetDevice represents the network device to be affected in target scope. +optional
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  targetDevice?: string
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1NetworkChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkCorruptSpec
 */
export interface V1alpha1NetworkCorruptSpec {
  /**
   * correlation is percentage (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  correlation?: string
  /**
   * the network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  device?: string
  /**
   * only impact egress traffic to these destination ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  'egress-port'?: string
  /**
   * only impact traffic to these hostnames
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  hostname?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  'ip-address'?: string
  /**
   * only impact traffic using this IP protocol, supported: tcp, udp, icmp, all
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  'ip-protocol'?: string
  /**
   * percentage of packets to corrupt (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  percent?: string
  /**
   * only impact egress traffic from these source ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkCorruptSpec
   */
  'source-port'?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkDNSSpec
 */
export interface V1alpha1NetworkDNSSpec {
  /**
   * map this host to specified IP
   * @type {string}
   * @memberof V1alpha1NetworkDNSSpec
   */
  'dns-domain-name'?: string
  /**
   * map specified host to this IP address
   * @type {string}
   * @memberof V1alpha1NetworkDNSSpec
   */
  'dns-ip'?: string
  /**
   * update the DNS server in /etc/resolv.conf with this value
   * @type {string}
   * @memberof V1alpha1NetworkDNSSpec
   */
  'dns-server'?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkDelaySpec
 */
export interface V1alpha1NetworkDelaySpec {
  /**
   * only the packet which match the tcp flag can be accepted, others will be dropped. only set when the IPProtocol is tcp, used for partition.
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  'accept-tcp-flags'?: string
  /**
   * correlation is percentage (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  correlation?: string
  /**
   * the network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  device?: string
  /**
   * only impact egress traffic to these destination ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  'egress-port'?: string
  /**
   * only impact traffic to these hostnames
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  hostname?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  'ip-address'?: string
  /**
   * only impact traffic using this IP protocol, supported: tcp, udp, icmp, all
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  'ip-protocol'?: string
  /**
   * jitter time, time units: ns, us (or µs), ms, s, m, h.
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  jitter?: string
  /**
   * delay egress time, time units: ns, us (or µs), ms, s, m, h.
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  latency?: string
  /**
   * only impact egress traffic from these source ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkDelaySpec
   */
  'source-port'?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkDownSpec
 */
export interface V1alpha1NetworkDownSpec {
  /**
   * The network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkDownSpec
   */
  device?: string
  /**
   * NIC down time, time units: ns, us (or µs), ms, s, m, h.
   * @type {string}
   * @memberof V1alpha1NetworkDownSpec
   */
  duration?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkDuplicateSpec
 */
export interface V1alpha1NetworkDuplicateSpec {
  /**
   * correlation is percentage (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  correlation?: string
  /**
   * the network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  device?: string
  /**
   * only impact egress traffic to these destination ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  'egress-port'?: string
  /**
   * only impact traffic to these hostnames
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  hostname?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  'ip-address'?: string
  /**
   * only impact traffic using this IP protocol, supported: tcp, udp, icmp, all
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  'ip-protocol'?: string
  /**
   * percentage of packets to duplicate (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  percent?: string
  /**
   * only impact egress traffic from these source ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkDuplicateSpec
   */
  'source-port'?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkFloodSpec
 */
export interface V1alpha1NetworkFloodSpec {
  /**
   * The number of seconds to run the iperf test
   * @type {string}
   * @memberof V1alpha1NetworkFloodSpec
   */
  duration?: string
  /**
   * Generate traffic to this IP address
   * @type {string}
   * @memberof V1alpha1NetworkFloodSpec
   */
  'ip-address'?: string
  /**
   * The number of iperf parallel client threads to run
   * @type {number}
   * @memberof V1alpha1NetworkFloodSpec
   */
  parallel?: number
  /**
   * Generate traffic to this port on the IP address
   * @type {string}
   * @memberof V1alpha1NetworkFloodSpec
   */
  port?: string
  /**
   * The speed of network traffic, allows bps, kbps, mbps, gbps, tbps unit. bps means bytes per second
   * @type {string}
   * @memberof V1alpha1NetworkFloodSpec
   */
  rate?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkLossSpec
 */
export interface V1alpha1NetworkLossSpec {
  /**
   * correlation is percentage (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  correlation?: string
  /**
   * the network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  device?: string
  /**
   * only impact egress traffic to these destination ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  'egress-port'?: string
  /**
   * only impact traffic to these hostnames
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  hostname?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  'ip-address'?: string
  /**
   * only impact traffic using this IP protocol, supported: tcp, udp, icmp, all
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  'ip-protocol'?: string
  /**
   * percentage of packets to loss (10 is 10%)
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  percent?: string
  /**
   * only impact egress traffic from these source ports, use a \',\' to separate or to indicate the range, such as 80, 8001:8010. it can only be used in conjunction with -p tcp or -p udp
   * @type {string}
   * @memberof V1alpha1NetworkLossSpec
   */
  'source-port'?: string
}
/**
 *
 * @export
 * @interface V1alpha1NetworkPartitionSpec
 */
export interface V1alpha1NetworkPartitionSpec {
  /**
   * only the packet which match the tcp flag can be accepted, others will be dropped. only set when the IPProtocol is tcp, used for partition.
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  'accept-tcp-flags'?: string
  /**
   * the network interface to impact
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  device?: string
  /**
   * specifies the partition direction, values can be \'from\', \'to\'. \'from\' means packets coming from the \'IPAddress\' or \'Hostname\' and going to your server, \'to\' means packets originating from your server and going to the \'IPAddress\' or \'Hostname\'.
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  direction?: string
  /**
   * only impact traffic to these hostnames
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  hostname?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  'ip-address'?: string
  /**
   * only impact egress traffic to these IP addresses
   * @type {string}
   * @memberof V1alpha1NetworkPartitionSpec
   */
  'ip-protocol'?: string
}
/**
 *
 * @export
 * @interface V1alpha1PMJVMMySQLSpec
 */
export interface V1alpha1PMJVMMySQLSpec {
  /**
   * the match database default value is \"\", means match all database
   * @type {string}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  database?: string
  /**
   * The exception which needs to throw for action `exception` or the exception message needs to throw in action `mysql`
   * @type {string}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  exception?: string
  /**
   * The latency duration for action \'latency\' or the latency duration in action `mysql`
   * @type {number}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  latency?: number
  /**
   * the version of mysql-connector-java, only support 5.X.X(set to \"5\") and 8.X.X(set to \"8\") now
   * @type {string}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  mysqlConnectorVersion?: string
  /**
   * the pid of Java process which needs to attach
   * @type {number}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  pid?: number
  /**
   * +optional the port of agent server, default 9277
   * @type {number}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  port?: number
  /**
   * the match sql type default value is \"\", means match all SQL type. The value can be \'select\', \'insert\', \'update\', \'delete\', \'replace\'.
   * @type {string}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  sqlType?: string
  /**
   * the match table default value is \"\", means match all table
   * @type {string}
   * @memberof V1alpha1PMJVMMySQLSpec
   */
  table?: string
}
/**
 *
 * @export
 * @interface V1alpha1PhysicalMachineChaosSpec
 */
export interface V1alpha1PhysicalMachineChaosSpec {
  /**
   * +kubebuilder:validation:Enum=stress-cpu;stress-mem;disk-read-payload;disk-write-payload;disk-fill;network-corrupt;network-duplicate;network-loss;network-delay;network-partition;network-dns;network-bandwidth;network-flood;network-down;process;jvm-exception;jvm-gc;jvm-latency;jvm-return;jvm-stress;jvm-rule-data;jvm-mysql;clock;redis-expiration;redis-penetration;redis-cacheLimit;redis-restart;redis-stop;kafka-fill;kafka-flood;kafka-io;file-create;file-modify;file-delete;file-rename;file-append;file-replace;vm;user_defined
   * @type {string}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  action?: string
  /**
   * DEPRECATED: Use Selector instead. Only one of Address and Selector could be specified. +optional
   * @type {Array<string>}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  address?: Array<string>
  /**
   * +ui:form:when=action==\'clock\' +optional
   * @type {V1alpha1ClockSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  clock?: V1alpha1ClockSpec
  /**
   * +ui:form:when=action==\'disk-fill\' +optional
   * @type {V1alpha1DiskFillSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'disk-fill'?: V1alpha1DiskFillSpec
  /**
   * +ui:form:when=action==\'disk-read-payload\' +optional
   * @type {V1alpha1DiskPayloadSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'disk-read-payload'?: V1alpha1DiskPayloadSpec
  /**
   * +ui:form:when=action==\'disk-write-payload\' +optional
   * @type {V1alpha1DiskPayloadSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'disk-write-payload'?: V1alpha1DiskPayloadSpec
  /**
   * Duration represents the duration of the chaos action +optional
   * @type {string}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  duration?: string
  /**
   * +ui:form:when=action==\'file-append\' +optional
   * @type {V1alpha1FileAppendSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-append'?: V1alpha1FileAppendSpec
  /**
   * +ui:form:when=action==\'file-create\' +optional
   * @type {V1alpha1FileCreateSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-create'?: V1alpha1FileCreateSpec
  /**
   * +ui:form:when=action==\'file-delete\' +optional
   * @type {V1alpha1FileDeleteSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-delete'?: V1alpha1FileDeleteSpec
  /**
   * +ui:form:when=action==\'file-modify\' +optional
   * @type {V1alpha1FileModifyPrivilegeSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-modify'?: V1alpha1FileModifyPrivilegeSpec
  /**
   * +ui:form:when=action==\'file-create\' +optional
   * @type {V1alpha1FileRenameSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-rename'?: V1alpha1FileRenameSpec
  /**
   * +ui:form:when=action==\'file-replace\' +optional
   * @type {V1alpha1FileReplaceSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'file-replace'?: V1alpha1FileReplaceSpec
  /**
   * +ui:form:when=action==\'http-abort\' +optional
   * @type {V1alpha1HTTPAbortSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'http-abort'?: V1alpha1HTTPAbortSpec
  /**
   * +ui:form:when=action==\'http-config\' +optional
   * @type {V1alpha1HTTPConfigSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'http-config'?: V1alpha1HTTPConfigSpec
  /**
   * +ui:form:when=action==\'http-delay\' +optional
   * @type {V1alpha1HTTPDelaySpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'http-delay'?: V1alpha1HTTPDelaySpec
  /**
   * +ui:form:when=action==\'http-request\' +optional
   * @type {V1alpha1HTTPRequestSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'http-request'?: V1alpha1HTTPRequestSpec
  /**
   * +ui:form:when=action==\'jvm-exception\' +optional
   * @type {V1alpha1JVMExceptionSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-exception'?: V1alpha1JVMExceptionSpec
  /**
   * +ui:form:when=action==\'jvm-gc\' +optional
   * @type {V1alpha1JVMGCSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-gc'?: V1alpha1JVMGCSpec
  /**
   * +ui:form:when=action==\'jvm-latency\' +optional
   * @type {V1alpha1JVMLatencySpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-latency'?: V1alpha1JVMLatencySpec
  /**
   * +ui:form:when=action==\'jvm-mysql\' +optional
   * @type {V1alpha1PMJVMMySQLSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-mysql'?: V1alpha1PMJVMMySQLSpec
  /**
   * +ui:form:when=action==\'jvm-return\' +optional
   * @type {V1alpha1JVMReturnSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-return'?: V1alpha1JVMReturnSpec
  /**
   * +ui:form:when=action==\'jvm-rule-data\' +optional
   * @type {V1alpha1JVMRuleDataSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-rule-data'?: V1alpha1JVMRuleDataSpec
  /**
   * +ui:form:when=action==\'jvm-stress\' +optional
   * @type {V1alpha1JVMStressSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'jvm-stress'?: V1alpha1JVMStressSpec
  /**
   * +ui:form:when=action==\'kafka-fill\' +optional
   * @type {V1alpha1KafkaFillSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'kafka-fill'?: V1alpha1KafkaFillSpec
  /**
   * +ui:form:when=action==\'kafka-flood\' +optional
   * @type {V1alpha1KafkaFloodSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'kafka-flood'?: V1alpha1KafkaFloodSpec
  /**
   * +ui:form:when=action==\'kafka-io\' +optional
   * @type {V1alpha1KafkaIOSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'kafka-io'?: V1alpha1KafkaIOSpec
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  mode?: string
  /**
   * +ui:form:when=action==\'network-bandwidth\' +optional
   * @type {V1alpha1NetworkBandwidthSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-bandwidth'?: V1alpha1NetworkBandwidthSpec
  /**
   * +ui:form:when=action==\'network-corrupt\' +optional
   * @type {V1alpha1NetworkCorruptSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-corrupt'?: V1alpha1NetworkCorruptSpec
  /**
   * +ui:form:when=action==\'network-delay\' +optional
   * @type {V1alpha1NetworkDelaySpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-delay'?: V1alpha1NetworkDelaySpec
  /**
   * +ui:form:when=action==\'network-dns\' +optional
   * @type {V1alpha1NetworkDNSSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-dns'?: V1alpha1NetworkDNSSpec
  /**
   * +ui:form:when=action==\'network-down\' +optional
   * @type {V1alpha1NetworkDownSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-down'?: V1alpha1NetworkDownSpec
  /**
   * +ui:form:when=action==\'network-duplicate\' +optional
   * @type {V1alpha1NetworkDuplicateSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-duplicate'?: V1alpha1NetworkDuplicateSpec
  /**
   * +ui:form:when=action==\'network-flood\' +optional
   * @type {V1alpha1NetworkFloodSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-flood'?: V1alpha1NetworkFloodSpec
  /**
   * +ui:form:when=action==\'network-loss\' +optional
   * @type {V1alpha1NetworkLossSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-loss'?: V1alpha1NetworkLossSpec
  /**
   * +ui:form:when=action==\'network-partition\' +optional
   * @type {V1alpha1NetworkPartitionSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'network-partition'?: V1alpha1NetworkPartitionSpec
  /**
   * +ui:form:when=action==\'process\' +optional
   * @type {V1alpha1ProcessSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  process?: V1alpha1ProcessSpec
  /**
   * +ui:form:when=action==\'redis-cacheLimit\' +optional
   * @type {V1alpha1RedisCacheLimitSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'redis-cacheLimit'?: V1alpha1RedisCacheLimitSpec
  /**
   * +ui:form:when=action==\'redis-expiration\' +optional
   * @type {V1alpha1RedisExpirationSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'redis-expiration'?: V1alpha1RedisExpirationSpec
  /**
   * +ui:form:when=action==\'redis-penetration\' +optional
   * @type {V1alpha1RedisPenetrationSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'redis-penetration'?: V1alpha1RedisPenetrationSpec
  /**
   * +ui:form:when=action==\'redis-restart\' +optional
   * @type {V1alpha1RedisSentinelRestartSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'redis-restart'?: V1alpha1RedisSentinelRestartSpec
  /**
   * +ui:form:when=action==\'redis-stop\' +optional
   * @type {V1alpha1RedisSentinelStopSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'redis-stop'?: V1alpha1RedisSentinelStopSpec
  /**
   *
   * @type {V1alpha1PhysicalMachineSelectorSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  selector?: V1alpha1PhysicalMachineSelectorSpec
  /**
   * +ui:form:when=action==\'stress-cpu\' +optional
   * @type {V1alpha1StressCPUSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'stress-cpu'?: V1alpha1StressCPUSpec
  /**
   * +ui:form:when=action==\'stress-mem\' +optional
   * @type {V1alpha1StressMemorySpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  'stress-mem'?: V1alpha1StressMemorySpec
  /**
   * +ui:form:when=action==\'user_defined\' +optional
   * @type {V1alpha1UserDefinedSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  user_defined?: V1alpha1UserDefinedSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of physical machines to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of physical machines the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  value?: string
  /**
   * +ui:form:when=action==\'vm\' +optional
   * @type {V1alpha1VMSpec}
   * @memberof V1alpha1PhysicalMachineChaosSpec
   */
  vm?: V1alpha1VMSpec
}
/**
 *
 * @export
 * @interface V1alpha1PhysicalMachineSelectorSpec
 */
export interface V1alpha1PhysicalMachineSelectorSpec {
  /**
   * Map of string keys and values that can be used to select objects. A selector based on annotations. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PhysicalMachineSelectorSpec
   */
  annotationSelectors?: { [key: string]: string }
  /**
   * Map of string keys and values that can be used to select objects. A selector based on fields. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PhysicalMachineSelectorSpec
   */
  fieldSelectors?: { [key: string]: string }
  /**
   * Map of string keys and values that can be used to select objects. A selector based on labels. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PhysicalMachineSelectorSpec
   */
  labelSelectors?: { [key: string]: string }
  /**
   * Namespaces is a set of namespace to which objects belong. +optional
   * @type {Array<string>}
   * @memberof V1alpha1PhysicalMachineSelectorSpec
   */
  namespaces?: Array<string>
  /**
   * PhysicalMachines is a map of string keys and a set values that used to select physical machines. The key defines the namespace which physical machine belong, and each value is a set of physical machine names. +optional
   * @type {{ [key: string]: Array<string>; }}
   * @memberof V1alpha1PhysicalMachineSelectorSpec
   */
  physicalMachines?: { [key: string]: Array<string> }
}
/**
 *
 * @export
 * @interface V1alpha1PodChaosSpec
 */
export interface V1alpha1PodChaosSpec {
  /**
   * Action defines the specific pod chaos action. Supported action: pod-kill / pod-failure / container-kill Default action: pod-kill +kubebuilder:validation:Enum=pod-kill;pod-failure;container-kill
   * @type {string}
   * @memberof V1alpha1PodChaosSpec
   */
  action?: string
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1PodChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action. It is required when the action is `PodFailureAction`. A duration string is a possibly signed sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\", \"-1.5h\" or \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +optional
   * @type {string}
   * @memberof V1alpha1PodChaosSpec
   */
  duration?: string
  /**
   * GracePeriod is used in pod-kill action. It represents the duration in seconds before the pod should be deleted. Value must be non-negative integer. The default value is zero that indicates delete immediately. +optional +kubebuilder:validation:Minimum=0
   * @type {number}
   * @memberof V1alpha1PodChaosSpec
   */
  gracePeriod?: number
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1PodChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1PodChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1PodChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1PodHttpChaosPatchActions
 */
export interface V1alpha1PodHttpChaosPatchActions {
  /**
   * Body is a rule to patch message body of target. +optional
   * @type {V1alpha1PodHttpChaosPatchBodyAction}
   * @memberof V1alpha1PodHttpChaosPatchActions
   */
  body?: V1alpha1PodHttpChaosPatchBodyAction
  /**
   * Headers is a rule to append http headers of target. For example: `[[\"Set-Cookie\", \"<one cookie>\"], [\"Set-Cookie\", \"<another cookie>\"]]`. +optional
   * @type {Array<Array<string>>}
   * @memberof V1alpha1PodHttpChaosPatchActions
   */
  headers?: Array<Array<string>>
  /**
   * Queries is a rule to append uri queries of target(Request only). For example: `[[\"foo\", \"bar\"], [\"foo\", \"unknown\"]]`. +optional
   * @type {Array<Array<string>>}
   * @memberof V1alpha1PodHttpChaosPatchActions
   */
  queries?: Array<Array<string>>
}
/**
 *
 * @export
 * @interface V1alpha1PodHttpChaosPatchBodyAction
 */
export interface V1alpha1PodHttpChaosPatchBodyAction {
  /**
   * Type represents the patch type, only support `JSON` as [merge patch json](https://tools.ietf.org/html/rfc7396) currently.
   * @type {string}
   * @memberof V1alpha1PodHttpChaosPatchBodyAction
   */
  type?: string
  /**
   * Value is the patch contents.
   * @type {string}
   * @memberof V1alpha1PodHttpChaosPatchBodyAction
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1PodHttpChaosReplaceActions
 */
export interface V1alpha1PodHttpChaosReplaceActions {
  /**
   * Body is a rule to replace http message body in target. +optional
   * @type {Array<number>}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  body?: Array<number>
  /**
   * Code is a rule to replace http status code in response. +optional
   * @type {number}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  code?: number
  /**
   * Headers is a rule to replace http headers of target. The key-value pairs represent header name and header value pairs. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  headers?: { [key: string]: string }
  /**
   * Method is a rule to replace http method in request. +optional
   * @type {string}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  method?: string
  /**
   * Path is rule to to replace uri path in http request. +optional
   * @type {string}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  path?: string
  /**
   * Queries is a rule to replace uri queries in http request. For example, with value `{ \"foo\": \"unknown\" }`, the `/?foo=bar` will be altered to `/?foo=unknown`, +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodHttpChaosReplaceActions
   */
  queries?: { [key: string]: string }
}
/**
 *
 * @export
 * @interface V1alpha1PodSelector
 */
export interface V1alpha1PodSelector {
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1PodSelector
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1PodSelector
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1PodSelector
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1PodSelectorSpec
 */
export interface V1alpha1PodSelectorSpec {
  /**
   * Map of string keys and values that can be used to select objects. A selector based on annotations. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodSelectorSpec
   */
  annotationSelectors?: { [key: string]: string }
  /**
   * Map of string keys and values that can be used to select objects. A selector based on fields. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodSelectorSpec
   */
  fieldSelectors?: { [key: string]: string }
  /**
   * Map of string keys and values that can be used to select objects. A selector based on labels. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodSelectorSpec
   */
  labelSelectors?: { [key: string]: string }
  /**
   * Namespaces is a set of namespace to which objects belong. +optional
   * @type {Array<string>}
   * @memberof V1alpha1PodSelectorSpec
   */
  namespaces?: Array<string>
  /**
   * Map of string keys and values that can be used to select nodes. Selector which must match a node\'s labels, and objects must belong to these selected nodes. +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1PodSelectorSpec
   */
  nodeSelectors?: { [key: string]: string }
  /**
   * Nodes is a set of node name and objects must belong to these nodes. +optional
   * @type {Array<string>}
   * @memberof V1alpha1PodSelectorSpec
   */
  nodes?: Array<string>
  /**
   * PodPhaseSelectors is a set of condition of a pod at the current time. supported value: Pending / Running / Succeeded / Failed / Unknown +optional
   * @type {Array<string>}
   * @memberof V1alpha1PodSelectorSpec
   */
  podPhaseSelectors?: Array<string>
  /**
   * Pods is a map of string keys and a set values that used to select pods. The key defines the namespace which pods belong, and the each values is a set of pod names. +optional
   * @type {{ [key: string]: Array<string>; }}
   * @memberof V1alpha1PodSelectorSpec
   */
  pods?: { [key: string]: Array<string> }
}
/**
 *
 * @export
 * @interface V1alpha1ProcessSpec
 */
export interface V1alpha1ProcessSpec {
  /**
   * the process name or the process ID
   * @type {string}
   * @memberof V1alpha1ProcessSpec
   */
  process?: string
  /**
   * the command to be run when recovering experiment
   * @type {string}
   * @memberof V1alpha1ProcessSpec
   */
  recoverCmd?: string
  /**
   * the signal number to send
   * @type {number}
   * @memberof V1alpha1ProcessSpec
   */
  signal?: number
}
/**
 *
 * @export
 * @interface V1alpha1RedisCacheLimitSpec
 */
export interface V1alpha1RedisCacheLimitSpec {
  /**
   * The adress of Redis server
   * @type {string}
   * @memberof V1alpha1RedisCacheLimitSpec
   */
  addr?: string
  /**
   * The size of `maxmemory`
   * @type {string}
   * @memberof V1alpha1RedisCacheLimitSpec
   */
  cacheSize?: string
  /**
   * The password of Redis server
   * @type {string}
   * @memberof V1alpha1RedisCacheLimitSpec
   */
  password?: string
  /**
   * Specifies maxmemory as a percentage of the original value
   * @type {string}
   * @memberof V1alpha1RedisCacheLimitSpec
   */
  percent?: string
}
/**
 *
 * @export
 * @interface V1alpha1RedisExpirationSpec
 */
export interface V1alpha1RedisExpirationSpec {
  /**
   * The adress of Redis server
   * @type {string}
   * @memberof V1alpha1RedisExpirationSpec
   */
  addr?: string
  /**
   * The expiration of the keys
   * @type {string}
   * @memberof V1alpha1RedisExpirationSpec
   */
  expiration?: string
  /**
   * The keys to be expired
   * @type {string}
   * @memberof V1alpha1RedisExpirationSpec
   */
  key?: string
  /**
   * Additional options for `expiration`
   * @type {string}
   * @memberof V1alpha1RedisExpirationSpec
   */
  option?: string
  /**
   * The password of Redis server
   * @type {string}
   * @memberof V1alpha1RedisExpirationSpec
   */
  password?: string
}
/**
 *
 * @export
 * @interface V1alpha1RedisPenetrationSpec
 */
export interface V1alpha1RedisPenetrationSpec {
  /**
   * The adress of Redis server
   * @type {string}
   * @memberof V1alpha1RedisPenetrationSpec
   */
  addr?: string
  /**
   * The password of Redis server
   * @type {string}
   * @memberof V1alpha1RedisPenetrationSpec
   */
  password?: string
  /**
   * The number of requests to be sent
   * @type {number}
   * @memberof V1alpha1RedisPenetrationSpec
   */
  requestNum?: number
}
/**
 *
 * @export
 * @interface V1alpha1RedisSentinelRestartSpec
 */
export interface V1alpha1RedisSentinelRestartSpec {
  /**
   * The adress of Redis server
   * @type {string}
   * @memberof V1alpha1RedisSentinelRestartSpec
   */
  addr?: string
  /**
   * The path of Sentinel conf
   * @type {string}
   * @memberof V1alpha1RedisSentinelRestartSpec
   */
  conf?: string
  /**
   * The control flag determines whether to flush config
   * @type {boolean}
   * @memberof V1alpha1RedisSentinelRestartSpec
   */
  flushConfig?: boolean
  /**
   * The password of Redis server
   * @type {string}
   * @memberof V1alpha1RedisSentinelRestartSpec
   */
  password?: string
  /**
   * The path of `redis-server` command-line tool
   * @type {boolean}
   * @memberof V1alpha1RedisSentinelRestartSpec
   */
  redisPath?: boolean
}
/**
 *
 * @export
 * @interface V1alpha1RedisSentinelStopSpec
 */
export interface V1alpha1RedisSentinelStopSpec {
  /**
   * The adress of Redis server
   * @type {string}
   * @memberof V1alpha1RedisSentinelStopSpec
   */
  addr?: string
  /**
   * The path of Sentinel conf
   * @type {string}
   * @memberof V1alpha1RedisSentinelStopSpec
   */
  conf?: string
  /**
   * The control flag determines whether to flush config
   * @type {boolean}
   * @memberof V1alpha1RedisSentinelStopSpec
   */
  flushConfig?: boolean
  /**
   * The password of Redis server
   * @type {string}
   * @memberof V1alpha1RedisSentinelStopSpec
   */
  password?: string
  /**
   * The path of `redis-server` command-line tool
   * @type {boolean}
   * @memberof V1alpha1RedisSentinelStopSpec
   */
  redisPath?: boolean
}
/**
 *
 * @export
 * @interface V1alpha1ReorderSpec
 */
export interface V1alpha1ReorderSpec {
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1ReorderSpec
   */
  correlation?: string
  /**
   *
   * @type {number}
   * @memberof V1alpha1ReorderSpec
   */
  gap?: number
  /**
   *
   * @type {string}
   * @memberof V1alpha1ReorderSpec
   */
  reorder?: string
}
/**
 *
 * @export
 * @interface V1alpha1Schedule
 */
export interface V1alpha1Schedule {
  /**
   * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: http://kubernetes.io/docs/user-guide/annotations +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1Schedule
   */
  annotations?: { [key: string]: string }
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  apiVersion?: string
  /**
   * The name of the cluster which the object belongs to. This is used to distinguish resources with same name and namespace in different clusters. This field is not set anywhere right now and apiserver is going to ignore it if set in create or update request. +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  clusterName?: string
  /**
   * CreationTimestamp is a timestamp representing the server time when this object was created. It is not guaranteed to be set in happens-before order across separate operations. Clients may not set this value. It is represented in RFC3339 form and is in UTC.  Populated by the system. Read-only. Null for lists. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  creationTimestamp?: string
  /**
   * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only. +optional
   * @type {number}
   * @memberof V1alpha1Schedule
   */
  deletionGracePeriodSeconds?: number
  /**
   * DeletionTimestamp is RFC 3339 date and time at which this resource will be deleted. This field is set by the server when a graceful deletion is requested by the user, and is not directly settable by a client. The resource is expected to be deleted (no longer visible from resource lists, and not reachable by name) after the time in this field, once the finalizers list is empty. As long as the finalizers list contains items, deletion is blocked. Once the deletionTimestamp is set, this value may not be unset or be set further into the future, although it may be shortened or the resource may be deleted prior to this time. For example, a user may request that a pod is deleted in 30 seconds. The Kubelet will react by sending a graceful termination signal to the containers in the pod. After that 30 seconds, the Kubelet will send a hard termination signal (SIGKILL) to the container and after cleanup, remove the pod from the API. In the presence of network partitions, this object may still exist after this timestamp, until an administrator or automated process can determine the resource is fully terminated. If not set, graceful deletion of the object has not been requested.  Populated by the system when a graceful deletion is requested. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  deletionTimestamp?: string
  /**
   * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list. +optional +patchStrategy=merge
   * @type {Array<string>}
   * @memberof V1alpha1Schedule
   */
  finalizers?: Array<string>
  /**
   * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will NOT return a 409 - instead, it will either return 201 Created or 500 with Reason ServerTimeout indicating a unique name could not be found in the time allotted, and the client should retry (optionally after the time indicated in the Retry-After header).  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  generateName?: string
  /**
   * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only. +optional
   * @type {number}
   * @memberof V1alpha1Schedule
   */
  generation?: number
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  kind?: string
  /**
   * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: http://kubernetes.io/docs/user-guide/labels +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1Schedule
   */
  labels?: { [key: string]: string }
  /**
   * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.  +optional
   * @type {Array<V1ManagedFieldsEntry>}
   * @memberof V1alpha1Schedule
   */
  managedFields?: Array<V1ManagedFieldsEntry>
  /**
   * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/identifiers#names +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  name?: string
  /**
   * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/namespaces +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  namespace?: string
  /**
   * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller. +optional +patchMergeKey=uid +patchStrategy=merge
   * @type {Array<V1OwnerReference>}
   * @memberof V1alpha1Schedule
   */
  ownerReferences?: Array<V1OwnerReference>
  /**
   * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  resourceVersion?: string
  /**
   * SelfLink is a URL representing this object. Populated by the system. Read-only.  DEPRECATED Kubernetes will stop propagating this field in 1.20 release and the field is planned to be removed in 1.21 release. +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  selfLink?: string
  /**
   *
   * @type {V1alpha1ScheduleSpec}
   * @memberof V1alpha1Schedule
   */
  spec?: V1alpha1ScheduleSpec
  /**
   *
   * @type {V1alpha1ScheduleStatus}
   * @memberof V1alpha1Schedule
   */
  status?: V1alpha1ScheduleStatus
  /**
   * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: http://kubernetes.io/docs/user-guide/identifiers#uids +optional
   * @type {string}
   * @memberof V1alpha1Schedule
   */
  uid?: string
}
/**
 *
 * @export
 * @interface V1alpha1ScheduleSpec
 */
export interface V1alpha1ScheduleSpec {
  /**
   *
   * @type {V1alpha1AWSChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  awsChaos?: V1alpha1AWSChaosSpec
  /**
   *
   * @type {V1alpha1AzureChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  azureChaos?: V1alpha1AzureChaosSpec
  /**
   *
   * @type {V1alpha1BlockChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  blockChaos?: V1alpha1BlockChaosSpec
  /**
   * +optional +kubebuilder:default=Forbid +kubebuilder:validation:Enum=Forbid;Allow
   * @type {string}
   * @memberof V1alpha1ScheduleSpec
   */
  concurrencyPolicy?: string
  /**
   *
   * @type {V1alpha1DNSChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  dnsChaos?: V1alpha1DNSChaosSpec
  /**
   *
   * @type {V1alpha1GCPChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  gcpChaos?: V1alpha1GCPChaosSpec
  /**
   * +optional +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1ScheduleSpec
   */
  historyLimit?: number
  /**
   *
   * @type {V1alpha1HTTPChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  httpChaos?: V1alpha1HTTPChaosSpec
  /**
   *
   * @type {V1alpha1IOChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  ioChaos?: V1alpha1IOChaosSpec
  /**
   *
   * @type {V1alpha1JVMChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  jvmChaos?: V1alpha1JVMChaosSpec
  /**
   *
   * @type {V1alpha1KernelChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  kernelChaos?: V1alpha1KernelChaosSpec
  /**
   *
   * @type {V1alpha1NetworkChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  networkChaos?: V1alpha1NetworkChaosSpec
  /**
   *
   * @type {V1alpha1PhysicalMachineChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  physicalmachineChaos?: V1alpha1PhysicalMachineChaosSpec
  /**
   *
   * @type {V1alpha1PodChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  podChaos?: V1alpha1PodChaosSpec
  /**
   *
   * @type {string}
   * @memberof V1alpha1ScheduleSpec
   */
  schedule?: string
  /**
   * +optional +nullable +kubebuilder:validation:Minimum=0 +kubebuilder:validation:ExclusiveMinimum=true
   * @type {number}
   * @memberof V1alpha1ScheduleSpec
   */
  startingDeadlineSeconds?: number
  /**
   *
   * @type {V1alpha1StressChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  stressChaos?: V1alpha1StressChaosSpec
  /**
   *
   * @type {V1alpha1TimeChaosSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  timeChaos?: V1alpha1TimeChaosSpec
  /**
   * TODO: use a custom type, as `TemplateType` contains other possible values
   * @type {string}
   * @memberof V1alpha1ScheduleSpec
   */
  type?: string
  /**
   *
   * @type {V1alpha1WorkflowSpec}
   * @memberof V1alpha1ScheduleSpec
   */
  workflow?: V1alpha1WorkflowSpec
}
/**
 *
 * @export
 * @interface V1alpha1ScheduleStatus
 */
export interface V1alpha1ScheduleStatus {
  /**
   * +optional
   * @type {Array<V1ObjectReference>}
   * @memberof V1alpha1ScheduleStatus
   */
  active?: Array<V1ObjectReference>
  /**
   * +optional +nullable
   * @type {string}
   * @memberof V1alpha1ScheduleStatus
   */
  time?: string
}
/**
 *
 * @export
 * @interface V1alpha1StatusCheckSpec
 */
export interface V1alpha1StatusCheckSpec {
  /**
   * Duration defines the duration of the whole status check if the number of failed execution does not exceed the failure threshold. Duration is available to both `Synchronous` and `Continuous` mode. A duration string is a possibly signed sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\", \"-1.5h\" or \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +optional
   * @type {string}
   * @memberof V1alpha1StatusCheckSpec
   */
  duration?: string
  /**
   * FailureThreshold defines the minimum consecutive failure for the status check to be considered failed. +optional +kubebuilder:default=3 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckSpec
   */
  failureThreshold?: number
  /**
   *
   * @type {V1alpha1HTTPStatusCheck}
   * @memberof V1alpha1StatusCheckSpec
   */
  http?: V1alpha1HTTPStatusCheck
  /**
   * IntervalSeconds defines how often (in seconds) to perform an execution of status check. +optional +kubebuilder:default=10 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckSpec
   */
  intervalSeconds?: number
  /**
   * Mode defines the execution mode of the status check. Support type: Synchronous / Continuous +optional +kubebuilder:validation:Enum=Synchronous;Continuous
   * @type {string}
   * @memberof V1alpha1StatusCheckSpec
   */
  mode?: string
  /**
   * RecordsHistoryLimit defines the number of record to retain. +optional +kubebuilder:default=100 +kubebuilder:validation:Minimum=1 +kubebuilder:validation:Maximum=1000
   * @type {number}
   * @memberof V1alpha1StatusCheckSpec
   */
  recordsHistoryLimit?: number
  /**
   * SuccessThreshold defines the minimum consecutive successes for the status check to be considered successful. SuccessThreshold only works for `Synchronous` mode. +optional +kubebuilder:default=1 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckSpec
   */
  successThreshold?: number
  /**
   * TimeoutSeconds defines the number of seconds after which an execution of status check times out. +optional +kubebuilder:default=1 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckSpec
   */
  timeoutSeconds?: number
  /**
   * Type defines the specific status check type. Support type: HTTP +kubebuilder:default=HTTP +kubebuilder:validation:Enum=HTTP
   * @type {string}
   * @memberof V1alpha1StatusCheckSpec
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1alpha1StatusCheckTemplate
 */
export interface V1alpha1StatusCheckTemplate {
  /**
   * Duration defines the duration of the whole status check if the number of failed execution does not exceed the failure threshold. Duration is available to both `Synchronous` and `Continuous` mode. A duration string is a possibly signed sequence of decimal numbers, each with optional fraction and a unit suffix, such as \"300ms\", \"-1.5h\" or \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\". +optional
   * @type {string}
   * @memberof V1alpha1StatusCheckTemplate
   */
  duration?: string
  /**
   * FailureThreshold defines the minimum consecutive failure for the status check to be considered failed. +optional +kubebuilder:default=3 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckTemplate
   */
  failureThreshold?: number
  /**
   *
   * @type {V1alpha1HTTPStatusCheck}
   * @memberof V1alpha1StatusCheckTemplate
   */
  http?: V1alpha1HTTPStatusCheck
  /**
   * IntervalSeconds defines how often (in seconds) to perform an execution of status check. +optional +kubebuilder:default=10 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckTemplate
   */
  intervalSeconds?: number
  /**
   * Mode defines the execution mode of the status check. Support type: Synchronous / Continuous +optional +kubebuilder:validation:Enum=Synchronous;Continuous
   * @type {string}
   * @memberof V1alpha1StatusCheckTemplate
   */
  mode?: string
  /**
   * RecordsHistoryLimit defines the number of record to retain. +optional +kubebuilder:default=100 +kubebuilder:validation:Minimum=1 +kubebuilder:validation:Maximum=1000
   * @type {number}
   * @memberof V1alpha1StatusCheckTemplate
   */
  recordsHistoryLimit?: number
  /**
   * SuccessThreshold defines the minimum consecutive successes for the status check to be considered successful. SuccessThreshold only works for `Synchronous` mode. +optional +kubebuilder:default=1 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckTemplate
   */
  successThreshold?: number
  /**
   * TimeoutSeconds defines the number of seconds after which an execution of status check times out. +optional +kubebuilder:default=1 +kubebuilder:validation:Minimum=1
   * @type {number}
   * @memberof V1alpha1StatusCheckTemplate
   */
  timeoutSeconds?: number
  /**
   * Type defines the specific status check type. Support type: HTTP +kubebuilder:default=HTTP +kubebuilder:validation:Enum=HTTP
   * @type {string}
   * @memberof V1alpha1StatusCheckTemplate
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1alpha1StressCPUSpec
 */
export interface V1alpha1StressCPUSpec {
  /**
   * specifies P percent loading per CPU worker. 0 is effectively a sleep (no load) and 100 is full loading.
   * @type {number}
   * @memberof V1alpha1StressCPUSpec
   */
  load?: number
  /**
   * extend stress-ng options
   * @type {Array<string>}
   * @memberof V1alpha1StressCPUSpec
   */
  options?: Array<string>
  /**
   * specifies N workers to apply the stressor.
   * @type {number}
   * @memberof V1alpha1StressCPUSpec
   */
  workers?: number
}
/**
 *
 * @export
 * @interface V1alpha1StressChaosSpec
 */
export interface V1alpha1StressChaosSpec {
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1StressChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action +optional
   * @type {string}
   * @memberof V1alpha1StressChaosSpec
   */
  duration?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1StressChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1StressChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * StressngStressors defines plenty of stressors just like `Stressors` except that it\'s an experimental feature and more powerful. You can define stressors in `stress-ng` (see also `man stress-ng`) dialect, however not all of the supported stressors are well tested. It maybe retired in later releases. You should always use `Stressors` to define the stressors and use this only when you want more stressors unsupported by `Stressors`. When both `StressngStressors` and `Stressors` are defined, `StressngStressors` wins. +optional
   * @type {string}
   * @memberof V1alpha1StressChaosSpec
   */
  stressngStressors?: string
  /**
   *
   * @type {V1alpha1Stressors}
   * @memberof V1alpha1StressChaosSpec
   */
  stressors?: V1alpha1Stressors
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1StressChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1StressMemorySpec
 */
export interface V1alpha1StressMemorySpec {
  /**
   * extend stress-ng options
   * @type {Array<string>}
   * @memberof V1alpha1StressMemorySpec
   */
  options?: Array<string>
  /**
   * specifies N bytes consumed per vm worker, default is the total available memory. One can specify the size as % of total available memory or in units of B, KB/KiB, MB/MiB, GB/GiB, TB/TiB..
   * @type {string}
   * @memberof V1alpha1StressMemorySpec
   */
  size?: string
}
/**
 *
 * @export
 * @interface V1alpha1Stressors
 */
export interface V1alpha1Stressors {
  /**
   *
   * @type {V1alpha1CPUStressor}
   * @memberof V1alpha1Stressors
   */
  cpu?: V1alpha1CPUStressor
  /**
   *
   * @type {V1alpha1MemoryStressor}
   * @memberof V1alpha1Stressors
   */
  memory?: V1alpha1MemoryStressor
}
/**
 *
 * @export
 * @interface V1alpha1Task
 */
export interface V1alpha1Task {
  /**
   *
   * @type {V1Container}
   * @memberof V1alpha1Task
   */
  container?: V1Container
  /**
   * Volumes is a list of volumes that can be mounted by containers in a template. +patchStrategy=merge +patchMergeKey=name
   * @type {Array<V1Volume>}
   * @memberof V1alpha1Task
   */
  volumes?: Array<V1Volume>
}
/**
 *
 * @export
 * @interface V1alpha1Template
 */
export interface V1alpha1Template {
  /**
   * AbortWithStatusCheck describe whether to abort the workflow when the failure threshold of StatusCheck is exceeded. Only used when Type is TypeStatusCheck. +optional
   * @type {boolean}
   * @memberof V1alpha1Template
   */
  abortWithStatusCheck?: boolean
  /**
   *
   * @type {V1alpha1AWSChaosSpec}
   * @memberof V1alpha1Template
   */
  awsChaos?: V1alpha1AWSChaosSpec
  /**
   *
   * @type {V1alpha1AzureChaosSpec}
   * @memberof V1alpha1Template
   */
  azureChaos?: V1alpha1AzureChaosSpec
  /**
   *
   * @type {V1alpha1BlockChaosSpec}
   * @memberof V1alpha1Template
   */
  blockChaos?: V1alpha1BlockChaosSpec
  /**
   * Children describes the children steps of serial or parallel node. Only used when Type is TypeSerial or TypeParallel. +optional
   * @type {Array<string>}
   * @memberof V1alpha1Template
   */
  children?: Array<string>
  /**
   * ConditionalBranches describes the conditional branches of custom tasks. Only used when Type is TypeTask. +optional
   * @type {Array<V1alpha1ConditionalBranch>}
   * @memberof V1alpha1Template
   */
  conditionalBranches?: Array<V1alpha1ConditionalBranch>
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1Template
   */
  deadline?: string
  /**
   *
   * @type {V1alpha1DNSChaosSpec}
   * @memberof V1alpha1Template
   */
  dnsChaos?: V1alpha1DNSChaosSpec
  /**
   *
   * @type {V1alpha1GCPChaosSpec}
   * @memberof V1alpha1Template
   */
  gcpChaos?: V1alpha1GCPChaosSpec
  /**
   *
   * @type {V1alpha1HTTPChaosSpec}
   * @memberof V1alpha1Template
   */
  httpChaos?: V1alpha1HTTPChaosSpec
  /**
   *
   * @type {V1alpha1IOChaosSpec}
   * @memberof V1alpha1Template
   */
  ioChaos?: V1alpha1IOChaosSpec
  /**
   *
   * @type {V1alpha1JVMChaosSpec}
   * @memberof V1alpha1Template
   */
  jvmChaos?: V1alpha1JVMChaosSpec
  /**
   *
   * @type {V1alpha1KernelChaosSpec}
   * @memberof V1alpha1Template
   */
  kernelChaos?: V1alpha1KernelChaosSpec
  /**
   *
   * @type {string}
   * @memberof V1alpha1Template
   */
  name?: string
  /**
   *
   * @type {V1alpha1NetworkChaosSpec}
   * @memberof V1alpha1Template
   */
  networkChaos?: V1alpha1NetworkChaosSpec
  /**
   *
   * @type {V1alpha1PhysicalMachineChaosSpec}
   * @memberof V1alpha1Template
   */
  physicalmachineChaos?: V1alpha1PhysicalMachineChaosSpec
  /**
   *
   * @type {V1alpha1PodChaosSpec}
   * @memberof V1alpha1Template
   */
  podChaos?: V1alpha1PodChaosSpec
  /**
   *
   * @type {V1alpha1ChaosOnlyScheduleSpec}
   * @memberof V1alpha1Template
   */
  schedule?: V1alpha1ChaosOnlyScheduleSpec
  /**
   *
   * @type {V1alpha1StatusCheckSpec}
   * @memberof V1alpha1Template
   */
  statusCheck?: V1alpha1StatusCheckSpec
  /**
   *
   * @type {V1alpha1StressChaosSpec}
   * @memberof V1alpha1Template
   */
  stressChaos?: V1alpha1StressChaosSpec
  /**
   *
   * @type {V1alpha1Task}
   * @memberof V1alpha1Template
   */
  task?: V1alpha1Task
  /**
   *
   * @type {string}
   * @memberof V1alpha1Template
   */
  templateType?: string
  /**
   *
   * @type {V1alpha1TimeChaosSpec}
   * @memberof V1alpha1Template
   */
  timeChaos?: V1alpha1TimeChaosSpec
}
/**
 *
 * @export
 * @interface V1alpha1TimeChaosSpec
 */
export interface V1alpha1TimeChaosSpec {
  /**
   * ClockIds defines all affected clock id All available options are [\"CLOCK_REALTIME\",\"CLOCK_MONOTONIC\",\"CLOCK_PROCESS_CPUTIME_ID\",\"CLOCK_THREAD_CPUTIME_ID\", \"CLOCK_MONOTONIC_RAW\",\"CLOCK_REALTIME_COARSE\",\"CLOCK_MONOTONIC_COARSE\",\"CLOCK_BOOTTIME\",\"CLOCK_REALTIME_ALARM\", \"CLOCK_BOOTTIME_ALARM\"] Default value is [\"CLOCK_REALTIME\"]
   * @type {Array<string>}
   * @memberof V1alpha1TimeChaosSpec
   */
  clockIds?: Array<string>
  /**
   * ContainerNames indicates list of the name of affected container. If not set, the first container will be injected +optional
   * @type {Array<string>}
   * @memberof V1alpha1TimeChaosSpec
   */
  containerNames?: Array<string>
  /**
   * Duration represents the duration of the chaos action
   * @type {string}
   * @memberof V1alpha1TimeChaosSpec
   */
  duration?: string
  /**
   * Mode defines the mode to run chaos action. Supported mode: one / all / fixed / fixed-percent / random-max-percent +kubebuilder:validation:Enum=one;all;fixed;fixed-percent;random-max-percent
   * @type {string}
   * @memberof V1alpha1TimeChaosSpec
   */
  mode?: string
  /**
   *
   * @type {V1alpha1PodSelectorSpec}
   * @memberof V1alpha1TimeChaosSpec
   */
  selector?: V1alpha1PodSelectorSpec
  /**
   * TimeOffset defines the delta time of injected program. It\'s a possibly signed sequence of decimal numbers, such as \"300ms\", \"-1.5h\" or \"2h45m\". Valid time units are \"ns\", \"us\" (or \"µs\"), \"ms\", \"s\", \"m\", \"h\".
   * @type {string}
   * @memberof V1alpha1TimeChaosSpec
   */
  timeOffset?: string
  /**
   * Value is required when the mode is set to `FixedMode` / `FixedPercentMode` / `RandomMaxPercentMode`. If `FixedMode`, provide an integer of pods to do chaos action. If `FixedPercentMode`, provide a number from 0-100 to specify the percent of pods the server can do chaos action. IF `RandomMaxPercentMode`,  provide a number from 0-100 to specify the max percent of pods to do chaos action +optional
   * @type {string}
   * @memberof V1alpha1TimeChaosSpec
   */
  value?: string
}
/**
 *
 * @export
 * @interface V1alpha1Timespec
 */
export interface V1alpha1Timespec {
  /**
   *
   * @type {number}
   * @memberof V1alpha1Timespec
   */
  nsec?: number
  /**
   *
   * @type {number}
   * @memberof V1alpha1Timespec
   */
  sec?: number
}
/**
 *
 * @export
 * @interface V1alpha1UserDefinedSpec
 */
export interface V1alpha1UserDefinedSpec {
  /**
   * The command to be executed when attack
   * @type {string}
   * @memberof V1alpha1UserDefinedSpec
   */
  attackCmd?: string
  /**
   * The command to be executed when recover
   * @type {string}
   * @memberof V1alpha1UserDefinedSpec
   */
  recoverCmd?: string
}
/**
 *
 * @export
 * @interface V1alpha1VMSpec
 */
export interface V1alpha1VMSpec {
  /**
   * The name of the VM to be injected
   * @type {string}
   * @memberof V1alpha1VMSpec
   */
  'vm-name'?: string
}
/**
 *
 * @export
 * @interface V1alpha1Workflow
 */
export interface V1alpha1Workflow {
  /**
   * Annotations is an unstructured key value map stored with a resource that may be set by external tools to store and retrieve arbitrary metadata. They are not queryable and should be preserved when modifying objects. More info: http://kubernetes.io/docs/user-guide/annotations +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1Workflow
   */
  annotations?: { [key: string]: string }
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  apiVersion?: string
  /**
   * The name of the cluster which the object belongs to. This is used to distinguish resources with same name and namespace in different clusters. This field is not set anywhere right now and apiserver is going to ignore it if set in create or update request. +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  clusterName?: string
  /**
   * CreationTimestamp is a timestamp representing the server time when this object was created. It is not guaranteed to be set in happens-before order across separate operations. Clients may not set this value. It is represented in RFC3339 form and is in UTC.  Populated by the system. Read-only. Null for lists. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  creationTimestamp?: string
  /**
   * Number of seconds allowed for this object to gracefully terminate before it will be removed from the system. Only set when deletionTimestamp is also set. May only be shortened. Read-only. +optional
   * @type {number}
   * @memberof V1alpha1Workflow
   */
  deletionGracePeriodSeconds?: number
  /**
   * DeletionTimestamp is RFC 3339 date and time at which this resource will be deleted. This field is set by the server when a graceful deletion is requested by the user, and is not directly settable by a client. The resource is expected to be deleted (no longer visible from resource lists, and not reachable by name) after the time in this field, once the finalizers list is empty. As long as the finalizers list contains items, deletion is blocked. Once the deletionTimestamp is set, this value may not be unset or be set further into the future, although it may be shortened or the resource may be deleted prior to this time. For example, a user may request that a pod is deleted in 30 seconds. The Kubelet will react by sending a graceful termination signal to the containers in the pod. After that 30 seconds, the Kubelet will send a hard termination signal (SIGKILL) to the container and after cleanup, remove the pod from the API. In the presence of network partitions, this object may still exist after this timestamp, until an administrator or automated process can determine the resource is fully terminated. If not set, graceful deletion of the object has not been requested.  Populated by the system when a graceful deletion is requested. Read-only. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#metadata +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  deletionTimestamp?: string
  /**
   * Must be empty before the object is deleted from the registry. Each entry is an identifier for the responsible component that will remove the entry from the list. If the deletionTimestamp of the object is non-nil, entries in this list can only be removed. Finalizers may be processed and removed in any order.  Order is NOT enforced because it introduces significant risk of stuck finalizers. finalizers is a shared field, any actor with permission can reorder it. If the finalizer list is processed in order, then this can lead to a situation in which the component responsible for the first finalizer in the list is waiting for a signal (field value, external system, or other) produced by a component responsible for a finalizer later in the list, resulting in a deadlock. Without enforced ordering finalizers are free to order amongst themselves and are not vulnerable to ordering changes in the list. +optional +patchStrategy=merge
   * @type {Array<string>}
   * @memberof V1alpha1Workflow
   */
  finalizers?: Array<string>
  /**
   * GenerateName is an optional prefix, used by the server, to generate a unique name ONLY IF the Name field has not been provided. If this field is used, the name returned to the client will be different than the name passed. This value will also be combined with a unique suffix. The provided value has the same validation rules as the Name field, and may be truncated by the length of the suffix required to make the value unique on the server.  If this field is specified and the generated name exists, the server will NOT return a 409 - instead, it will either return 201 Created or 500 with Reason ServerTimeout indicating a unique name could not be found in the time allotted, and the client should retry (optionally after the time indicated in the Retry-After header).  Applied only if Name is not specified. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#idempotency +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  generateName?: string
  /**
   * A sequence number representing a specific generation of the desired state. Populated by the system. Read-only. +optional
   * @type {number}
   * @memberof V1alpha1Workflow
   */
  generation?: number
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  kind?: string
  /**
   * Map of string keys and values that can be used to organize and categorize (scope and select) objects. May match selectors of replication controllers and services. More info: http://kubernetes.io/docs/user-guide/labels +optional
   * @type {{ [key: string]: string; }}
   * @memberof V1alpha1Workflow
   */
  labels?: { [key: string]: string }
  /**
   * ManagedFields maps workflow-id and version to the set of fields that are managed by that workflow. This is mostly for internal housekeeping, and users typically shouldn\'t need to set or understand this field. A workflow can be the user\'s name, a controller\'s name, or the name of a specific apply path like \"ci-cd\". The set of fields is always in the version that the workflow used when modifying the object.  +optional
   * @type {Array<V1ManagedFieldsEntry>}
   * @memberof V1alpha1Workflow
   */
  managedFields?: Array<V1ManagedFieldsEntry>
  /**
   * Name must be unique within a namespace. Is required when creating resources, although some resources may allow a client to request the generation of an appropriate name automatically. Name is primarily intended for creation idempotence and configuration definition. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/identifiers#names +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  name?: string
  /**
   * Namespace defines the space within which each name must be unique. An empty namespace is equivalent to the \"default\" namespace, but \"default\" is the canonical representation. Not all objects are required to be scoped to a namespace - the value of this field for those objects will be empty.  Must be a DNS_LABEL. Cannot be updated. More info: http://kubernetes.io/docs/user-guide/namespaces +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  namespace?: string
  /**
   * List of objects depended by this object. If ALL objects in the list have been deleted, this object will be garbage collected. If this object is managed by a controller, then an entry in this list will point to this controller, with the controller field set to true. There cannot be more than one managing controller. +optional +patchMergeKey=uid +patchStrategy=merge
   * @type {Array<V1OwnerReference>}
   * @memberof V1alpha1Workflow
   */
  ownerReferences?: Array<V1OwnerReference>
  /**
   * An opaque value that represents the internal version of this object that can be used by clients to determine when objects have changed. May be used for optimistic concurrency, change detection, and the watch operation on a resource or set of resources. Clients must treat these values as opaque and passed unmodified back to the server. They may only be valid for a particular resource or set of resources.  Populated by the system. Read-only. Value must be treated as opaque by clients and . More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  resourceVersion?: string
  /**
   * SelfLink is a URL representing this object. Populated by the system. Read-only.  DEPRECATED Kubernetes will stop propagating this field in 1.20 release and the field is planned to be removed in 1.21 release. +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  selfLink?: string
  /**
   *
   * @type {V1alpha1WorkflowSpec}
   * @memberof V1alpha1Workflow
   */
  spec?: V1alpha1WorkflowSpec
  /**
   *
   * @type {V1alpha1WorkflowStatus}
   * @memberof V1alpha1Workflow
   */
  status?: V1alpha1WorkflowStatus
  /**
   * UID is the unique in time and space value for this object. It is typically generated by the server on successful creation of a resource and is not allowed to change on PUT operations.  Populated by the system. Read-only. More info: http://kubernetes.io/docs/user-guide/identifiers#uids +optional
   * @type {string}
   * @memberof V1alpha1Workflow
   */
  uid?: string
}
/**
 *
 * @export
 * @interface V1alpha1WorkflowCondition
 */
export interface V1alpha1WorkflowCondition {
  /**
   *
   * @type {string}
   * @memberof V1alpha1WorkflowCondition
   */
  reason?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1WorkflowCondition
   */
  startTime?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1WorkflowCondition
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof V1alpha1WorkflowCondition
   */
  type?: string
}
/**
 *
 * @export
 * @interface V1alpha1WorkflowSpec
 */
export interface V1alpha1WorkflowSpec {
  /**
   *
   * @type {string}
   * @memberof V1alpha1WorkflowSpec
   */
  entry?: string
  /**
   *
   * @type {Array<V1alpha1Template>}
   * @memberof V1alpha1WorkflowSpec
   */
  templates?: Array<V1alpha1Template>
}
/**
 *
 * @export
 * @interface V1alpha1WorkflowStatus
 */
export interface V1alpha1WorkflowStatus {
  /**
   * Represents the latest available observations of a workflow\'s current state. +optional +patchMergeKey=type +patchStrategy=merge
   * @type {Array<V1alpha1WorkflowCondition>}
   * @memberof V1alpha1WorkflowStatus
   */
  conditions?: Array<V1alpha1WorkflowCondition>
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1WorkflowStatus
   */
  endTime?: string
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1WorkflowStatus
   */
  entryNode?: string
  /**
   * +optional
   * @type {string}
   * @memberof V1alpha1WorkflowStatus
   */
  startTime?: string
}

/**
 * ArchivesApi - axios parameter creator
 * @export
 */
export const ArchivesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesDelete: async (uids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uids' is not null or undefined
      assertParamExists('archivesDelete', 'uids', uids)
      const localVarPath = `/archives`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (uids !== undefined) {
        localVarQueryParameter['uids'] = uids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get archived chaos experiments.
     * @summary Get archived chaos experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos'} [kind] kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesGet: async (
      namespace?: string,
      name?: string,
      kind?: 'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/archives`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesDelete: async (uids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uids' is not null or undefined
      assertParamExists('archivesSchedulesDelete', 'uids', uids)
      const localVarPath = `/archives/schedules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (uids !== undefined) {
        localVarQueryParameter['uids'] = uids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get archived schedule experiments.
     * @summary Get archived schedule experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesGet: async (
      namespace?: string,
      name?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/archives/schedules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesUidDelete: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesSchedulesUidDelete', 'uid', uid)
      const localVarPath = `/archives/schedules/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the detail of an archived schedule experiment.
     * @summary Get the detail of an archived schedule experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesSchedulesUidGet', 'uid', uid)
      const localVarPath = `/archives/schedules/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesUidDelete: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesUidDelete', 'uid', uid)
      const localVarPath = `/archives/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the archived chaos experiment\'s detail by uid.
     * @summary Get an archived chaos experiment.
     * @param {string} uid the archive uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesUidGet', 'uid', uid)
      const localVarPath = `/archives/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified archived workflows.
     * @summary Delete the specified archived workflows.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsDelete: async (uids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uids' is not null or undefined
      assertParamExists('archivesWorkflowsDelete', 'uids', uids)
      const localVarPath = `/archives/workflows`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (uids !== undefined) {
        localVarQueryParameter['uids'] = uids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get archived workflow.
     * @summary Get archived workflow.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsGet: async (
      namespace?: string,
      name?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/archives/workflows`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified archived workflow.
     * @summary Delete the specified archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsUidDelete: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesWorkflowsUidDelete', 'uid', uid)
      const localVarPath = `/archives/workflows/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the detail of an archived workflow.
     * @summary Get the detail of an archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('archivesWorkflowsUidGet', 'uid', uid)
      const localVarPath = `/archives/workflows/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArchivesApi - functional programming interface
 * @export
 */
export const ArchivesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArchivesApiAxiosParamCreator(configuration)
  return {
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesDelete(
      uids: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesDelete(uids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get archived chaos experiments.
     * @summary Get archived chaos experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos'} [kind] kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesGet(
      namespace?: string,
      name?: string,
      kind?: 'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesArchive>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesGet(namespace, name, kind, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesSchedulesDelete(
      uids: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesSchedulesDelete(uids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get archived schedule experiments.
     * @summary Get archived schedule experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesSchedulesGet(
      namespace?: string,
      name?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesArchive>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesSchedulesGet(namespace, name, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesSchedulesUidDelete(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesSchedulesUidDelete(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the detail of an archived schedule experiment.
     * @summary Get the detail of an archived schedule experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesSchedulesUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesArchiveDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesSchedulesUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesUidDelete(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesUidDelete(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the archived chaos experiment\'s detail by uid.
     * @summary Get an archived chaos experiment.
     * @param {string} uid the archive uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesArchiveDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified archived workflows.
     * @summary Delete the specified archived workflows.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesWorkflowsDelete(
      uids: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesWorkflowsDelete(uids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get archived workflow.
     * @summary Get archived workflow.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesWorkflowsGet(
      namespace?: string,
      name?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesArchive>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesWorkflowsGet(namespace, name, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified archived workflow.
     * @summary Delete the specified archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesWorkflowsUidDelete(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesWorkflowsUidDelete(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the detail of an archived workflow.
     * @summary Get the detail of an archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archivesWorkflowsUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesArchiveDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archivesWorkflowsUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ArchivesApi - factory interface
 * @export
 */
export const ArchivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArchivesApiFp(configuration)
  return {
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesDelete(uids: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesDelete(uids, options).then((request) => request(axios, basePath))
    },
    /**
     * Get archived chaos experiments.
     * @summary Get archived chaos experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos'} [kind] kind
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesGet(
      namespace?: string,
      name?: string,
      kind?: 'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos',
      options?: any
    ): AxiosPromise<Array<TypesArchive>> {
      return localVarFp.archivesGet(namespace, name, kind, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesDelete(uids: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesSchedulesDelete(uids, options).then((request) => request(axios, basePath))
    },
    /**
     * Get archived schedule experiments.
     * @summary Get archived schedule experiments.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesGet(namespace?: string, name?: string, options?: any): AxiosPromise<Array<TypesArchive>> {
      return localVarFp.archivesSchedulesGet(namespace, name, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified archived schedule.
     * @summary Delete the specified archived schedule.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesUidDelete(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesSchedulesUidDelete(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the detail of an archived schedule experiment.
     * @summary Get the detail of an archived schedule experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesSchedulesUidGet(uid: string, options?: any): AxiosPromise<TypesArchiveDetail> {
      return localVarFp.archivesSchedulesUidGet(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified archived experiment.
     * @summary Delete the specified archived experiment.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesUidDelete(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesUidDelete(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the archived chaos experiment\'s detail by uid.
     * @summary Get an archived chaos experiment.
     * @param {string} uid the archive uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesUidGet(uid: string, options?: any): AxiosPromise<TypesArchiveDetail> {
      return localVarFp.archivesUidGet(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified archived workflows.
     * @summary Delete the specified archived workflows.
     * @param {string} uids uids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsDelete(uids: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesWorkflowsDelete(uids, options).then((request) => request(axios, basePath))
    },
    /**
     * Get archived workflow.
     * @summary Get archived workflow.
     * @param {string} [namespace] namespace
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsGet(namespace?: string, name?: string, options?: any): AxiosPromise<Array<TypesArchive>> {
      return localVarFp.archivesWorkflowsGet(namespace, name, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified archived workflow.
     * @summary Delete the specified archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsUidDelete(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.archivesWorkflowsUidDelete(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the detail of an archived workflow.
     * @summary Get the detail of an archived workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archivesWorkflowsUidGet(uid: string, options?: any): AxiosPromise<TypesArchiveDetail> {
      return localVarFp.archivesWorkflowsUidGet(uid, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for archivesDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesDeleteRequest
 */
export interface ArchivesApiArchivesDeleteRequest {
  /**
   * uids
   * @type {string}
   * @memberof ArchivesApiArchivesDelete
   */
  readonly uids: string
}

/**
 * Request parameters for archivesGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesGetRequest
 */
export interface ArchivesApiArchivesGetRequest {
  /**
   * namespace
   * @type {string}
   * @memberof ArchivesApiArchivesGet
   */
  readonly namespace?: string

  /**
   * name
   * @type {string}
   * @memberof ArchivesApiArchivesGet
   */
  readonly name?: string

  /**
   * kind
   * @type {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos'}
   * @memberof ArchivesApiArchivesGet
   */
  readonly kind?: 'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos'
}

/**
 * Request parameters for archivesSchedulesDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesSchedulesDeleteRequest
 */
export interface ArchivesApiArchivesSchedulesDeleteRequest {
  /**
   * uids
   * @type {string}
   * @memberof ArchivesApiArchivesSchedulesDelete
   */
  readonly uids: string
}

/**
 * Request parameters for archivesSchedulesGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesSchedulesGetRequest
 */
export interface ArchivesApiArchivesSchedulesGetRequest {
  /**
   * namespace
   * @type {string}
   * @memberof ArchivesApiArchivesSchedulesGet
   */
  readonly namespace?: string

  /**
   * name
   * @type {string}
   * @memberof ArchivesApiArchivesSchedulesGet
   */
  readonly name?: string
}

/**
 * Request parameters for archivesSchedulesUidDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesSchedulesUidDeleteRequest
 */
export interface ArchivesApiArchivesSchedulesUidDeleteRequest {
  /**
   * uid
   * @type {string}
   * @memberof ArchivesApiArchivesSchedulesUidDelete
   */
  readonly uid: string
}

/**
 * Request parameters for archivesSchedulesUidGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesSchedulesUidGetRequest
 */
export interface ArchivesApiArchivesSchedulesUidGetRequest {
  /**
   * uid
   * @type {string}
   * @memberof ArchivesApiArchivesSchedulesUidGet
   */
  readonly uid: string
}

/**
 * Request parameters for archivesUidDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesUidDeleteRequest
 */
export interface ArchivesApiArchivesUidDeleteRequest {
  /**
   * uid
   * @type {string}
   * @memberof ArchivesApiArchivesUidDelete
   */
  readonly uid: string
}

/**
 * Request parameters for archivesUidGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesUidGetRequest
 */
export interface ArchivesApiArchivesUidGetRequest {
  /**
   * the archive uid
   * @type {string}
   * @memberof ArchivesApiArchivesUidGet
   */
  readonly uid: string
}

/**
 * Request parameters for archivesWorkflowsDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesWorkflowsDeleteRequest
 */
export interface ArchivesApiArchivesWorkflowsDeleteRequest {
  /**
   * uids
   * @type {string}
   * @memberof ArchivesApiArchivesWorkflowsDelete
   */
  readonly uids: string
}

/**
 * Request parameters for archivesWorkflowsGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesWorkflowsGetRequest
 */
export interface ArchivesApiArchivesWorkflowsGetRequest {
  /**
   * namespace
   * @type {string}
   * @memberof ArchivesApiArchivesWorkflowsGet
   */
  readonly namespace?: string

  /**
   * name
   * @type {string}
   * @memberof ArchivesApiArchivesWorkflowsGet
   */
  readonly name?: string
}

/**
 * Request parameters for archivesWorkflowsUidDelete operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesWorkflowsUidDeleteRequest
 */
export interface ArchivesApiArchivesWorkflowsUidDeleteRequest {
  /**
   * uid
   * @type {string}
   * @memberof ArchivesApiArchivesWorkflowsUidDelete
   */
  readonly uid: string
}

/**
 * Request parameters for archivesWorkflowsUidGet operation in ArchivesApi.
 * @export
 * @interface ArchivesApiArchivesWorkflowsUidGetRequest
 */
export interface ArchivesApiArchivesWorkflowsUidGetRequest {
  /**
   * uid
   * @type {string}
   * @memberof ArchivesApiArchivesWorkflowsUidGet
   */
  readonly uid: string
}

/**
 * ArchivesApi - object-oriented interface
 * @export
 * @class ArchivesApi
 * @extends {BaseAPI}
 */
export class ArchivesApi extends BaseAPI {
  /**
   * Delete the specified archived experiment.
   * @summary Delete the specified archived experiment.
   * @param {ArchivesApiArchivesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesDelete(requestParameters: ArchivesApiArchivesDeleteRequest, options?: AxiosRequestConfig) {
    return ArchivesApiFp(this.configuration)
      .archivesDelete(requestParameters.uids, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get archived chaos experiments.
   * @summary Get archived chaos experiments.
   * @param {ArchivesApiArchivesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesGet(requestParameters: ArchivesApiArchivesGetRequest = {}, options?: AxiosRequestConfig) {
    return ArchivesApiFp(this.configuration)
      .archivesGet(requestParameters.namespace, requestParameters.name, requestParameters.kind, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified archived schedule.
   * @summary Delete the specified archived schedule.
   * @param {ArchivesApiArchivesSchedulesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesSchedulesDelete(
    requestParameters: ArchivesApiArchivesSchedulesDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesSchedulesDelete(requestParameters.uids, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get archived schedule experiments.
   * @summary Get archived schedule experiments.
   * @param {ArchivesApiArchivesSchedulesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesSchedulesGet(
    requestParameters: ArchivesApiArchivesSchedulesGetRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesSchedulesGet(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified archived schedule.
   * @summary Delete the specified archived schedule.
   * @param {ArchivesApiArchivesSchedulesUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesSchedulesUidDelete(
    requestParameters: ArchivesApiArchivesSchedulesUidDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesSchedulesUidDelete(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the detail of an archived schedule experiment.
   * @summary Get the detail of an archived schedule experiment.
   * @param {ArchivesApiArchivesSchedulesUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesSchedulesUidGet(
    requestParameters: ArchivesApiArchivesSchedulesUidGetRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesSchedulesUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified archived experiment.
   * @summary Delete the specified archived experiment.
   * @param {ArchivesApiArchivesUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesUidDelete(requestParameters: ArchivesApiArchivesUidDeleteRequest, options?: AxiosRequestConfig) {
    return ArchivesApiFp(this.configuration)
      .archivesUidDelete(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the archived chaos experiment\'s detail by uid.
   * @summary Get an archived chaos experiment.
   * @param {ArchivesApiArchivesUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesUidGet(requestParameters: ArchivesApiArchivesUidGetRequest, options?: AxiosRequestConfig) {
    return ArchivesApiFp(this.configuration)
      .archivesUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified archived workflows.
   * @summary Delete the specified archived workflows.
   * @param {ArchivesApiArchivesWorkflowsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesWorkflowsDelete(
    requestParameters: ArchivesApiArchivesWorkflowsDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesWorkflowsDelete(requestParameters.uids, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get archived workflow.
   * @summary Get archived workflow.
   * @param {ArchivesApiArchivesWorkflowsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesWorkflowsGet(
    requestParameters: ArchivesApiArchivesWorkflowsGetRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesWorkflowsGet(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified archived workflow.
   * @summary Delete the specified archived workflow.
   * @param {ArchivesApiArchivesWorkflowsUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesWorkflowsUidDelete(
    requestParameters: ArchivesApiArchivesWorkflowsUidDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesWorkflowsUidDelete(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the detail of an archived workflow.
   * @summary Get the detail of an archived workflow.
   * @param {ArchivesApiArchivesWorkflowsUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArchivesApi
   */
  public archivesWorkflowsUidGet(
    requestParameters: ArchivesApiArchivesWorkflowsUidGetRequest,
    options?: AxiosRequestConfig
  ) {
    return ArchivesApiFp(this.configuration)
      .archivesWorkflowsUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonAnnotationsGet: async (podNamespaceList: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'podNamespaceList' is not null or undefined
      assertParamExists('commonAnnotationsGet', 'podNamespaceList', podNamespaceList)
      const localVarPath = `/common/annotations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (podNamespaceList !== undefined) {
        localVarQueryParameter['podNamespaceList'] = podNamespaceList
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @summary Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonChaosAvailableNamespacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/common/chaos-available-namespaces`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the config of Dashboard.
     * @summary Get the config of Dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/common/config`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all chaos kinds from Kubernetes cluster.
     * @summary Get all chaos kinds from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonKindsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/common/kinds`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonLabelsGet: async (podNamespaceList: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'podNamespaceList' is not null or undefined
      assertParamExists('commonLabelsGet', 'podNamespaceList', podNamespaceList)
      const localVarPath = `/common/labels`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (podNamespaceList !== undefined) {
        localVarQueryParameter['podNamespaceList'] = podNamespaceList
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get all from Kubernetes cluster.
     * @summary Get all namespaces from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    commonNamespacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/common/namespaces`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachineAnnotationsGet: async (
      physicalMachineNamespaceList: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'physicalMachineNamespaceList' is not null or undefined
      assertParamExists(
        'commonPhysicalmachineAnnotationsGet',
        'physicalMachineNamespaceList',
        physicalMachineNamespaceList
      )
      const localVarPath = `/common/physicalmachine-annotations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (physicalMachineNamespaceList !== undefined) {
        localVarQueryParameter['physicalMachineNamespaceList'] = physicalMachineNamespaceList
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachineLabelsGet: async (
      physicalMachineNamespaceList: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'physicalMachineNamespaceList' is not null or undefined
      assertParamExists('commonPhysicalmachineLabelsGet', 'physicalMachineNamespaceList', physicalMachineNamespaceList)
      const localVarPath = `/common/physicalmachine-labels`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (physicalMachineNamespaceList !== undefined) {
        localVarQueryParameter['physicalMachineNamespaceList'] = physicalMachineNamespaceList
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get physicalMachines from Kubernetes cluster.
     * @summary Get physicalMachines from Kubernetes cluster.
     * @param {V1alpha1PhysicalMachineSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachinesPost: async (
      request: V1alpha1PhysicalMachineSelectorSpec,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('commonPhysicalmachinesPost', 'request', request)
      const localVarPath = `/common/physicalmachines`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get pods from Kubernetes cluster.
     * @summary Get pods from Kubernetes cluster.
     * @param {V1alpha1PodSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPodsPost: async (
      request: V1alpha1PodSelectorSpec,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('commonPodsPost', 'request', request)
      const localVarPath = `/common/pods`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the rbac config according to the user\'s choice.
     * @summary Get the rbac config according to the user\'s choice.
     * @param {string} [namespace] The namespace of RBAC
     * @param {string} [role] The role of RBAC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonRbacConfigGet: async (
      namespace?: string,
      role?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/common/rbac-config`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (role !== undefined) {
        localVarQueryParameter['role'] = role
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonAnnotationsGet(
      podNamespaceList: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string> }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonAnnotationsGet(podNamespaceList, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @summary Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonChaosAvailableNamespacesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonChaosAvailableNamespacesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the config of Dashboard.
     * @summary Get the config of Dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonConfigGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigChaosDashboardConfig>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonConfigGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get all chaos kinds from Kubernetes cluster.
     * @summary Get all chaos kinds from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonKindsGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonKindsGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonLabelsGet(
      podNamespaceList: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string> }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonLabelsGet(podNamespaceList, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get all from Kubernetes cluster.
     * @summary Get all namespaces from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async commonNamespacesGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonNamespacesGet(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonPhysicalmachineAnnotationsGet(
      physicalMachineNamespaceList: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string> }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonPhysicalmachineAnnotationsGet(
        physicalMachineNamespaceList,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonPhysicalmachineLabelsGet(
      physicalMachineNamespaceList: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string> }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonPhysicalmachineLabelsGet(
        physicalMachineNamespaceList,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get physicalMachines from Kubernetes cluster.
     * @summary Get physicalMachines from Kubernetes cluster.
     * @param {V1alpha1PhysicalMachineSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonPhysicalmachinesPost(
      request: V1alpha1PhysicalMachineSelectorSpec,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesPhysicalMachine>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonPhysicalmachinesPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get pods from Kubernetes cluster.
     * @summary Get pods from Kubernetes cluster.
     * @param {V1alpha1PodSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonPodsPost(
      request: V1alpha1PodSelectorSpec,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesPod>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonPodsPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the rbac config according to the user\'s choice.
     * @summary Get the rbac config according to the user\'s choice.
     * @param {string} [namespace] The namespace of RBAC
     * @param {string} [role] The role of RBAC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonRbacConfigGet(
      namespace?: string,
      role?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commonRbacConfigGet(namespace, role, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CommonApiFp(configuration)
  return {
    /**
     * Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonAnnotationsGet(podNamespaceList: string, options?: any): AxiosPromise<{ [key: string]: Array<string> }> {
      return localVarFp.commonAnnotationsGet(podNamespaceList, options).then((request) => request(axios, basePath))
    },
    /**
     * Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @summary Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonChaosAvailableNamespacesGet(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.commonChaosAvailableNamespacesGet(options).then((request) => request(axios, basePath))
    },
    /**
     * Get the config of Dashboard.
     * @summary Get the config of Dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonConfigGet(options?: any): AxiosPromise<ConfigChaosDashboardConfig> {
      return localVarFp.commonConfigGet(options).then((request) => request(axios, basePath))
    },
    /**
     * Get all chaos kinds from Kubernetes cluster.
     * @summary Get all chaos kinds from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonKindsGet(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.commonKindsGet(options).then((request) => request(axios, basePath))
    },
    /**
     * Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the pods in the specified namespace from Kubernetes cluster.
     * @param {string} podNamespaceList The pod\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonLabelsGet(podNamespaceList: string, options?: any): AxiosPromise<{ [key: string]: Array<string> }> {
      return localVarFp.commonLabelsGet(podNamespaceList, options).then((request) => request(axios, basePath))
    },
    /**
     * Get all from Kubernetes cluster.
     * @summary Get all namespaces from Kubernetes cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    commonNamespacesGet(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.commonNamespacesGet(options).then((request) => request(axios, basePath))
    },
    /**
     * Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachineAnnotationsGet(
      physicalMachineNamespaceList: string,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<string> }> {
      return localVarFp
        .commonPhysicalmachineAnnotationsGet(physicalMachineNamespaceList, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @summary Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
     * @param {string} physicalMachineNamespaceList The physicalMachine\&#39;s namespace list, split by ,
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachineLabelsGet(
      physicalMachineNamespaceList: string,
      options?: any
    ): AxiosPromise<{ [key: string]: Array<string> }> {
      return localVarFp
        .commonPhysicalmachineLabelsGet(physicalMachineNamespaceList, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get physicalMachines from Kubernetes cluster.
     * @summary Get physicalMachines from Kubernetes cluster.
     * @param {V1alpha1PhysicalMachineSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPhysicalmachinesPost(
      request: V1alpha1PhysicalMachineSelectorSpec,
      options?: any
    ): AxiosPromise<Array<TypesPhysicalMachine>> {
      return localVarFp.commonPhysicalmachinesPost(request, options).then((request) => request(axios, basePath))
    },
    /**
     * Get pods from Kubernetes cluster.
     * @summary Get pods from Kubernetes cluster.
     * @param {V1alpha1PodSelectorSpec} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonPodsPost(request: V1alpha1PodSelectorSpec, options?: any): AxiosPromise<Array<TypesPod>> {
      return localVarFp.commonPodsPost(request, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the rbac config according to the user\'s choice.
     * @summary Get the rbac config according to the user\'s choice.
     * @param {string} [namespace] The namespace of RBAC
     * @param {string} [role] The role of RBAC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonRbacConfigGet(namespace?: string, role?: string, options?: any): AxiosPromise<{ [key: string]: string }> {
      return localVarFp.commonRbacConfigGet(namespace, role, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for commonAnnotationsGet operation in CommonApi.
 * @export
 * @interface CommonApiCommonAnnotationsGetRequest
 */
export interface CommonApiCommonAnnotationsGetRequest {
  /**
   * The pod\&#39;s namespace list, split by ,
   * @type {string}
   * @memberof CommonApiCommonAnnotationsGet
   */
  readonly podNamespaceList: string
}

/**
 * Request parameters for commonLabelsGet operation in CommonApi.
 * @export
 * @interface CommonApiCommonLabelsGetRequest
 */
export interface CommonApiCommonLabelsGetRequest {
  /**
   * The pod\&#39;s namespace list, split by ,
   * @type {string}
   * @memberof CommonApiCommonLabelsGet
   */
  readonly podNamespaceList: string
}

/**
 * Request parameters for commonPhysicalmachineAnnotationsGet operation in CommonApi.
 * @export
 * @interface CommonApiCommonPhysicalmachineAnnotationsGetRequest
 */
export interface CommonApiCommonPhysicalmachineAnnotationsGetRequest {
  /**
   * The physicalMachine\&#39;s namespace list, split by ,
   * @type {string}
   * @memberof CommonApiCommonPhysicalmachineAnnotationsGet
   */
  readonly physicalMachineNamespaceList: string
}

/**
 * Request parameters for commonPhysicalmachineLabelsGet operation in CommonApi.
 * @export
 * @interface CommonApiCommonPhysicalmachineLabelsGetRequest
 */
export interface CommonApiCommonPhysicalmachineLabelsGetRequest {
  /**
   * The physicalMachine\&#39;s namespace list, split by ,
   * @type {string}
   * @memberof CommonApiCommonPhysicalmachineLabelsGet
   */
  readonly physicalMachineNamespaceList: string
}

/**
 * Request parameters for commonPhysicalmachinesPost operation in CommonApi.
 * @export
 * @interface CommonApiCommonPhysicalmachinesPostRequest
 */
export interface CommonApiCommonPhysicalmachinesPostRequest {
  /**
   * Request body
   * @type {V1alpha1PhysicalMachineSelectorSpec}
   * @memberof CommonApiCommonPhysicalmachinesPost
   */
  readonly request: V1alpha1PhysicalMachineSelectorSpec
}

/**
 * Request parameters for commonPodsPost operation in CommonApi.
 * @export
 * @interface CommonApiCommonPodsPostRequest
 */
export interface CommonApiCommonPodsPostRequest {
  /**
   * Request body
   * @type {V1alpha1PodSelectorSpec}
   * @memberof CommonApiCommonPodsPost
   */
  readonly request: V1alpha1PodSelectorSpec
}

/**
 * Request parameters for commonRbacConfigGet operation in CommonApi.
 * @export
 * @interface CommonApiCommonRbacConfigGetRequest
 */
export interface CommonApiCommonRbacConfigGetRequest {
  /**
   * The namespace of RBAC
   * @type {string}
   * @memberof CommonApiCommonRbacConfigGet
   */
  readonly namespace?: string

  /**
   * The role of RBAC
   * @type {string}
   * @memberof CommonApiCommonRbacConfigGet
   */
  readonly role?: string
}

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
  /**
   * Get the annotations of the pods in the specified namespace from Kubernetes cluster.
   * @summary Get the annotations of the pods in the specified namespace from Kubernetes cluster.
   * @param {CommonApiCommonAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonAnnotationsGet(requestParameters: CommonApiCommonAnnotationsGetRequest, options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonAnnotationsGet(requestParameters.podNamespaceList, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
   * @summary Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonChaosAvailableNamespacesGet(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonChaosAvailableNamespacesGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the config of Dashboard.
   * @summary Get the config of Dashboard.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonConfigGet(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonConfigGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all chaos kinds from Kubernetes cluster.
   * @summary Get all chaos kinds from Kubernetes cluster.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonKindsGet(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonKindsGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the labels of the pods in the specified namespace from Kubernetes cluster.
   * @summary Get the labels of the pods in the specified namespace from Kubernetes cluster.
   * @param {CommonApiCommonLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonLabelsGet(requestParameters: CommonApiCommonLabelsGetRequest, options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonLabelsGet(requestParameters.podNamespaceList, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get all from Kubernetes cluster.
   * @summary Get all namespaces from Kubernetes cluster.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonNamespacesGet(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonNamespacesGet(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
   * @summary Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
   * @param {CommonApiCommonPhysicalmachineAnnotationsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonPhysicalmachineAnnotationsGet(
    requestParameters: CommonApiCommonPhysicalmachineAnnotationsGetRequest,
    options?: AxiosRequestConfig
  ) {
    return CommonApiFp(this.configuration)
      .commonPhysicalmachineAnnotationsGet(requestParameters.physicalMachineNamespaceList, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
   * @summary Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
   * @param {CommonApiCommonPhysicalmachineLabelsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonPhysicalmachineLabelsGet(
    requestParameters: CommonApiCommonPhysicalmachineLabelsGetRequest,
    options?: AxiosRequestConfig
  ) {
    return CommonApiFp(this.configuration)
      .commonPhysicalmachineLabelsGet(requestParameters.physicalMachineNamespaceList, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get physicalMachines from Kubernetes cluster.
   * @summary Get physicalMachines from Kubernetes cluster.
   * @param {CommonApiCommonPhysicalmachinesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonPhysicalmachinesPost(
    requestParameters: CommonApiCommonPhysicalmachinesPostRequest,
    options?: AxiosRequestConfig
  ) {
    return CommonApiFp(this.configuration)
      .commonPhysicalmachinesPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get pods from Kubernetes cluster.
   * @summary Get pods from Kubernetes cluster.
   * @param {CommonApiCommonPodsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonPodsPost(requestParameters: CommonApiCommonPodsPostRequest, options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonPodsPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the rbac config according to the user\'s choice.
   * @summary Get the rbac config according to the user\'s choice.
   * @param {CommonApiCommonRbacConfigGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonRbacConfigGet(
    requestParameters: CommonApiCommonRbacConfigGetRequest = {},
    options?: AxiosRequestConfig
  ) {
    return CommonApiFp(this.configuration)
      .commonRbacConfigGet(requestParameters.namespace, requestParameters.role, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get events from db.
     * @summary list events.
     * @param {string} [createdAt] The create time of events
     * @param {string} [name] The name of the object
     * @param {string} [namespace] The namespace of the object
     * @param {string} [objectId] The UID of the object
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos' | 'AWSChaos' | 'GCPChaos' | 'DNSChaos' | 'Schedule'} [kind] kind
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsGet: async (
      createdAt?: string,
      name?: string,
      namespace?: string,
      objectId?: string,
      kind?:
        | 'PodChaos'
        | 'IOChaos'
        | 'NetworkChaos'
        | 'TimeChaos'
        | 'KernelChaos'
        | 'StressChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'DNSChaos'
        | 'Schedule',
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/events`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (createdAt !== undefined) {
        localVarQueryParameter['created_at'] = createdAt
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (objectId !== undefined) {
        localVarQueryParameter['object_id'] = objectId
      }

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the event from db by ID.
     * @summary Get an event.
     * @param {number} id The event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventsIdGet', 'id', id)
      const localVarPath = `/events/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * list all events for Workflow and related WorkflowNode.
     * @summary cascadeFetchEventsForWorkflow list all events for Workflow and related WorkflowNode.
     * @param {string} uid The UID of the Workflow
     * @param {string} [namespace] The namespace of the object
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsWorkflowUidGet: async (
      uid: string,
      namespace?: string,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('eventsWorkflowUidGet', 'uid', uid)
      const localVarPath = `/events/workflow/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
  return {
    /**
     * Get events from db.
     * @summary list events.
     * @param {string} [createdAt] The create time of events
     * @param {string} [name] The name of the object
     * @param {string} [namespace] The namespace of the object
     * @param {string} [objectId] The UID of the object
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos' | 'AWSChaos' | 'GCPChaos' | 'DNSChaos' | 'Schedule'} [kind] kind
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsGet(
      createdAt?: string,
      name?: string,
      namespace?: string,
      objectId?: string,
      kind?:
        | 'PodChaos'
        | 'IOChaos'
        | 'NetworkChaos'
        | 'TimeChaos'
        | 'KernelChaos'
        | 'StressChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'DNSChaos'
        | 'Schedule',
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoreEvent>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventsGet(
        createdAt,
        name,
        namespace,
        objectId,
        kind,
        limit,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the event from db by ID.
     * @summary Get an event.
     * @param {number} id The event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsIdGet(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventsIdGet(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * list all events for Workflow and related WorkflowNode.
     * @summary cascadeFetchEventsForWorkflow list all events for Workflow and related WorkflowNode.
     * @param {string} uid The UID of the Workflow
     * @param {string} [namespace] The namespace of the object
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventsWorkflowUidGet(
      uid: string,
      namespace?: string,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoreEvent>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventsWorkflowUidGet(uid, namespace, limit, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EventsApiFp(configuration)
  return {
    /**
     * Get events from db.
     * @summary list events.
     * @param {string} [createdAt] The create time of events
     * @param {string} [name] The name of the object
     * @param {string} [namespace] The namespace of the object
     * @param {string} [objectId] The UID of the object
     * @param {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos' | 'AWSChaos' | 'GCPChaos' | 'DNSChaos' | 'Schedule'} [kind] kind
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsGet(
      createdAt?: string,
      name?: string,
      namespace?: string,
      objectId?: string,
      kind?:
        | 'PodChaos'
        | 'IOChaos'
        | 'NetworkChaos'
        | 'TimeChaos'
        | 'KernelChaos'
        | 'StressChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'DNSChaos'
        | 'Schedule',
      limit?: number,
      options?: any
    ): AxiosPromise<Array<CoreEvent>> {
      return localVarFp
        .eventsGet(createdAt, name, namespace, objectId, kind, limit, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the event from db by ID.
     * @summary Get an event.
     * @param {number} id The event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsIdGet(id: number, options?: any): AxiosPromise<CoreEvent> {
      return localVarFp.eventsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     * list all events for Workflow and related WorkflowNode.
     * @summary cascadeFetchEventsForWorkflow list all events for Workflow and related WorkflowNode.
     * @param {string} uid The UID of the Workflow
     * @param {string} [namespace] The namespace of the object
     * @param {number} [limit] The max length of events list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventsWorkflowUidGet(
      uid: string,
      namespace?: string,
      limit?: number,
      options?: any
    ): AxiosPromise<Array<CoreEvent>> {
      return localVarFp.eventsWorkflowUidGet(uid, namespace, limit, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for eventsGet operation in EventsApi.
 * @export
 * @interface EventsApiEventsGetRequest
 */
export interface EventsApiEventsGetRequest {
  /**
   * The create time of events
   * @type {string}
   * @memberof EventsApiEventsGet
   */
  readonly createdAt?: string

  /**
   * The name of the object
   * @type {string}
   * @memberof EventsApiEventsGet
   */
  readonly name?: string

  /**
   * The namespace of the object
   * @type {string}
   * @memberof EventsApiEventsGet
   */
  readonly namespace?: string

  /**
   * The UID of the object
   * @type {string}
   * @memberof EventsApiEventsGet
   */
  readonly objectId?: string

  /**
   * kind
   * @type {'PodChaos' | 'IOChaos' | 'NetworkChaos' | 'TimeChaos' | 'KernelChaos' | 'StressChaos' | 'AWSChaos' | 'GCPChaos' | 'DNSChaos' | 'Schedule'}
   * @memberof EventsApiEventsGet
   */
  readonly kind?:
    | 'PodChaos'
    | 'IOChaos'
    | 'NetworkChaos'
    | 'TimeChaos'
    | 'KernelChaos'
    | 'StressChaos'
    | 'AWSChaos'
    | 'GCPChaos'
    | 'DNSChaos'
    | 'Schedule'

  /**
   * The max length of events list
   * @type {number}
   * @memberof EventsApiEventsGet
   */
  readonly limit?: number
}

/**
 * Request parameters for eventsIdGet operation in EventsApi.
 * @export
 * @interface EventsApiEventsIdGetRequest
 */
export interface EventsApiEventsIdGetRequest {
  /**
   * The event ID
   * @type {number}
   * @memberof EventsApiEventsIdGet
   */
  readonly id: number
}

/**
 * Request parameters for eventsWorkflowUidGet operation in EventsApi.
 * @export
 * @interface EventsApiEventsWorkflowUidGetRequest
 */
export interface EventsApiEventsWorkflowUidGetRequest {
  /**
   * The UID of the Workflow
   * @type {string}
   * @memberof EventsApiEventsWorkflowUidGet
   */
  readonly uid: string

  /**
   * The namespace of the object
   * @type {string}
   * @memberof EventsApiEventsWorkflowUidGet
   */
  readonly namespace?: string

  /**
   * The max length of events list
   * @type {number}
   * @memberof EventsApiEventsWorkflowUidGet
   */
  readonly limit?: number
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
  /**
   * Get events from db.
   * @summary list events.
   * @param {EventsApiEventsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public eventsGet(requestParameters: EventsApiEventsGetRequest = {}, options?: AxiosRequestConfig) {
    return EventsApiFp(this.configuration)
      .eventsGet(
        requestParameters.createdAt,
        requestParameters.name,
        requestParameters.namespace,
        requestParameters.objectId,
        requestParameters.kind,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the event from db by ID.
   * @summary Get an event.
   * @param {EventsApiEventsIdGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public eventsIdGet(requestParameters: EventsApiEventsIdGetRequest, options?: AxiosRequestConfig) {
    return EventsApiFp(this.configuration)
      .eventsIdGet(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * list all events for Workflow and related WorkflowNode.
   * @summary cascadeFetchEventsForWorkflow list all events for Workflow and related WorkflowNode.
   * @param {EventsApiEventsWorkflowUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventsApi
   */
  public eventsWorkflowUidGet(requestParameters: EventsApiEventsWorkflowUidGetRequest, options?: AxiosRequestConfig) {
    return EventsApiFp(this.configuration)
      .eventsWorkflowUidGet(requestParameters.uid, requestParameters.namespace, requestParameters.limit, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ExperimentsApi - axios parameter creator
 * @export
 */
export const ExperimentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Batch delete chaos experiments by uids.
     * @summary Batch delete chaos experiments.
     * @param {string} uids the experiment uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsDelete: async (
      uids: string,
      force?: 'true' | 'false',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uids' is not null or undefined
      assertParamExists('experimentsDelete', 'uids', uids)
      const localVarPath = `/experiments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (uids !== undefined) {
        localVarQueryParameter['uids'] = uids
      }

      if (force !== undefined) {
        localVarQueryParameter['force'] = force
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get chaos experiments from k8s clusters in real time.
     * @summary List chaos experiments.
     * @param {string} [namespace] filter exps by namespace
     * @param {string} [name] filter exps by name
     * @param {'PodChaos' | 'NetworkChaos' | 'IOChaos' | 'StressChaos' | 'KernelChaos' | 'TimeChaos' | 'DNSChaos' | 'AWSChaos' | 'GCPChaos' | 'JVMChaos' | 'HTTPChaos'} [kind] filter exps by kind
     * @param {'Injecting' | 'Running' | 'Finished' | 'Paused'} [status] filter exps by status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsGet: async (
      namespace?: string,
      name?: string,
      kind?:
        | 'PodChaos'
        | 'NetworkChaos'
        | 'IOChaos'
        | 'StressChaos'
        | 'KernelChaos'
        | 'TimeChaos'
        | 'DNSChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'JVMChaos'
        | 'HTTPChaos',
      status?: 'Injecting' | 'Running' | 'Finished' | 'Paused',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/experiments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Pause a chaos experiment.
     * @summary Pause a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsPauseUidPut: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('experimentsPauseUidPut', 'uid', uid)
      const localVarPath = `/experiments/pause/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Pass a JSON object to create a new chaos experiment. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new chaos experiment.
     * @param {object} chaos the chaos definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsPost: async (chaos: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chaos' is not null or undefined
      assertParamExists('experimentsPost', 'chaos', chaos)
      const localVarPath = `/experiments`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(chaos, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Start a chaos experiment.
     * @summary Start a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsStartUidPut: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('experimentsStartUidPut', 'uid', uid)
      const localVarPath = `/experiments/start/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the status of all experiments.
     * @summary Get the status of all experiments.
     * @param {string} [namespace] namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsStateGet: async (namespace?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/experiments/state`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the chaos experiment by uid.
     * @summary Delete a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsUidDelete: async (
      uid: string,
      force?: 'true' | 'false',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('experimentsUidDelete', 'uid', uid)
      const localVarPath = `/experiments/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (force !== undefined) {
        localVarQueryParameter['force'] = force
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the chaos experiment\'s detail by uid.
     * @summary Get a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('experimentsUidGet', 'uid', uid)
      const localVarPath = `/experiments/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ExperimentsApi - functional programming interface
 * @export
 */
export const ExperimentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExperimentsApiAxiosParamCreator(configuration)
  return {
    /**
     * Batch delete chaos experiments by uids.
     * @summary Batch delete chaos experiments.
     * @param {string} uids the experiment uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsDelete(
      uids: string,
      force?: 'true' | 'false',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsDelete(uids, force, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get chaos experiments from k8s clusters in real time.
     * @summary List chaos experiments.
     * @param {string} [namespace] filter exps by namespace
     * @param {string} [name] filter exps by name
     * @param {'PodChaos' | 'NetworkChaos' | 'IOChaos' | 'StressChaos' | 'KernelChaos' | 'TimeChaos' | 'DNSChaos' | 'AWSChaos' | 'GCPChaos' | 'JVMChaos' | 'HTTPChaos'} [kind] filter exps by kind
     * @param {'Injecting' | 'Running' | 'Finished' | 'Paused'} [status] filter exps by status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsGet(
      namespace?: string,
      name?: string,
      kind?:
        | 'PodChaos'
        | 'NetworkChaos'
        | 'IOChaos'
        | 'StressChaos'
        | 'KernelChaos'
        | 'TimeChaos'
        | 'DNSChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'JVMChaos'
        | 'HTTPChaos',
      status?: 'Injecting' | 'Running' | 'Finished' | 'Paused',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesExperiment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsGet(namespace, name, kind, status, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Pause a chaos experiment.
     * @summary Pause a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsPauseUidPut(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsPauseUidPut(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Pass a JSON object to create a new chaos experiment. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new chaos experiment.
     * @param {object} chaos the chaos definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsPost(
      chaos: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsPost(chaos, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Start a chaos experiment.
     * @summary Start a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsStartUidPut(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsStartUidPut(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the status of all experiments.
     * @summary Get the status of all experiments.
     * @param {string} [namespace] namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsStateGet(
      namespace?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusAllChaosStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsStateGet(namespace, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the chaos experiment by uid.
     * @summary Delete a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsUidDelete(
      uid: string,
      force?: 'true' | 'false',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsUidDelete(uid, force, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the chaos experiment\'s detail by uid.
     * @summary Get a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async experimentsUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesExperimentDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.experimentsUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ExperimentsApi - factory interface
 * @export
 */
export const ExperimentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ExperimentsApiFp(configuration)
  return {
    /**
     * Batch delete chaos experiments by uids.
     * @summary Batch delete chaos experiments.
     * @param {string} uids the experiment uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsDelete(uids: string, force?: 'true' | 'false', options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.experimentsDelete(uids, force, options).then((request) => request(axios, basePath))
    },
    /**
     * Get chaos experiments from k8s clusters in real time.
     * @summary List chaos experiments.
     * @param {string} [namespace] filter exps by namespace
     * @param {string} [name] filter exps by name
     * @param {'PodChaos' | 'NetworkChaos' | 'IOChaos' | 'StressChaos' | 'KernelChaos' | 'TimeChaos' | 'DNSChaos' | 'AWSChaos' | 'GCPChaos' | 'JVMChaos' | 'HTTPChaos'} [kind] filter exps by kind
     * @param {'Injecting' | 'Running' | 'Finished' | 'Paused'} [status] filter exps by status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsGet(
      namespace?: string,
      name?: string,
      kind?:
        | 'PodChaos'
        | 'NetworkChaos'
        | 'IOChaos'
        | 'StressChaos'
        | 'KernelChaos'
        | 'TimeChaos'
        | 'DNSChaos'
        | 'AWSChaos'
        | 'GCPChaos'
        | 'JVMChaos'
        | 'HTTPChaos',
      status?: 'Injecting' | 'Running' | 'Finished' | 'Paused',
      options?: any
    ): AxiosPromise<Array<TypesExperiment>> {
      return localVarFp
        .experimentsGet(namespace, name, kind, status, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Pause a chaos experiment.
     * @summary Pause a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsPauseUidPut(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.experimentsPauseUidPut(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Pass a JSON object to create a new chaos experiment. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new chaos experiment.
     * @param {object} chaos the chaos definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsPost(chaos: object, options?: any): AxiosPromise<object> {
      return localVarFp.experimentsPost(chaos, options).then((request) => request(axios, basePath))
    },
    /**
     * Start a chaos experiment.
     * @summary Start a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsStartUidPut(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.experimentsStartUidPut(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the status of all experiments.
     * @summary Get the status of all experiments.
     * @param {string} [namespace] namespace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsStateGet(namespace?: string, options?: any): AxiosPromise<StatusAllChaosStatus> {
      return localVarFp.experimentsStateGet(namespace, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the chaos experiment by uid.
     * @summary Delete a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {'true' | 'false'} [force] force
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsUidDelete(uid: string, force?: 'true' | 'false', options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.experimentsUidDelete(uid, force, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the chaos experiment\'s detail by uid.
     * @summary Get a chaos experiment.
     * @param {string} uid the experiment uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    experimentsUidGet(uid: string, options?: any): AxiosPromise<TypesExperimentDetail> {
      return localVarFp.experimentsUidGet(uid, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for experimentsDelete operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsDeleteRequest
 */
export interface ExperimentsApiExperimentsDeleteRequest {
  /**
   * the experiment uids, split with comma. Example: ?uids&#x3D;uid1,uid2
   * @type {string}
   * @memberof ExperimentsApiExperimentsDelete
   */
  readonly uids: string

  /**
   * force
   * @type {'true' | 'false'}
   * @memberof ExperimentsApiExperimentsDelete
   */
  readonly force?: 'true' | 'false'
}

/**
 * Request parameters for experimentsGet operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsGetRequest
 */
export interface ExperimentsApiExperimentsGetRequest {
  /**
   * filter exps by namespace
   * @type {string}
   * @memberof ExperimentsApiExperimentsGet
   */
  readonly namespace?: string

  /**
   * filter exps by name
   * @type {string}
   * @memberof ExperimentsApiExperimentsGet
   */
  readonly name?: string

  /**
   * filter exps by kind
   * @type {'PodChaos' | 'NetworkChaos' | 'IOChaos' | 'StressChaos' | 'KernelChaos' | 'TimeChaos' | 'DNSChaos' | 'AWSChaos' | 'GCPChaos' | 'JVMChaos' | 'HTTPChaos'}
   * @memberof ExperimentsApiExperimentsGet
   */
  readonly kind?:
    | 'PodChaos'
    | 'NetworkChaos'
    | 'IOChaos'
    | 'StressChaos'
    | 'KernelChaos'
    | 'TimeChaos'
    | 'DNSChaos'
    | 'AWSChaos'
    | 'GCPChaos'
    | 'JVMChaos'
    | 'HTTPChaos'

  /**
   * filter exps by status
   * @type {'Injecting' | 'Running' | 'Finished' | 'Paused'}
   * @memberof ExperimentsApiExperimentsGet
   */
  readonly status?: 'Injecting' | 'Running' | 'Finished' | 'Paused'
}

/**
 * Request parameters for experimentsPauseUidPut operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsPauseUidPutRequest
 */
export interface ExperimentsApiExperimentsPauseUidPutRequest {
  /**
   * the experiment uid
   * @type {string}
   * @memberof ExperimentsApiExperimentsPauseUidPut
   */
  readonly uid: string
}

/**
 * Request parameters for experimentsPost operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsPostRequest
 */
export interface ExperimentsApiExperimentsPostRequest {
  /**
   * the chaos definition
   * @type {object}
   * @memberof ExperimentsApiExperimentsPost
   */
  readonly chaos: object
}

/**
 * Request parameters for experimentsStartUidPut operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsStartUidPutRequest
 */
export interface ExperimentsApiExperimentsStartUidPutRequest {
  /**
   * the experiment uid
   * @type {string}
   * @memberof ExperimentsApiExperimentsStartUidPut
   */
  readonly uid: string
}

/**
 * Request parameters for experimentsStateGet operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsStateGetRequest
 */
export interface ExperimentsApiExperimentsStateGetRequest {
  /**
   * namespace
   * @type {string}
   * @memberof ExperimentsApiExperimentsStateGet
   */
  readonly namespace?: string
}

/**
 * Request parameters for experimentsUidDelete operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsUidDeleteRequest
 */
export interface ExperimentsApiExperimentsUidDeleteRequest {
  /**
   * the experiment uid
   * @type {string}
   * @memberof ExperimentsApiExperimentsUidDelete
   */
  readonly uid: string

  /**
   * force
   * @type {'true' | 'false'}
   * @memberof ExperimentsApiExperimentsUidDelete
   */
  readonly force?: 'true' | 'false'
}

/**
 * Request parameters for experimentsUidGet operation in ExperimentsApi.
 * @export
 * @interface ExperimentsApiExperimentsUidGetRequest
 */
export interface ExperimentsApiExperimentsUidGetRequest {
  /**
   * the experiment uid
   * @type {string}
   * @memberof ExperimentsApiExperimentsUidGet
   */
  readonly uid: string
}

/**
 * ExperimentsApi - object-oriented interface
 * @export
 * @class ExperimentsApi
 * @extends {BaseAPI}
 */
export class ExperimentsApi extends BaseAPI {
  /**
   * Batch delete chaos experiments by uids.
   * @summary Batch delete chaos experiments.
   * @param {ExperimentsApiExperimentsDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsDelete(requestParameters: ExperimentsApiExperimentsDeleteRequest, options?: AxiosRequestConfig) {
    return ExperimentsApiFp(this.configuration)
      .experimentsDelete(requestParameters.uids, requestParameters.force, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get chaos experiments from k8s clusters in real time.
   * @summary List chaos experiments.
   * @param {ExperimentsApiExperimentsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsGet(requestParameters: ExperimentsApiExperimentsGetRequest = {}, options?: AxiosRequestConfig) {
    return ExperimentsApiFp(this.configuration)
      .experimentsGet(
        requestParameters.namespace,
        requestParameters.name,
        requestParameters.kind,
        requestParameters.status,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Pause a chaos experiment.
   * @summary Pause a chaos experiment.
   * @param {ExperimentsApiExperimentsPauseUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsPauseUidPut(
    requestParameters: ExperimentsApiExperimentsPauseUidPutRequest,
    options?: AxiosRequestConfig
  ) {
    return ExperimentsApiFp(this.configuration)
      .experimentsPauseUidPut(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Pass a JSON object to create a new chaos experiment. The schema for JSON is the same as the YAML schema for the Kubernetes object.
   * @summary Create a new chaos experiment.
   * @param {ExperimentsApiExperimentsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsPost(requestParameters: ExperimentsApiExperimentsPostRequest, options?: AxiosRequestConfig) {
    return ExperimentsApiFp(this.configuration)
      .experimentsPost(requestParameters.chaos, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Start a chaos experiment.
   * @summary Start a chaos experiment.
   * @param {ExperimentsApiExperimentsStartUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsStartUidPut(
    requestParameters: ExperimentsApiExperimentsStartUidPutRequest,
    options?: AxiosRequestConfig
  ) {
    return ExperimentsApiFp(this.configuration)
      .experimentsStartUidPut(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the status of all experiments.
   * @summary Get the status of all experiments.
   * @param {ExperimentsApiExperimentsStateGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsStateGet(
    requestParameters: ExperimentsApiExperimentsStateGetRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ExperimentsApiFp(this.configuration)
      .experimentsStateGet(requestParameters.namespace, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the chaos experiment by uid.
   * @summary Delete a chaos experiment.
   * @param {ExperimentsApiExperimentsUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsUidDelete(
    requestParameters: ExperimentsApiExperimentsUidDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return ExperimentsApiFp(this.configuration)
      .experimentsUidDelete(requestParameters.uid, requestParameters.force, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the chaos experiment\'s detail by uid.
   * @summary Get a chaos experiment.
   * @param {ExperimentsApiExperimentsUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExperimentsApi
   */
  public experimentsUidGet(requestParameters: ExperimentsApiExperimentsUidGetRequest, options?: AxiosRequestConfig) {
    return ExperimentsApiFp(this.configuration)
      .experimentsUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SchedulesApi - axios parameter creator
 * @export
 */
export const SchedulesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Batch delete schedules by uids.
     * @summary Batch delete schedules.
     * @param {string} uids the schedule uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesDelete: async (uids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uids' is not null or undefined
      assertParamExists('schedulesDelete', 'uids', uids)
      const localVarPath = `/schedules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (uids !== undefined) {
        localVarQueryParameter['uids'] = uids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get chaos schedules from k8s cluster in real time.
     * @summary List chaos schedules.
     * @param {string} [namespace] filter schedules by namespace
     * @param {string} [name] filter schedules by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesGet: async (namespace?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/schedules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Pause a schedule.
     * @summary Pause a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesPauseUidPut: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('schedulesPauseUidPut', 'uid', uid)
      const localVarPath = `/schedules/pause/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Pass a JSON object to create a new schedule. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new schedule.
     * @param {V1alpha1Schedule} schedule the schedule definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesPost: async (schedule: V1alpha1Schedule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'schedule' is not null or undefined
      assertParamExists('schedulesPost', 'schedule', schedule)
      const localVarPath = `/schedules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Start a schedule.
     * @summary Start a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesStartUidPut: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('schedulesStartUidPut', 'uid', uid)
      const localVarPath = `/schedules/start/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the schedule by uid.
     * @summary Delete a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesUidDelete: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('schedulesUidDelete', 'uid', uid)
      const localVarPath = `/schedules/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the schedule\'s detail by uid.
     * @summary Get a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('schedulesUidGet', 'uid', uid)
      const localVarPath = `/schedules/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SchedulesApi - functional programming interface
 * @export
 */
export const SchedulesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SchedulesApiAxiosParamCreator(configuration)
  return {
    /**
     * Batch delete schedules by uids.
     * @summary Batch delete schedules.
     * @param {string} uids the schedule uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesDelete(
      uids: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesDelete(uids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get chaos schedules from k8s cluster in real time.
     * @summary List chaos schedules.
     * @param {string} [namespace] filter schedules by namespace
     * @param {string} [name] filter schedules by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesGet(
      namespace?: string,
      name?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesSchedule>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesGet(namespace, name, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Pause a schedule.
     * @summary Pause a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesPauseUidPut(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesPauseUidPut(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Pass a JSON object to create a new schedule. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new schedule.
     * @param {V1alpha1Schedule} schedule the schedule definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesPost(
      schedule: V1alpha1Schedule,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1alpha1Schedule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesPost(schedule, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Start a schedule.
     * @summary Start a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesStartUidPut(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesStartUidPut(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the schedule by uid.
     * @summary Delete a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesUidDelete(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesUidDelete(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the schedule\'s detail by uid.
     * @summary Get a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schedulesUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesScheduleDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schedulesUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SchedulesApi - factory interface
 * @export
 */
export const SchedulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SchedulesApiFp(configuration)
  return {
    /**
     * Batch delete schedules by uids.
     * @summary Batch delete schedules.
     * @param {string} uids the schedule uids, split with comma. Example: ?uids&#x3D;uid1,uid2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesDelete(uids: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.schedulesDelete(uids, options).then((request) => request(axios, basePath))
    },
    /**
     * Get chaos schedules from k8s cluster in real time.
     * @summary List chaos schedules.
     * @param {string} [namespace] filter schedules by namespace
     * @param {string} [name] filter schedules by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesGet(namespace?: string, name?: string, options?: any): AxiosPromise<Array<TypesSchedule>> {
      return localVarFp.schedulesGet(namespace, name, options).then((request) => request(axios, basePath))
    },
    /**
     * Pause a schedule.
     * @summary Pause a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesPauseUidPut(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.schedulesPauseUidPut(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Pass a JSON object to create a new schedule. The schema for JSON is the same as the YAML schema for the Kubernetes object.
     * @summary Create a new schedule.
     * @param {V1alpha1Schedule} schedule the schedule definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesPost(schedule: V1alpha1Schedule, options?: any): AxiosPromise<V1alpha1Schedule> {
      return localVarFp.schedulesPost(schedule, options).then((request) => request(axios, basePath))
    },
    /**
     * Start a schedule.
     * @summary Start a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesStartUidPut(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.schedulesStartUidPut(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the schedule by uid.
     * @summary Delete a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesUidDelete(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.schedulesUidDelete(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the schedule\'s detail by uid.
     * @summary Get a schedule.
     * @param {string} uid the schedule uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schedulesUidGet(uid: string, options?: any): AxiosPromise<TypesScheduleDetail> {
      return localVarFp.schedulesUidGet(uid, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for schedulesDelete operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesDeleteRequest
 */
export interface SchedulesApiSchedulesDeleteRequest {
  /**
   * the schedule uids, split with comma. Example: ?uids&#x3D;uid1,uid2
   * @type {string}
   * @memberof SchedulesApiSchedulesDelete
   */
  readonly uids: string
}

/**
 * Request parameters for schedulesGet operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesGetRequest
 */
export interface SchedulesApiSchedulesGetRequest {
  /**
   * filter schedules by namespace
   * @type {string}
   * @memberof SchedulesApiSchedulesGet
   */
  readonly namespace?: string

  /**
   * filter schedules by name
   * @type {string}
   * @memberof SchedulesApiSchedulesGet
   */
  readonly name?: string
}

/**
 * Request parameters for schedulesPauseUidPut operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesPauseUidPutRequest
 */
export interface SchedulesApiSchedulesPauseUidPutRequest {
  /**
   * the schedule uid
   * @type {string}
   * @memberof SchedulesApiSchedulesPauseUidPut
   */
  readonly uid: string
}

/**
 * Request parameters for schedulesPost operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesPostRequest
 */
export interface SchedulesApiSchedulesPostRequest {
  /**
   * the schedule definition
   * @type {V1alpha1Schedule}
   * @memberof SchedulesApiSchedulesPost
   */
  readonly schedule: V1alpha1Schedule
}

/**
 * Request parameters for schedulesStartUidPut operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesStartUidPutRequest
 */
export interface SchedulesApiSchedulesStartUidPutRequest {
  /**
   * the schedule uid
   * @type {string}
   * @memberof SchedulesApiSchedulesStartUidPut
   */
  readonly uid: string
}

/**
 * Request parameters for schedulesUidDelete operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesUidDeleteRequest
 */
export interface SchedulesApiSchedulesUidDeleteRequest {
  /**
   * the schedule uid
   * @type {string}
   * @memberof SchedulesApiSchedulesUidDelete
   */
  readonly uid: string
}

/**
 * Request parameters for schedulesUidGet operation in SchedulesApi.
 * @export
 * @interface SchedulesApiSchedulesUidGetRequest
 */
export interface SchedulesApiSchedulesUidGetRequest {
  /**
   * the schedule uid
   * @type {string}
   * @memberof SchedulesApiSchedulesUidGet
   */
  readonly uid: string
}

/**
 * SchedulesApi - object-oriented interface
 * @export
 * @class SchedulesApi
 * @extends {BaseAPI}
 */
export class SchedulesApi extends BaseAPI {
  /**
   * Batch delete schedules by uids.
   * @summary Batch delete schedules.
   * @param {SchedulesApiSchedulesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesDelete(requestParameters: SchedulesApiSchedulesDeleteRequest, options?: AxiosRequestConfig) {
    return SchedulesApiFp(this.configuration)
      .schedulesDelete(requestParameters.uids, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get chaos schedules from k8s cluster in real time.
   * @summary List chaos schedules.
   * @param {SchedulesApiSchedulesGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesGet(requestParameters: SchedulesApiSchedulesGetRequest = {}, options?: AxiosRequestConfig) {
    return SchedulesApiFp(this.configuration)
      .schedulesGet(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Pause a schedule.
   * @summary Pause a schedule.
   * @param {SchedulesApiSchedulesPauseUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesPauseUidPut(
    requestParameters: SchedulesApiSchedulesPauseUidPutRequest,
    options?: AxiosRequestConfig
  ) {
    return SchedulesApiFp(this.configuration)
      .schedulesPauseUidPut(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Pass a JSON object to create a new schedule. The schema for JSON is the same as the YAML schema for the Kubernetes object.
   * @summary Create a new schedule.
   * @param {SchedulesApiSchedulesPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesPost(requestParameters: SchedulesApiSchedulesPostRequest, options?: AxiosRequestConfig) {
    return SchedulesApiFp(this.configuration)
      .schedulesPost(requestParameters.schedule, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Start a schedule.
   * @summary Start a schedule.
   * @param {SchedulesApiSchedulesStartUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesStartUidPut(
    requestParameters: SchedulesApiSchedulesStartUidPutRequest,
    options?: AxiosRequestConfig
  ) {
    return SchedulesApiFp(this.configuration)
      .schedulesStartUidPut(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the schedule by uid.
   * @summary Delete a schedule.
   * @param {SchedulesApiSchedulesUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesUidDelete(requestParameters: SchedulesApiSchedulesUidDeleteRequest, options?: AxiosRequestConfig) {
    return SchedulesApiFp(this.configuration)
      .schedulesUidDelete(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the schedule\'s detail by uid.
   * @summary Get a schedule.
   * @param {SchedulesApiSchedulesUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchedulesApi
   */
  public schedulesUidGet(requestParameters: SchedulesApiSchedulesUidGetRequest, options?: AxiosRequestConfig) {
    return SchedulesApiFp(this.configuration)
      .schedulesUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get status check templates from k8s cluster in real time.
     * @summary List status check templates.
     * @param {string} [namespace] filter status check templates by namespace
     * @param {string} [name] filter status check templates by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksGet: async (
      namespace?: string,
      name?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/templates/statuschecks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
  return {
    /**
     * Get status check templates from k8s cluster in real time.
     * @summary List status check templates.
     * @param {string} [namespace] filter status check templates by namespace
     * @param {string} [name] filter status check templates by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesStatuschecksGet(
      namespace?: string,
      name?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypesStatusCheckTemplateBase>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.templatesStatuschecksGet(namespace, name, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TemplateApiFp(configuration)
  return {
    /**
     * Get status check templates from k8s cluster in real time.
     * @summary List status check templates.
     * @param {string} [namespace] filter status check templates by namespace
     * @param {string} [name] filter status check templates by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksGet(
      namespace?: string,
      name?: string,
      options?: any
    ): AxiosPromise<Array<TypesStatusCheckTemplateBase>> {
      return localVarFp.templatesStatuschecksGet(namespace, name, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for templatesStatuschecksGet operation in TemplateApi.
 * @export
 * @interface TemplateApiTemplatesStatuschecksGetRequest
 */
export interface TemplateApiTemplatesStatuschecksGetRequest {
  /**
   * filter status check templates by namespace
   * @type {string}
   * @memberof TemplateApiTemplatesStatuschecksGet
   */
  readonly namespace?: string

  /**
   * filter status check templates by name
   * @type {string}
   * @memberof TemplateApiTemplatesStatuschecksGet
   */
  readonly name?: string
}

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
  /**
   * Get status check templates from k8s cluster in real time.
   * @summary List status check templates.
   * @param {TemplateApiTemplatesStatuschecksGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateApi
   */
  public templatesStatuschecksGet(
    requestParameters: TemplateApiTemplatesStatuschecksGetRequest = {},
    options?: AxiosRequestConfig
  ) {
    return TemplateApiFp(this.configuration)
      .templatesStatuschecksGet(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Pass a JSON object to create a new status check template.
     * @summary Create a new status check template.
     * @param {TypesStatusCheckTemplate} statuscheck the status check definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksPost: async (
      statuscheck: TypesStatusCheckTemplate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statuscheck' is not null or undefined
      assertParamExists('templatesStatuschecksPost', 'statuscheck', statuscheck)
      const localVarPath = `/templates/statuschecks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(statuscheck, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the status check template by namespaced name.
     * @summary Delete a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckDelete: async (
      namespace: string,
      name: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'namespace' is not null or undefined
      assertParamExists('templatesStatuschecksStatuscheckDelete', 'namespace', namespace)
      // verify required parameter 'name' is not null or undefined
      assertParamExists('templatesStatuschecksStatuscheckDelete', 'name', name)
      const localVarPath = `/templates/statuschecks/statuscheck`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the status check template\'s detail by namespaced name.
     * @summary Get a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckGet: async (
      namespace: string,
      name: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'namespace' is not null or undefined
      assertParamExists('templatesStatuschecksStatuscheckGet', 'namespace', namespace)
      // verify required parameter 'name' is not null or undefined
      assertParamExists('templatesStatuschecksStatuscheckGet', 'name', name)
      const localVarPath = `/templates/statuschecks/statuscheck`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update a status check template by namespaced name.
     * @summary Update a status check template.
     * @param {TypesStatusCheckTemplate} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckPut: async (
      request: TypesStatusCheckTemplate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('templatesStatuschecksStatuscheckPut', 'request', request)
      const localVarPath = `/templates/statuschecks/statuscheck`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TemplatesApiAxiosParamCreator(configuration)
  return {
    /**
     * Pass a JSON object to create a new status check template.
     * @summary Create a new status check template.
     * @param {TypesStatusCheckTemplate} statuscheck the status check definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesStatuschecksPost(
      statuscheck: TypesStatusCheckTemplate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesStatusCheckTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.templatesStatuschecksPost(statuscheck, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the status check template by namespaced name.
     * @summary Delete a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesStatuschecksStatuscheckDelete(
      namespace: string,
      name: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.templatesStatuschecksStatuscheckDelete(
        namespace,
        name,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the status check template\'s detail by namespaced name.
     * @summary Get a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesStatuschecksStatuscheckGet(
      namespace: string,
      name: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesStatusCheckTemplateDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.templatesStatuschecksStatuscheckGet(
        namespace,
        name,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update a status check template by namespaced name.
     * @summary Update a status check template.
     * @param {TypesStatusCheckTemplate} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async templatesStatuschecksStatuscheckPut(
      request: TypesStatusCheckTemplate,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypesStatusCheckTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.templatesStatuschecksStatuscheckPut(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TemplatesApiFp(configuration)
  return {
    /**
     * Pass a JSON object to create a new status check template.
     * @summary Create a new status check template.
     * @param {TypesStatusCheckTemplate} statuscheck the status check definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksPost(
      statuscheck: TypesStatusCheckTemplate,
      options?: any
    ): AxiosPromise<TypesStatusCheckTemplate> {
      return localVarFp.templatesStatuschecksPost(statuscheck, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the status check template by namespaced name.
     * @summary Delete a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckDelete(
      namespace: string,
      name: string,
      options?: any
    ): AxiosPromise<UtilsResponse> {
      return localVarFp
        .templatesStatuschecksStatuscheckDelete(namespace, name, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the status check template\'s detail by namespaced name.
     * @summary Get a status check template.
     * @param {string} namespace the namespace of status check templates
     * @param {string} name the name of status check templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckGet(
      namespace: string,
      name: string,
      options?: any
    ): AxiosPromise<TypesStatusCheckTemplateDetail> {
      return localVarFp
        .templatesStatuschecksStatuscheckGet(namespace, name, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Update a status check template by namespaced name.
     * @summary Update a status check template.
     * @param {TypesStatusCheckTemplate} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    templatesStatuschecksStatuscheckPut(
      request: TypesStatusCheckTemplate,
      options?: any
    ): AxiosPromise<TypesStatusCheckTemplate> {
      return localVarFp
        .templatesStatuschecksStatuscheckPut(request, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for templatesStatuschecksPost operation in TemplatesApi.
 * @export
 * @interface TemplatesApiTemplatesStatuschecksPostRequest
 */
export interface TemplatesApiTemplatesStatuschecksPostRequest {
  /**
   * the status check definition
   * @type {TypesStatusCheckTemplate}
   * @memberof TemplatesApiTemplatesStatuschecksPost
   */
  readonly statuscheck: TypesStatusCheckTemplate
}

/**
 * Request parameters for templatesStatuschecksStatuscheckDelete operation in TemplatesApi.
 * @export
 * @interface TemplatesApiTemplatesStatuschecksStatuscheckDeleteRequest
 */
export interface TemplatesApiTemplatesStatuschecksStatuscheckDeleteRequest {
  /**
   * the namespace of status check templates
   * @type {string}
   * @memberof TemplatesApiTemplatesStatuschecksStatuscheckDelete
   */
  readonly namespace: string

  /**
   * the name of status check templates
   * @type {string}
   * @memberof TemplatesApiTemplatesStatuschecksStatuscheckDelete
   */
  readonly name: string
}

/**
 * Request parameters for templatesStatuschecksStatuscheckGet operation in TemplatesApi.
 * @export
 * @interface TemplatesApiTemplatesStatuschecksStatuscheckGetRequest
 */
export interface TemplatesApiTemplatesStatuschecksStatuscheckGetRequest {
  /**
   * the namespace of status check templates
   * @type {string}
   * @memberof TemplatesApiTemplatesStatuschecksStatuscheckGet
   */
  readonly namespace: string

  /**
   * the name of status check templates
   * @type {string}
   * @memberof TemplatesApiTemplatesStatuschecksStatuscheckGet
   */
  readonly name: string
}

/**
 * Request parameters for templatesStatuschecksStatuscheckPut operation in TemplatesApi.
 * @export
 * @interface TemplatesApiTemplatesStatuschecksStatuscheckPutRequest
 */
export interface TemplatesApiTemplatesStatuschecksStatuscheckPutRequest {
  /**
   * Request body
   * @type {TypesStatusCheckTemplate}
   * @memberof TemplatesApiTemplatesStatuschecksStatuscheckPut
   */
  readonly request: TypesStatusCheckTemplate
}

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
  /**
   * Pass a JSON object to create a new status check template.
   * @summary Create a new status check template.
   * @param {TemplatesApiTemplatesStatuschecksPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public templatesStatuschecksPost(
    requestParameters: TemplatesApiTemplatesStatuschecksPostRequest,
    options?: AxiosRequestConfig
  ) {
    return TemplatesApiFp(this.configuration)
      .templatesStatuschecksPost(requestParameters.statuscheck, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the status check template by namespaced name.
   * @summary Delete a status check template.
   * @param {TemplatesApiTemplatesStatuschecksStatuscheckDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public templatesStatuschecksStatuscheckDelete(
    requestParameters: TemplatesApiTemplatesStatuschecksStatuscheckDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return TemplatesApiFp(this.configuration)
      .templatesStatuschecksStatuscheckDelete(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the status check template\'s detail by namespaced name.
   * @summary Get a status check template.
   * @param {TemplatesApiTemplatesStatuschecksStatuscheckGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public templatesStatuschecksStatuscheckGet(
    requestParameters: TemplatesApiTemplatesStatuschecksStatuscheckGetRequest,
    options?: AxiosRequestConfig
  ) {
    return TemplatesApiFp(this.configuration)
      .templatesStatuschecksStatuscheckGet(requestParameters.namespace, requestParameters.name, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update a status check template by namespaced name.
   * @summary Update a status check template.
   * @param {TemplatesApiTemplatesStatuschecksStatuscheckPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplatesApi
   */
  public templatesStatuschecksStatuscheckPut(
    requestParameters: TemplatesApiTemplatesStatuschecksStatuscheckPutRequest,
    options?: AxiosRequestConfig
  ) {
    return TemplatesApiFp(this.configuration)
      .templatesStatuschecksStatuscheckPut(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WorkflowsApi - axios parameter creator
 * @export
 */
export const WorkflowsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * List workflows from Kubernetes cluster.
     * @summary List workflows from Kubernetes cluster.
     * @param {string} [namespace] namespace, given empty string means list from all namespace
     * @param {'Initializing' | 'Running' | 'Errored' | 'Finished'} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsGet: async (
      namespace?: string,
      status?: 'Initializing' | 'Running' | 'Errored' | 'Finished',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/workflows`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (namespace !== undefined) {
        localVarQueryParameter['namespace'] = namespace
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Parse the rendered task back to the original request
     * @summary Parse the rendered task back to the original request
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsParseTaskHttpPost: async (
      request: V1alpha1Template,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('workflowsParseTaskHttpPost', 'request', request)
      const localVarPath = `/workflows/parse-task/http`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create a new workflow.
     * @summary Create a new workflow.
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsPost: async (request: V1alpha1Workflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('workflowsPost', 'request', request)
      const localVarPath = `/workflows`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Render a task which sends HTTP request
     * @summary Render a task which sends HTTP request
     * @param {CurlRequestForm} request Origin HTTP Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsRenderTaskHttpPost: async (
      request: CurlRequestForm,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('workflowsRenderTaskHttpPost', 'request', request)
      const localVarPath = `/workflows/render-task/http`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Delete the specified workflow.
     * @summary Delete the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidDelete: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('workflowsUidDelete', 'uid', uid)
      const localVarPath = `/workflows/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get detailed information about the specified workflow. If that object is not existed in kubernetes, it will only return ths persisted data in the database.
     * @summary Get detailed information about the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidGet: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('workflowsUidGet', 'uid', uid)
      const localVarPath = `/workflows/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Update a workflow.
     * @summary Update a workflow.
     * @param {string} uid uid
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidPut: async (
      uid: string,
      request: V1alpha1Workflow,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('workflowsUidPut', 'uid', uid)
      // verify required parameter 'request' is not null or undefined
      assertParamExists('workflowsUidPut', 'request', request)
      const localVarPath = `/workflows/{uid}`.replace(`{${'uid'}}`, encodeURIComponent(String(uid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Validate the given template is a valid rendered HTTP Task
     * @summary Validate the given template is a valid rendered HTTP Task
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsValidateTaskHttpPost: async (
      request: V1alpha1Template,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('workflowsValidateTaskHttpPost', 'request', request)
      const localVarPath = `/workflows/validate-task/http`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WorkflowsApi - functional programming interface
 * @export
 */
export const WorkflowsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkflowsApiAxiosParamCreator(configuration)
  return {
    /**
     * List workflows from Kubernetes cluster.
     * @summary List workflows from Kubernetes cluster.
     * @param {string} [namespace] namespace, given empty string means list from all namespace
     * @param {'Initializing' | 'Running' | 'Errored' | 'Finished'} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsGet(
      namespace?: string,
      status?: 'Initializing' | 'Running' | 'Errored' | 'Finished',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoreWorkflowMeta>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsGet(namespace, status, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Parse the rendered task back to the original request
     * @summary Parse the rendered task back to the original request
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsParseTaskHttpPost(
      request: V1alpha1Template,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurlRequestForm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsParseTaskHttpPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create a new workflow.
     * @summary Create a new workflow.
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsPost(
      request: V1alpha1Workflow,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreWorkflowDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Render a task which sends HTTP request
     * @summary Render a task which sends HTTP request
     * @param {CurlRequestForm} request Origin HTTP Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsRenderTaskHttpPost(
      request: CurlRequestForm,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1alpha1Template>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsRenderTaskHttpPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Delete the specified workflow.
     * @summary Delete the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsUidDelete(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsUidDelete(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get detailed information about the specified workflow. If that object is not existed in kubernetes, it will only return ths persisted data in the database.
     * @summary Get detailed information about the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsUidGet(
      uid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreWorkflowDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsUidGet(uid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Update a workflow.
     * @summary Update a workflow.
     * @param {string} uid uid
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsUidPut(
      uid: string,
      request: V1alpha1Workflow,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreWorkflowDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsUidPut(uid, request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Validate the given template is a valid rendered HTTP Task
     * @summary Validate the given template is a valid rendered HTTP Task
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workflowsValidateTaskHttpPost(
      request: V1alpha1Template,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workflowsValidateTaskHttpPost(request, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WorkflowsApi - factory interface
 * @export
 */
export const WorkflowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WorkflowsApiFp(configuration)
  return {
    /**
     * List workflows from Kubernetes cluster.
     * @summary List workflows from Kubernetes cluster.
     * @param {string} [namespace] namespace, given empty string means list from all namespace
     * @param {'Initializing' | 'Running' | 'Errored' | 'Finished'} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsGet(
      namespace?: string,
      status?: 'Initializing' | 'Running' | 'Errored' | 'Finished',
      options?: any
    ): AxiosPromise<Array<CoreWorkflowMeta>> {
      return localVarFp.workflowsGet(namespace, status, options).then((request) => request(axios, basePath))
    },
    /**
     * Parse the rendered task back to the original request
     * @summary Parse the rendered task back to the original request
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsParseTaskHttpPost(request: V1alpha1Template, options?: any): AxiosPromise<CurlRequestForm> {
      return localVarFp.workflowsParseTaskHttpPost(request, options).then((request) => request(axios, basePath))
    },
    /**
     * Create a new workflow.
     * @summary Create a new workflow.
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsPost(request: V1alpha1Workflow, options?: any): AxiosPromise<CoreWorkflowDetail> {
      return localVarFp.workflowsPost(request, options).then((request) => request(axios, basePath))
    },
    /**
     * Render a task which sends HTTP request
     * @summary Render a task which sends HTTP request
     * @param {CurlRequestForm} request Origin HTTP Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsRenderTaskHttpPost(request: CurlRequestForm, options?: any): AxiosPromise<V1alpha1Template> {
      return localVarFp.workflowsRenderTaskHttpPost(request, options).then((request) => request(axios, basePath))
    },
    /**
     * Delete the specified workflow.
     * @summary Delete the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidDelete(uid: string, options?: any): AxiosPromise<UtilsResponse> {
      return localVarFp.workflowsUidDelete(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Get detailed information about the specified workflow. If that object is not existed in kubernetes, it will only return ths persisted data in the database.
     * @summary Get detailed information about the specified workflow.
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidGet(uid: string, options?: any): AxiosPromise<CoreWorkflowDetail> {
      return localVarFp.workflowsUidGet(uid, options).then((request) => request(axios, basePath))
    },
    /**
     * Update a workflow.
     * @summary Update a workflow.
     * @param {string} uid uid
     * @param {V1alpha1Workflow} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsUidPut(uid: string, request: V1alpha1Workflow, options?: any): AxiosPromise<CoreWorkflowDetail> {
      return localVarFp.workflowsUidPut(uid, request, options).then((request) => request(axios, basePath))
    },
    /**
     * Validate the given template is a valid rendered HTTP Task
     * @summary Validate the given template is a valid rendered HTTP Task
     * @param {V1alpha1Template} request Rendered Task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workflowsValidateTaskHttpPost(request: V1alpha1Template, options?: any): AxiosPromise<boolean> {
      return localVarFp.workflowsValidateTaskHttpPost(request, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for workflowsGet operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsGetRequest
 */
export interface WorkflowsApiWorkflowsGetRequest {
  /**
   * namespace, given empty string means list from all namespace
   * @type {string}
   * @memberof WorkflowsApiWorkflowsGet
   */
  readonly namespace?: string

  /**
   * status
   * @type {'Initializing' | 'Running' | 'Errored' | 'Finished'}
   * @memberof WorkflowsApiWorkflowsGet
   */
  readonly status?: 'Initializing' | 'Running' | 'Errored' | 'Finished'
}

/**
 * Request parameters for workflowsParseTaskHttpPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsParseTaskHttpPostRequest
 */
export interface WorkflowsApiWorkflowsParseTaskHttpPostRequest {
  /**
   * Rendered Task
   * @type {V1alpha1Template}
   * @memberof WorkflowsApiWorkflowsParseTaskHttpPost
   */
  readonly request: V1alpha1Template
}

/**
 * Request parameters for workflowsPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsPostRequest
 */
export interface WorkflowsApiWorkflowsPostRequest {
  /**
   * Request body
   * @type {V1alpha1Workflow}
   * @memberof WorkflowsApiWorkflowsPost
   */
  readonly request: V1alpha1Workflow
}

/**
 * Request parameters for workflowsRenderTaskHttpPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsRenderTaskHttpPostRequest
 */
export interface WorkflowsApiWorkflowsRenderTaskHttpPostRequest {
  /**
   * Origin HTTP Request
   * @type {CurlRequestForm}
   * @memberof WorkflowsApiWorkflowsRenderTaskHttpPost
   */
  readonly request: CurlRequestForm
}

/**
 * Request parameters for workflowsUidDelete operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsUidDeleteRequest
 */
export interface WorkflowsApiWorkflowsUidDeleteRequest {
  /**
   * uid
   * @type {string}
   * @memberof WorkflowsApiWorkflowsUidDelete
   */
  readonly uid: string
}

/**
 * Request parameters for workflowsUidGet operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsUidGetRequest
 */
export interface WorkflowsApiWorkflowsUidGetRequest {
  /**
   * uid
   * @type {string}
   * @memberof WorkflowsApiWorkflowsUidGet
   */
  readonly uid: string
}

/**
 * Request parameters for workflowsUidPut operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsUidPutRequest
 */
export interface WorkflowsApiWorkflowsUidPutRequest {
  /**
   * uid
   * @type {string}
   * @memberof WorkflowsApiWorkflowsUidPut
   */
  readonly uid: string

  /**
   * Request body
   * @type {V1alpha1Workflow}
   * @memberof WorkflowsApiWorkflowsUidPut
   */
  readonly request: V1alpha1Workflow
}

/**
 * Request parameters for workflowsValidateTaskHttpPost operation in WorkflowsApi.
 * @export
 * @interface WorkflowsApiWorkflowsValidateTaskHttpPostRequest
 */
export interface WorkflowsApiWorkflowsValidateTaskHttpPostRequest {
  /**
   * Rendered Task
   * @type {V1alpha1Template}
   * @memberof WorkflowsApiWorkflowsValidateTaskHttpPost
   */
  readonly request: V1alpha1Template
}

/**
 * WorkflowsApi - object-oriented interface
 * @export
 * @class WorkflowsApi
 * @extends {BaseAPI}
 */
export class WorkflowsApi extends BaseAPI {
  /**
   * List workflows from Kubernetes cluster.
   * @summary List workflows from Kubernetes cluster.
   * @param {WorkflowsApiWorkflowsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsGet(requestParameters: WorkflowsApiWorkflowsGetRequest = {}, options?: AxiosRequestConfig) {
    return WorkflowsApiFp(this.configuration)
      .workflowsGet(requestParameters.namespace, requestParameters.status, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Parse the rendered task back to the original request
   * @summary Parse the rendered task back to the original request
   * @param {WorkflowsApiWorkflowsParseTaskHttpPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsParseTaskHttpPost(
    requestParameters: WorkflowsApiWorkflowsParseTaskHttpPostRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkflowsApiFp(this.configuration)
      .workflowsParseTaskHttpPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create a new workflow.
   * @summary Create a new workflow.
   * @param {WorkflowsApiWorkflowsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsPost(requestParameters: WorkflowsApiWorkflowsPostRequest, options?: AxiosRequestConfig) {
    return WorkflowsApiFp(this.configuration)
      .workflowsPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Render a task which sends HTTP request
   * @summary Render a task which sends HTTP request
   * @param {WorkflowsApiWorkflowsRenderTaskHttpPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsRenderTaskHttpPost(
    requestParameters: WorkflowsApiWorkflowsRenderTaskHttpPostRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkflowsApiFp(this.configuration)
      .workflowsRenderTaskHttpPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Delete the specified workflow.
   * @summary Delete the specified workflow.
   * @param {WorkflowsApiWorkflowsUidDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsUidDelete(requestParameters: WorkflowsApiWorkflowsUidDeleteRequest, options?: AxiosRequestConfig) {
    return WorkflowsApiFp(this.configuration)
      .workflowsUidDelete(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get detailed information about the specified workflow. If that object is not existed in kubernetes, it will only return ths persisted data in the database.
   * @summary Get detailed information about the specified workflow.
   * @param {WorkflowsApiWorkflowsUidGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsUidGet(requestParameters: WorkflowsApiWorkflowsUidGetRequest, options?: AxiosRequestConfig) {
    return WorkflowsApiFp(this.configuration)
      .workflowsUidGet(requestParameters.uid, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Update a workflow.
   * @summary Update a workflow.
   * @param {WorkflowsApiWorkflowsUidPutRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsUidPut(requestParameters: WorkflowsApiWorkflowsUidPutRequest, options?: AxiosRequestConfig) {
    return WorkflowsApiFp(this.configuration)
      .workflowsUidPut(requestParameters.uid, requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Validate the given template is a valid rendered HTTP Task
   * @summary Validate the given template is a valid rendered HTTP Task
   * @param {WorkflowsApiWorkflowsValidateTaskHttpPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkflowsApi
   */
  public workflowsValidateTaskHttpPost(
    requestParameters: WorkflowsApiWorkflowsValidateTaskHttpPostRequest,
    options?: AxiosRequestConfig
  ) {
    return WorkflowsApiFp(this.configuration)
      .workflowsValidateTaskHttpPost(requestParameters.request, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
